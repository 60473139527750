import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import './Poi.scss';
import ReactTags from 'react-tag-autocomplete';
import toast from 'toasted-notes'
import { poiService } from '../../services';
import queryString from 'query-string';
import moment from 'moment';


class PoiEdit  extends Home{


  constructor(props) {
      super(props)

      let params = queryString.parse(this.props.location.search);
      this.state.podcast = {title:"",image:"",url:"", latitude:"",longitude:"",tags:[],description:"", locale:"", isPoi:true,  visibleApp: true, route:[]};
      this.state.buttonvisibles = true;
      this.state.routes = [];

      if (params.id) {
        this.state.idpodcast = params.id;
        this.callSearch();
      } else{
        this.state.idpodcast = -1;
      }

      this.handlerUpload = this.handlerUpload.bind(this);
      this.handlerDelete = this.handlerDelete.bind(this);
      this.state.valid_url = false;

      this.reactTags = React.createRef()
      this.reactRoutes = React.createRef()
      
      this.callTags();
      this.callRoutes();

  }


    callSearch (){

        let callback = (data, error) => {
          if (data) {
             this.setState({podcast:data});
             if (data.image) {
                this.setState({valid_url:this.validURLImage(data.image)});
             }
          } else {
            const msg = "No se ha encontrado el podcast";
            toast.notify(msg, {position:'top', duration: 5000});
            this.setState({buttonvisibles:false});
          }

        }
        poiService.one(this.state.idpodcast, callback);
    }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({tags:datarows});
      }
    }
    poiService.tags(callback);
  }

  callRoutes (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({routes:datarows});
      }
    }
    poiService.routes(callback);
  }


   handleClickClear(event) {
     window.location.href="/pois";
   }

   handlerUpload(image){
     var podcast = this.state.podcast;
     podcast.image = image;
     this.setState({podcast:podcast});
   }

   handlerDelete(){
     var podcast = this.state.podcast;
     podcast.image = "";
     this.setState({podcast:podcast});
   }

  //  setChecked(field,event,checked) {
  //   var podcast = this.state.podcast;
  //    if (field === "visibleApp") {
  //       podcast.visibleApp = checked;
  //    } else if (event.target.name === "donwload") {
  //       podcast.donwload = checked;
  //    } else if (field === "featureGlobal") {
  //       podcast.featureGlobal = checked;
  //    } else if (field === "featured") {
  //       podcast.featured = checked;
  //    } else if (field === "externalUrl") {
  //       podcast.externalUrl = checked;
  //    } else if (field === "isHome") {
  //       podcast.isHome = checked;
  //    } else if (field === "push") {
  //       podcast.push = checked;
  //    }


  //    this.setState({podcast:podcast});

  //  }

  onDelete (i) {
    const tags = this.state.podcast.tags.slice(0)
    tags.splice(i, 1)
    var podcast = this.state.podcast;
    podcast.tags = tags;
    this.setState({podcast:podcast});
  }

  onAddition (tag) {
    const tags = [].concat(this.state.podcast.tags, tag)
    var podcast = this.state.podcast;
    podcast.tags = tags;
    this.setState({podcast:podcast});
  }

  onDeleteRoute (i) {
    const routes = this.state.podcast.route.slice(0)
    routes.splice(i, 1)
    var podcast = this.state.podcast;
    podcast.route = routes;
    this.setState({podcast:podcast});
  }

  onAdditionRoute (route) {
    const routes = [].concat(this.state.podcast.route, route)
    var podcast = this.state.podcast;
    podcast.route = routes;
    this.setState({podcast:podcast});
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  validURLImage(str) {
    return str.match(/\.(jpeg|jpg|gif|png)$/) != null
  }


  handleChange(event){
       const value = event.target.value
       var podcast = this.state.podcast;
       if (event.target.name === "title") {
         podcast.title = value;
       } else if (event.target.name === "idRadioViajera") {
         podcast.idRadioViajera = value;
       } else if (event.target.name === "url") {
          podcast.url = value;
      } else if (event.target.name === "latitude") {
         podcast.latitude = value;
       } else if (event.target.name === "longitude") {
         podcast.longitude = value;
       } else if (event.target.name === "image") {
         podcast.image = value;
         this.setState({valid_url:this.validURLImage(value)});
       } else if (event.target.name === "dateCreate") {
          podcast.dateCreate = moment(value).format('YYYY-MM-DD')+"T00:00:00Z";
       } else if (event.target.name === "description") {
          podcast.description = value;
       } else if (event.target.name === "locale") {
        podcast.locale = value;
      }

       this.setState({podcast:podcast});
  }


     handleClickSave(event) {
       if (this.state.podcast.title && this.state.podcast.title !== "" ) {
         if(this.state.idpodcast !== -1){
           //EDIT
           this.callEdit();
         } else {
           //CREATE
           this.callCreate();
         }
       } else if (!this.state.podcast.title || this.state.podcast.title === "" ) {
           const msg = "El nombre es obligatorio"
           toast.notify(msg, {position:'top', duration: 5000})
       } else {
             const msg = "La url no tiene un formato correcto";
             toast.notify(msg, {position:'top', duration: 5000});
       }


     }

     callCreate (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
              window.location.href="/poi?id="+datarows.data.id;
           }

         }
         poiService.create(this.state.podcast, callback);
     }

     callEdit (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
               const msg = "Se ha guardado correctamente";
               toast.notify(msg, {position:'top', duration: 5000});
           }

         }
         poiService.edit(this.state.idpodcast,this.state.podcast, callback);
     }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idpodcast !== -1) return "Editar podcast"; else return "Nuevo podcast";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>ID</Form.Label>
                  <Form.Control type="number" placeholder="" name="idRadioViajera" value={this.state.podcast.idRadioViajera} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>Titulo</Form.Label>
                  <Form.Control type="text" placeholder="" name="title" value={this.state.podcast.title} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
             <Col sm="6">
                 <Form.Group >
                   <Form.Label>Fecha</Form.Label>
                   <Form.Control type="date" placeholder="" name="dateCreate" value={moment(this.state.podcast.dateCreate).format('YYYY-MM-DD')} onChange={event => this.handleChange(event) } pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"  required/>
                 </Form.Group>
             </Col>
             <Col sm="6">
                 <Form.Group >
                   <Form.Label>Url</Form.Label>
                   <Form.Control type="text" placeholder="" name="url" value={this.state.podcast.url} onChange={event => this.handleChange(event)} required/>
                 </Form.Group>
             </Col>
             <Col sm="12">
                  <Form.Group >
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control as="textarea" rows="3" placeholder="" name="description" value={this.state.podcast.description} onChange={event => this.handleChange(event)} />
                  </Form.Group>
            </Col>
            <Col sm="6">
                 <Form.Group >
                   <Form.Label>Latitud</Form.Label>
                   <Form.Control placeholder="" name="latitude" value={this.state.podcast.latitude} onChange={event => this.handleChange(event)} />
                 </Form.Group>
             </Col>
             <Col sm="6">
                  <Form.Group >
                    <Form.Label>Longitud</Form.Label>
                    <Form.Control placeholder="" name="longitude" value={this.state.podcast.longitude} onChange={event => this.handleChange(event)} />
                  </Form.Group>
              </Col>
              
             
             <Col sm="6">
                  <Form.Group >
                    <Form.Label>Tags</Form.Label>
                    <ReactTags allowNew={true} className="tagsinput" ref={this.reactTags} tags={this.state.podcast.tags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Nuevas etiquetas"/>
                  </Form.Group>
            </Col>
            <Col sm="9">
                 <Form.Group >
                   <Form.Label>Rutas</Form.Label>
                   <ReactTags allowNew={true} className="routeinput" ref={this.reactRoutes} tags={this.state.podcast.route} suggestions={this.state.routes} onDelete={this.onDeleteRoute.bind(this)} onAddition={this.onAdditionRoute.bind(this)} placeholderText="Nuevas rutas"/>
                 </Form.Group>
           </Col>
           <Col sm="3">
                  <Form.Group >
                    <Form.Label>Idioma</Form.Label>
                    <Form.Control placeholder="" name="locale" value={this.state.podcast.locale} onChange={event => this.handleChange(event)} />
                  </Form.Group>
             </Col>
           <Col sm="9">
                <Form.Group >
                  <Form.Label>Imagen</Form.Label>
                  <Form.Control placeholder="" name="image" value={this.state.podcast.image} onChange={event => this.handleChange(event)} />
                </Form.Group>
          </Col>
           <Col sm="3">
                <Image src={this.state.podcast.image } rounded fluid className="imagetable" style={{ display: this.state.valid_url  ? "block" : "none" }} />
          </Col>
        
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default PoiEdit;
