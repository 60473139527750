import BaseService from './_base.service';
export const RSSService = {
    search,
    all,
    edit,
    create,
    one,
    remove
};
function search(offset,name, callback) {
    let body = JSON.stringify({ name})
    return BaseService.post("/RSS/search/"+offset, body, callback, true)
}
function edit(id, bodyelement, callback) {
    if(bodyelement.tag.id == -1){
        bodyelement.tag = null;
      }
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/RSS/"+id, body, callback, true)
}
function create(bodyelement, callback) {
    if(bodyelement.tag.id == -1){
        bodyelement.tag = null;
      }
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/RSS/", body, callback, true)
}
function one(id,callback) {
    return BaseService.get("/RSS/one/"+id, callback);
}
function remove(id,callback) {
    return BaseService.delete("/RSS/"+id, callback);
}
function all(callback) {
    return BaseService.get("/RSS/all/", callback);
}