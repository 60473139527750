import BaseService from './_base.service';

export const notificationService = {
    send
};


function send(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/pushnotifications/send", body, callback, true)
}
