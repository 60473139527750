import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import './Tag.scss';
import toast from 'toasted-notes'
import { tagService } from '../../services';
import queryString from 'query-string';


class TagEdit  extends Home{


  constructor(props) {
      super(props)

      let params = queryString.parse(this.props.location.search);
      this.state.tag = {name:"",description:"", info:"", image:"",subtitle:"", banner:""};
      this.state.buttonvisibles = true;

      if (params.id) {
        this.state.idtag = params.id;
        this.callSearch();
      } else{
        this.state.idtag = -1;
      }

      this.handlerUpload = this.handlerUpload.bind(this);
      this.handlerDelete = this.handlerDelete.bind(this);
      this.state.valid_url = false;
      this.state.valid_url_icon = false;
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
           this.setState({tag:data});
           if (data.image) {
            this.setState({valid_url:this.validURLImage(data.image)});
           }
        } else {
          const msg = "No se ha encontrado el tag";
          toast.notify(msg, {position:'top', duration: 5000});
          this.setState({buttonvisibles:false});
        }

      }
      tagService.one(this.state.idtag, callback);
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  validURLImage(str) {
    return str.match(/\.(jpeg|jpg|gif|png)$/) != null
  }

 handleClickClear(event) {
   window.location.href="/tags";
 }

 handlerUpload(image){
  var tag = this.state.tag;
  tag.image = image;
  this.setState({tag:tag});
}

handlerDelete(){
  var tag = this.state.tag;
  tag.image = "";
  this.setState({tag:tag});
}

  handleChange(event){
       const value = event.target.value
       var tag = this.state.tag;
       if (event.target.name === "name") {
         tag.name = value;
       } else if (event.target.name === "description") {
         tag.description = value;
       } else if (event.target.name === "image") {
         tag.image = value;
         this.setState({valid_url:this.validURLImage(value)});
       } else if (event.target.name === "info") {
         tag.info = value;
       } else if (event.target.name === "subtitle") {
          tag.subtitle = value;
      } else if (event.target.name === "banner") {
        tag.banner = value;
      }

       this.setState({tag:tag});
  }


   handleClickSave(event) {
     if (this.state.tag.name) {
       if(this.state.idtag !== -1){
         //EDIT
         this.callEdit();
       } else {
         //CREATE
         this.callCreate();
       }
     } else if (!this.state.tag.name || this.state.tag.name === "" ) {
         const msg = "El nombre es obligatorio"
         toast.notify(msg, {position:'top', duration: 5000})
     }


   }

     callCreate (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
              this.setState({station:datarows.data});
              this.setState({idstation:datarows.data.id});
              window.location.href="/tag?id="+datarows.data.id;
           }

         }
         tagService.create(this.state.tag, callback);
     }

     callEdit (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
               const msg = "Se ha guardado correctamente";
               toast.notify(msg, {position:'top', duration: 5000});
           }

         }
         tagService.edit(this.state.idtag,this.state.tag, callback);
     }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idtag !== -1) return "Editar tag"; else return "Nuevo tag";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" placeholder="" name="name" value={this.state.tag.name} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="12">
                 <Form.Group >
                   <Form.Label>Descripción</Form.Label>
                   <Form.Control as="textarea" rows="3" placeholder="" name="description" value={this.state.tag.description} onChange={event => this.handleChange(event)} />
                 </Form.Group>
           </Col>
           <Col sm="12">
                 <Form.Group >
                   <Form.Label>Nueva información</Form.Label>
                   <Form.Control as="textarea" rows="3" placeholder="" name="info" value={this.state.tag.info} onChange={event => this.handleChange(event)} />
                 </Form.Group>
           </Col>
           <Col sm="12">
                <Form.Group >
                  <Form.Label>Subtítulo</Form.Label>
                  <Form.Control placeholder="" name="subtitle" value={this.state.tag.subtitle} onChange={event => this.handleChange(event)} />
                </Form.Group>
          </Col>
          <Col sm="12">
              <Form.Group >
                <Form.Label>Banner</Form.Label>
                <Form.Control placeholder="" name="banner" value={this.state.tag.banner} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>
           <Col sm="9">
                <Form.Group >
                  <Form.Label>Imagen</Form.Label>
                  <Form.Control placeholder="" name="image" value={this.state.tag.image} onChange={event => this.handleChange(event)} />
                </Form.Group>
          </Col>
           <Col sm="3">
                <Image src={this.state.tag.image } rounded fluid className="imagetable" style={{ display: this.state.valid_url  ? "block" : "none" }} />
          </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default TagEdit;
