// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/fondologin.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/*\r\n * Login scss File\r\n*/\ndiv.container {\n  height: 100%; }\n\n.backlogin {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  min-height: 100vh; }\n  .backlogin .canvas {\n    min-height: 100vh;\n    background-color: rgba(0, 0, 0, 0.6); }\n\ndiv#root .login {\n  padding-top: 10em; }\n  div#root .login .card {\n    background: #fff !important;\n    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3) !important;\n    padding: 40px; }\n    div#root .login .card button[type=\"submit\"] {\n      margin-left: calc(50% - 50px);\n      width: 100px; }\n    div#root .login .card #errorlogin {\n      margin-top: 2em !important; }\n    div#root .login .card .hiddenError {\n      display: none; }\n", ""]);
// Exports
module.exports = exports;
