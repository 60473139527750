import React from 'react';
import TitlePage from './../../components/page/title';
import TableTic from './../../components/page/table';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import './Administradores.scss';
import { userService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form } from 'react-bootstrap';
import queryString from 'query-string';




class Administradores  extends Home {


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      console.log(params);
      this.state['headers'] =  ["Email","Telefono","Nombre","Apellido","Ciudad","Pais","Rol"];
      this.state['fields'] =  ["email","phone","name","lastname","city","state","rol.code"];
      this.state['type'] =  ["text","text","text","text","text","text","text"];
      this.state['data'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 20;
      this.state['filteremail'] = params.email?params.email:"";
      this.state['filtername'] =  params.name?params.name:"";
      this.state['filterphone'] =  params.phone?params.phone:"";
      this.state['query'] = "";
      this.state['link'] = "/administradores/";
      if (params.page) {
        this.state['page'] = params.page;
        this.callUser(params.page,params.email?params.email:"",params.name?params.name:"",params.phone?params.phone:"");
      } else {
        this.state['page'] = 0;
        this.callUser(0,params.email?params.email:"",params.name?params.name:"",params.phone?params.phone:"");
      }

  }

  callUser (offset,email,name,phone){

      let callback = (datarows, error) => {

        if (datarows) {
           this.setState({data:datarows.data,total:datarows.total});
        }

      }
      userService.administradores(offset,name,email,phone, callback);
  }

  clickCell(id){
    window.location.href="/administrador?id="+id;
  }

 getFilter(){
   var query=""
   if(this.state.filteremail !== ""){
     if(query !== ""){
        query = query = "&";
     }
     query = query + "email=" + this.state.filteremail ;
   }
   if(this.state.filterphone !== ""){
     if(query !== ""){
        query = query = "&";
     }
     query = query + "phone=" + this.state.filterphone ;
   }
   if(this.state.filtername !== ""){
     if(query !== ""){
        query = query = "&";
     }
     query = query + "name=" + this.state.filtername ;
   }
   return query;
 }

 handleChange(event){
      const value = event.target.value
      console.log(event.target.name, value)

      if (event.target.name === "email") {
          console.log("Cambiar email => " + value);
          this.setState({filteremail:value});
      } else if (event.target.name === "name") {
          this.setState({filtername:value});
          console.log("Cambiar name => " + value);
      } else if (event.target.name === "phone") {
          this.setState({filterphone:value});
          console.log("Cambiar telefono => " + value);
      }
    }

  handleClickSearch(event) {
    this.setState({query:this.getFilter()});
    window.location.href="/administradores?page=0&"+this.getFilter();
  }

  clickDel(id){
    let callback = (datarows, error) => {
       window.location.reload();
    }
    userService.remove(id, callback);
  }



  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de admnistradores" newbutton={true} link="/administradornew"/>
           <Accordion defaultActiveKey="0">
             <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="default" eventKey="0">
                  Filtrar
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="filterpanel">
                  <Row>
                    <Col sm="4">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="" name="email" value={this.state.filteremail} onChange={event => this.handleChange(event)} />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Teléfono</Form.Label>
                          <Form.Control type="number" placeholder="" name="phone" value={this.state.filterphone} onChange={event => this.handleChange(event)}  />
                        </Form.Group>
                    </Col>
                    <Col sm="4">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control type="text" placeholder="" name="name" value={this.state.filtername} onChange={event => this.handleChange(event)}  />
                        </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                      <Col className="text-right">
                          <Button variant="primary" className="btnnew" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                      </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
           </Accordion>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} handlerdel={this.clickDel} link={this.state.link} query={this.state.query} />
      </div></>
  };
}

export default Administradores;
