import React from 'react';
import ReactDOM from 'react-dom';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import { adsService } from '../../services';
import { podcastService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form } from 'react-bootstrap';
import './Ads.scss';
import queryString from 'query-string';
import ReactTags from 'react-tag-autocomplete';


class Ads  extends Home {


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state['headers'] =  ["#","Titulo","URL","En APP","En Home","En Todos","Tags"];
      this.state['fields'] =  ["image","title","url","visibleInApp","visibleInInit","visibleAllTag","tags.name"];
      this.state['type'] =  ["image","text","text","boolean","boolean","boolean","array"];
      this.state['data'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 20;
      this.state['filtertitle'] = params.title?params.title:"";
      this.state['filtername'] = params.name?params.name:"";
      this.state['filterurl'] =  params.url?params.url:"";
      this.state['filtertags'] =  params.tags?this.readTag(params.tags):[];
      this.state['tags'] = [];
      this.state['link'] = "/ads/";
      this.state['query'] = "";
      if (params.page) {
        this.state['page'] =params.page;
        this.callEmisoras(params.page,this.state.filtertitle,this.state.filterurl,this.state.filtername,this.state.filtertags);
      } else {
        this.state['page'] = 0;
        this.callEmisoras(0,this.state.filtertitle,this.state.filterurl,this.state.filtername,this.state.filtertags);
      }

      this.reactTags = React.createRef()
      this.callTags();
  }

  readTag(tags){
    var array = []
    if (Array.isArray(tags)){
        tags.forEach((item, i) => {
            var split = item.split("|");
            array.push({id:split[0],name:split[1]});
        });
    } else {
          var split = tags.split("|");
          array.push({id:split[0],name:split[1]});
    }

    return array;

}

  callEmisoras (offset,name,url,title,tags){

    var tagsAux = [];
      if(tags && tags.length > 0){
         tags.forEach((item, i) => {
            tagsAux.push(item.id);
         });

      }
      let callback = (datarows, error) => {

        if (datarows) {
           this.setState({data:datarows.data,total:datarows.total});
        }

      }
      adsService.search(offset,name,url,title,tags, callback);
  }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({tags:datarows});
      }
    }
    podcastService.tags(callback);
  }

  handleChange(event){
       const value = event.target.value
       if (event.target.name === "title") {
           this.setState({filtertitle:value});
       } else if (event.target.name === "url") {
           this.setState({filterurl:value});
       } else if (event.target.name === "name") {
           this.setState({filtername:value});
       }  else if (event.target.name === "tags") {
        var node = ReactDOM.findDOMNode(this.refs.colTags);
        var options = [].slice.call(node.querySelectorAll('option'));
        var selected = options.filter(function (option) {
         return option.selected;
        });
        var selectedValues = [];
         selected.map(function (option) {
         selectedValues.push(option.value);
         return option.selected;
        });
        this.setState({filtertags:selectedValues});
    }
     }

  getFilter(){
       var query=""
       if(this.state.filtertitle !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "title=" + this.state.filtertitle ;
       }
       if(this.state.filterurl !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "url=" + this.state.filterurl ;
       }
       if(this.state.filtername !== ""){
        if(query !== ""){
           query = query + "&";
        }
        query = query + "name=" + this.state.filtername ;
      }
      if(this.state.filtertags.length > 0){
        debugger;
        this.state.filtertags.forEach( function(tag) {
            if(query !== ""){
               query = query + "&";
            }
            query = query + "tags=" + tag.id +"|"+tag.name;
        });
      }
       return query;
 }

 onDelete (i) {
  const tags = this.state.filtertags.slice(0)
  tags.splice(i, 1)
  this.setState({filtertags:tags});
}

onAddition (tag) {
  const tags = [].concat(this.state.filtertags, tag)
  this.setState({filtertags:tags});
}

 handleClickSearch(event) {
   this.setState({query:this.getFilter()});
   window.location.href="/ads?page0&"+encodeURI(this.getFilter());
 }

 handleClickClear(event) {
   window.location.href="/ads/";
 }


 clickCell(id){
   window.location.href="/adsedit?id="+id;
 }

 clickDel(id){
   let callback = (datarows, error) => {
      window.location.reload();
   }
   adsService.remove(id, callback);
 }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de anuncios" newbutton={true} link="/adsnew"/>
           <Accordion defaultActiveKey="0">
             <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="default" eventKey="0">
                  Filtrar
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="filterpanel">
                  <Row>
                    <Col sm="4">
                      <Form.Group >
                        <Form.Label>Titulo</Form.Label>
                        <Form.Control type="text" placeholder="" name="title" value={this.state.filtertitle} onChange={event => this.handleChange(event)} />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group >
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" placeholder="" name="name" value={this.state.filtername} onChange={event => this.handleChange(event)} />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="formLatitude">
                        <Form.Label>Url</Form.Label>
                        <Form.Control type="text" placeholder="" name="url" value={this.state.filterurl} onChange={event => this.handleChange(event)} />
                      </Form.Group>
                    </Col>
                    <Col sm="3">
                       <Form.Group >
                         <Form.Label>Tags</Form.Label>
                         <ReactTags allowNew={true} className="tagsinput" ref={this.reactTags} tags={this.state.filtertags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Etiquetas"/>
                       </Form.Group>
                  </Col>
                  </Row>
                  <Row>
                      <Col className="text-right">
                          <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                          <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                      </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
           </Accordion>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} handlerdel={this.clickDel} link={this.state.link} query={this.state.query} />
      </div></>
  };
}

export default Ads;
