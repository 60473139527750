import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import { stationService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form } from 'react-bootstrap';
import './Emisoras.scss';
import queryString from 'query-string';


class Emisoras  extends Home {


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state['headers'] =  ["#","Nombre","Audio","Posición"];
      this.state['fields'] =  ["image","name","url","position"];
      this.state['type'] =  ["image","text","audio","text"];
      this.state['data'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 20;
      this.state['filtername'] = params.name?params.name:"";
      this.state['filterurl'] =  params.url?params.url:"";
      this.state['link'] = "/emisoras";
      this.state['query'] = "";
      if (params.page) {
        this.state['page'] = params.page;
        this.callEmisoras(params.page,this.state.filtername,this.state.filterurl);
      } else {
        this.state['page'] = 0;
        this.callEmisoras(0,this.state.filtername,this.state.filterurl);
      }
  }

  callEmisoras (offset,name,url){

      let callback = (datarows, error) => {

        if (datarows) {
           this.setState({data:datarows.data,total:datarows.total});
        }

      }
      stationService.search(offset,name,url, callback);
  }

  handleChange(event){
       const value = event.target.value
       if (event.target.name === "name") {
           this.setState({filtername:value});
       } else if (event.target.name === "url") {
           this.setState({filterurl:value});
       }
     }

  getFilter(){
       var query=""
       if(this.state.filtername !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "name=" + this.state.filtername ;
       }
       if(this.state.filterurl !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "url=" + this.state.filterurl ;
       }
       return query;
 }

 handleClickSearch(event) {
   this.setState({query:this.getFilter()});
   window.location.href="/emisoras?page=0&"+encodeURI(this.getFilter());
 }

 handleClickClear(event) {
   window.location.href="/emisoras";
 }


 clickCell(id){
   window.location.href="/emisora?id="+id;
 }



 clickDel(id){
   let callback = (datarows, error) => {
      window.location.reload();
   }
   stationService.remove(id, callback);
 }

  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de emisoras" newbutton={true} link="/emisoranew"/>
           <Accordion defaultActiveKey="0">
             <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="default" eventKey="0">
                  Filtrar
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="filterpanel">
                  <Row>
                    <Col sm="4">
                      <Form.Group >
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="" name="name" value={this.state.filtername} onChange={event => this.handleChange(event)} />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group controlId="formLatitude">
                        <Form.Label>Url</Form.Label>
                        <Form.Control type="text" placeholder="" name="url" value={this.state.filterurl} onChange={event => this.handleChange(event)} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                      <Col className="text-right">
                          <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                          <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                      </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
           </Accordion>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} handlerdel={this.clickDel} link={this.state.link} query={this.state.query} />
      </div></>
  };
}

export default Emisoras;
