import BaseService from './_base.service';

export const userService = {
    login,
    recovery,
    logout,
    administradores,
    clients,
    one,
    create,
    remove,
    edit,
    exportuser
};

function login(email, pass, callback) {

    // localStorage.setItem('user', JSON.stringify(user));
    let body = JSON.stringify({ email, pass })
    console.log (body, email, pass)
    return BaseService.post("/login", body, callback, true)
}

function recovery(email, callback) {

    // localStorage.setItem('user', JSON.stringify(user));
    let body = JSON.stringify({ email })
    return BaseService.post("/recoverypass", body, callback, true)
}


function logout() {
    localStorage.removeItem('user');
}


function administradores(offset,name,email,phone, callback) {

    // localStorage.setItem('user', JSON.stringify(user));
    let body = JSON.stringify({ name,email,phone })
    return BaseService.post("/user/search/admin/"+offset, body, callback, true)
}



function clients(offset,name,email,phone, callback) {

    // localStorage.setItem('user', JSON.stringify(user));
    let body = JSON.stringify({ name,email,phone })
    return BaseService.post("/user/search/client/"+offset, body, callback, true)
}




function one(id, callback) {
    return BaseService.get("/user/one/"+id, callback)
}


function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/user/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/user/", body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete("/user/"+id, callback);
}


function exportuser(callback) {
    return BaseService.get("/user/export", callback)
}
