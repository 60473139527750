import BaseService from './_base.service';

export const tagService = {
    search,
    all,
    edit,
    create,
    one,
    remove,
    programs,
    podcasts,
    exportTag,
    importfile
};

function search(offset,name, callback) {
    let body = JSON.stringify({ name})
    return BaseService.post("/tag/search/"+offset, body, callback, true)
}

function programs(callback) {
    return BaseService.get("/program/all/", callback);
}

function podcasts(callback) {
    return BaseService.get("/podcast/all/", callback);
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/tag/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/tag/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/tag/one/"+id, callback);
}
function remove(id,callback) {
    return BaseService.delete("/tag/"+id, callback);
}
function all(callback) {
    return BaseService.get("/tag/all/", callback);
}
function exportTag(callback) {
    return BaseService.get("/tag/export", callback)
}
function importfile(excel, callback) {
    return BaseService.postMultipart("/tag/import", excel, callback, true)
}