import React from 'react';
import { Navbar,NavDropdown,Nav, Image} from 'react-bootstrap'
import logo from '../../assets/img/logoradio.png'
import './NavBar.scss';



class NavBar extends React.Component {

  closeSession() {
    console.log('entra en cerra sesion');
    localStorage.removeItem('user');
    window.location.href = "/";

  }

  constructor(props) {
      super(props)
      this.state = { user: props.user, callback:props.callback}
  }

  render() {
    return <Navbar bg="dark" expand="lg">
      <Navbar.Brand href="/home">  <Image src={logo} rounded fluid className="logoheader" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/administradores">Admnistradores</Nav.Link>
          <Nav.Link href="/usuarios">Usuarios</Nav.Link>
          <Nav.Link href="/podcasts">Podcast</Nav.Link>
          <Nav.Link href="/tags">Tags</Nav.Link>
          <Nav.Link href="/programs">Programas</Nav.Link>
          <Nav.Link href="/emisoras">Emisoras</Nav.Link>
          <Nav.Link href="/popups">Popups</Nav.Link>
          <Nav.Link href="/stories">Stories</Nav.Link>
          <Nav.Link href="/rutas">Rutas</Nav.Link>
          <Nav.Link href="/pois">Pois</Nav.Link>
          <Nav.Link href="/ads">Anuncios</Nav.Link>
          <Nav.Link href="/configuracion">Configuración</Nav.Link>
          <Nav.Link href="/RSSs">RSS</Nav.Link>
          <Nav.Link href="/linkaudios">Audios enlazados</Nav.Link>
        </Nav>
      </Navbar.Collapse>

      <NavDropdown title={this.state.user} id="basic-nav-dropdown">
        <NavDropdown.Item href="#" onClick={this.closeSession}> Salir </NavDropdown.Item>
      </NavDropdown>
    </Navbar>
  };
}

export default NavBar;
