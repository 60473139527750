import React from 'react';
import TitlePage from './../../components/page/title';
import TableTic from './../../components/page/table';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import './Roles.scss';
import { rolesService } from '../../services';
import queryString from 'query-string';


class Roles  extends Home {



    constructor(props) {
        super(props)
        let params = queryString.parse(this.props.location.search);
        console.log(params);
        this.state['headers'] =  ["Código","Nombre","Descripcion","Permisos"];
        this.state['fields'] =  ["code","name","descripction","permissions.name"];
        this.state['type'] =  ["text","text","text","array"];
        this.state['data'] = [];
        this.state['row'] = 0;
        this.state['total'] = 0;
        this.state['sizepage'] = 20;
        this.state['query'] = "";
        this.state['link'] = "/roles";
        if (params.page) {
          this.state['page'] = params.page;
          this.callRoles(params.page);
        } else {
          this.state['page'] = 0;
          this.callRoles(0);
        }

    }

    callRoles (offset){

        let callback = (datarows, error) => {

          if (datarows) {
             this.setState({data:datarows, total:datarows.length});
          }

        }
        rolesService.search(offset,callback);
    }

    clickCell(id){
      window.location.href="/rol?id="+id;
    }



    clickDel(id){
      let callback = (datarows, error) => {
         window.location.reload();
      }
      rolesService.remove(id, callback);
    }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de roles" newbutton={true} link="/rolnew" handler=""/>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} handlerdel={this.clickDel} link={this.state.link} query={this.state.query} identy="code" />
      </div></>
  };
}

export default Roles;
