import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import './Notification.scss';
import { notificationService } from '../../services';
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import toast from 'toasted-notes'


class Notification extends Home{


  constructor(props) {
      super(props)
      this.state.noti = {title:"",message:""};
      this.state.showconfirm = false;
  }


  handleChange(event){
       const value = event.target.value
       var noti = this.state.noti;
       if (event.target.name === "title") {
         noti.title = value;
       } else if (event.target.name === "message") {
         noti.message = value;
       }
       this.setState({noti:noti});
   }

    handleClickSave(event) {
      if (this.state.noti.title && this.state.noti.message && this.state.noti.title !== "" && this.state.noti.message) {
          this.callSend();
      } else if (!this.state.noti.title || this.state.noti.title === "" ) {
          const msg = "El titulo es obligatorio"
          toast.notify(msg, {position:'top', duration: 5000})
      } else {
          const msg = "El mensaje es obligatorio"
          toast.notify(msg, {position:'top', duration: 5000});
      }

    }



    callSend (){

        let callback = (data, error) => {
          if (data) {
             this.setState({noti:{title:"",message:""}});
             this.setState({showconfirm:true});
          }

        }
        notificationService.send(this.state.noti, callback);
    }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Enviar notificación" newbutton={false} link=""  />
         <Row>
            <Col sm="12">
                <Form.Group >
                  <Form.Label>Titulo</Form.Label>
                  <Form.Control type="text" placeholder="" name="title" value={this.state.noti.title} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="12">
                 <Form.Group >
                   <Form.Label>Mensaje</Form.Label>
                   <Form.Control as="textarea" rows="3" type="text" placeholder="" name="message" value={this.state.noti.message} onChange={event => this.handleChange(event)} required/>
                 </Form.Group>
             </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>
             </Col>
         </Row>
      </div>

      <Modal show={this.state.showconfirm} onHide={event => this.setState({showconfirm:false})}>
        <Modal.Header closeButton>
          <Modal.Title>Notificacion enviada</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>Se ha enviado la notificación correctamente</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={event => this.setState({showconfirm:false})}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </>
  };
}

export default Notification;
