import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import ImageUpload from './../../components/image';
import Home from './../../screens/Home';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { adsService } from '../../services';
import './Ads.scss';
import ReactTags from 'react-tag-autocomplete';
import toast from 'toasted-notes'
import { podcastService } from '../../services';
import { tagService } from '../../services';
import queryString from 'query-string';


class AdsEdit  extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state.ads = {title:"",name:"",priority:0,description:"",url:"",image:"", tags:[],visibleInApp:false, visibleInInit:false,visibleAllTag:false,programs:[],podcasts:[]};
      this.state.buttonvisibles = true;

      if (params.id) {
        this.state.idads = params.id;
        this.callSearch();
      } else{
        this.state.idads = -1;
      }

      this.handlerUpload = this.handlerUpload.bind(this);
      this.handlerDelete = this.handlerDelete.bind(this);


      this.reactTags = React.createRef()
      this.reactPrograms = React.createRef()
      this.reactPodcasts = React.createRef()

      this.callTags();
      this.callPrograms();
      this.callPodcasts();
  }

  callTags (){
    let callback = (datarows, error) => {
      if (datarows) {
        console.log(datarows);
         this.setState({tags:datarows});
      }
    }
    podcastService.tags(callback);
  }

  callPrograms (){
    let callback = (datarows, error) => {
      if (datarows) {
        console.log(datarows);
         this.setState({programs:datarows});
      }
    }
    tagService.programs(callback);
  }

  callPodcasts (){
    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({podcasts:datarows});
      }
    }
    tagService.podcasts(callback);
  }

  callSearch (){
      let callback = (data, error) => {
        if (data) {
           this.setState({ads:data});
        } else {
          const msg = "No se ha encontrado el anuncio";
          toast.notify(msg, {position:'top', duration: 5000})
          this.setState({buttonvisibles:false});
        }

      }
      adsService.one(this.state.idads, callback);
  }


   setChecked(event,checked) {

     var ads = this.state.ads;
     if (event.target.name === "visibleInApp") {
        ads.visibleInApp = checked;
     } else if (event.target.name === "visibleInInit") {
        ads.visibleInInit = checked;
     } else if (event.target.name === "visibleAllTag") {
        ads.visibleAllTag = checked;
     }

      this.setState({ads:ads});
   }


  handleChange(event){
       const value = event.target.value
       var ads = this.state.ads;
       if (event.target.name === "title") {
         ads.title = value;
       } else if (event.target.name === "url") {
         ads.url = value;
       } else if (event.target.name === "description") {
         ads.description = value;
       } else if (event.target.name === "name") {
         ads.name = value;
        } else if (event.target.name === "priority") {
          ads.priority = value;
        }

       this.setState({ads:ads});
  }


  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

   handleClickSave(event) {
     if (this.state.ads.title && this.state.ads.url && this.state.ads.title !== "" && this.state.ads.url !== "" && this.validURL(this.state.ads.url )) {
       if(this.state.idads !== -1){
         //EDIT
         this.callEdit();
       } else {
         //CREATE
         this.callCreate();
       }
     } else if (!this.state.ads.title || this.state.ads.title === "" ) {
         const msg = "El titulo es obligatorio"
         toast.notify(msg, {position:'top', duration: 5000})
     } else {
           const msg = "La url no tiene un formato correcto";
           toast.notify(msg, {position:'top', duration: 5000});
     }


   }

   callCreate (){

       let callback = (datarows, error) => {
         if (datarows && datarows.data) {
            window.location.href="/adsedit?id="+datarows.data.id;
         }

       }
       adsService.create(this.state.ads, callback);
   }

   callEdit (){

       let callback = (datarows, error) => {
         if (datarows && datarows.data) {
             const msg = "Se ha guardado correctamente";
             toast.notify(msg, {position:'top', duration: 5000});
         }

       }
       adsService.edit(this.state.idads,this.state.ads, callback);
   }

   handleClickClear(event) {
     window.location.href="/ads/";
   }

   handlerUpload(image){
     console.log('Upload 2 ' + image);
     var ads = this.state.ads;
     ads.image = image;
     this.setState({ads:ads});
   }

   handlerDelete(){
     var ads = this.state.ads;
     ads.image = "";
     this.setState({ads:ads});
   }

   onDelete (i) {
    const tags = this.state.ads.tags.slice(0)
    tags.splice(i, 1)
    var ads = this.state.ads;
    ads.tags = tags;
    this.setState({ads:ads});
   }

   onAddition (tag) {
    const tags = [].concat(this.state.ads.tags, tag)
    var ads = this.state.ads;
    ads.tags = tags;
    this.setState({ads:ads});
   }

   onDeleteProgram (i) {
    const programs = this.state.ads.programs.slice(0)
    programs.splice(i, 1)
    var ads = this.state.ads;
    ads.programs = programs;
    this.setState({ads:ads});
   }

   onAdditionProgram (program) {
    const programs = [].concat(this.state.ads.programs, program)
    var ads = this.state.ads;
    ads.programs = programs;
    this.setState({ads:ads});
   }

   onDeletePodcast (i) {
    const podcasts = this.state.ads.podcasts.slice(0)
    podcasts.splice(i, 1)
    var ads = this.state.ads;
    ads.podcasts = podcasts;
    this.setState({ads:ads});
   }

   onAdditionPodcast (podcast) {
    const podcasts = [].concat(this.state.ads.podcasts, podcast)
    var ads = this.state.ads;
    ads.podcasts = podcasts;
    this.setState({ads:ads});
   }

  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idads !== -1) return "Editar anuncio"; else return "Nuevo anuncio";})() } newbutton={false} link=""/>
         <Row>
         <Col sm="6">
                <Form.Group >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" placeholder="" name="name" value={this.state.ads.name} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>Title</Form.Label>
                  <Form.Control type="text" placeholder="" name="title" value={this.state.ads.title} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="9">
                 <Form.Group >
                   <Form.Label>Url</Form.Label>
                   <Form.Control type="text" placeholder="" name="url" value={this.state.ads.url} onChange={event => this.handleChange(event)} required/>
                 </Form.Group>
             </Col>
             <Col sm="3">
                 <Form.Group >
                   <Form.Label>Prioridad</Form.Label>
                   <Form.Control type="number" placeholder="" name="priority" value={this.state.ads.priority} onChange={event => this.handleChange(event)} required/>
                 </Form.Group>
             </Col>
             <Col sm="12">
                  <Form.Group >
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control as="textarea" rows="3" placeholder="" name="description" value={this.state.ads.description} onChange={event => this.handleChange(event)} />
                  </Form.Group>
              </Col>
              <Col sm="3">
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Visible en APP" name="visibleInApp" checked={this.state.ads.visibleInApp} onChange={(event) => this.setChecked(event,!this.state.ads.visibleInApp)}/>
                </Form.Group>
              </Col>
              <Col sm="3">
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Visible en home" name="visibleInInit" checked={this.state.ads.visibleInInit} onChange={(event) => this.setChecked(event,!this.state.ads.visibleInInit)}/>
                </Form.Group>
              </Col>
              <Col sm="3">
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Visible en todos los tags" name="visibleAllTag" checked={this.state.ads.visibleAllTag} onChange={(event) => this.setChecked(event,!this.state.ads.visibleAllTag)}/>
                </Form.Group>
              </Col>
              <Col sm="12">
                   <Form.Group >
                     <Form.Label>Tags</Form.Label>
                     <ReactTags allowNew="true" className="tagsinput" ref={this.reactTags} tags={this.state.ads.tags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Nuevas etiquetas"/>
                   </Form.Group>
             </Col>

             <Col sm="12">
                   <Form.Group >
                     <Form.Label>Programas</Form.Label>
                     <ReactTags allowNew="true" className="programsinput" ref={this.reactPrograms} tags={this.state.ads.programs} suggestions={this.state.programs} onDelete={this.onDeleteProgram.bind(this)} onAddition={this.onAdditionProgram.bind(this)} placeholderText="Nuevos programas"/>
                   </Form.Group>
             </Col>
             <Col sm="12">
                   <Form.Group >
                     <Form.Label>Podcasts</Form.Label>
                     <ReactTags allowNew="true" className="podcastsinput" ref={this.reactPodcasts} tags={this.state.ads.podcasts} suggestions={this.state.podcasts} onDelete={this.onDeletePodcast.bind(this)} onAddition={this.onAdditionPodcast.bind(this)} placeholderText="Nuevos podcast"/>
                   </Form.Group>
             </Col>
              <Col sm="12">
                  <ImageUpload image={this.state.ads.image}  upload={this.handlerUpload} deleted={this.handlerDelete} />
              </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default AdsEdit;
