import BaseService from './_base.service';

export const adsService = {
    search,
    edit,
    create,
    remove,
    one
};

function search(offset,name,url,title,tags, callback) {
    let body = JSON.stringify({ name,url,title,tags })
    return BaseService.post("/ads/search/"+offset, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/ads/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/ads/", body, callback, true)
}


function one(id,callback) {
    return BaseService.get("/ads/one/"+id, callback);
}


function remove(id,callback) {
    return BaseService.delete("/ads/"+id, callback);
}
