import BaseService from './_base.service';

export const poiService = {
    search,
    tags,
    all,
    //programs,
    podcasts,
    routes,
    edit,
    create,
    one,
    remove,
    //importfile,
    //exportPodcast
};

function search(offset,latitude,longitude,tags,title,id,route_all,general, callback) {
    let route = route_all.map(a => a.id);
    console.log(route)
    let isPoi = true;
    let body = JSON.stringify({ general,latitude,longitude,tags,title,id,route, isPoi})
    return BaseService.post("/podcast/search/"+offset, body, callback, true)
}

function tags(callback) {
    return BaseService.get("/tag/all/", callback);
}

function podcasts(callback) {
    return BaseService.get("/podcast/all/", callback);
}

function routes(callback) {
    return BaseService.get("/route/all/", callback);
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/podcast/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/podcast/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/podcast/one/"+id, callback);
}

function remove(id,callback) {
    return BaseService.delete("/podcast/"+id, callback);
}
function all(callback) {
    let podcasts = BaseService.get("/podcast/all/", callback);
    let pois = podcasts.filter(x => x.isPoi == true);
    return pois
}

