import React from 'react';
import { Button, Row, Col, Form , Image} from 'react-bootstrap';
import './ImageUpload.scss';
import { uploadService } from '../../services';




class ImageUpload extends React.Component {

  constructor(props) {
      super(props)
      this.state = { image: props.image, upload:props.upload,deleted:props.deleted}
      if(props.columns){
        this.state.columns = props.columns;
      } else {
        this.state.columns = 2;
      }
      this.inputup = React.createRef();
      this.HOST = process.env.REACT_APP_HOST;
      this.handleSelectedImage = this.handleSelectedImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
      this.setState({image:nextProps.image})
  }


   handleUpload(){
      this.inputup.current.click()
   }

   handleDeleted(){
      this.setState({image:""})
      this.state.deleted()
   }

   handleSelectedImage(event){
     const value = event.target.files[0];

     if (value) {
       let callback = (data, error) => {
         if (data) {
            this.setState({image:data.data});
            console.log('Uploda ' + data.data);
            this.state.upload(data.data);
         }
       }
       uploadService.upload(value, callback);
     }

   }

  render() {
    return <Row>
            <Col sm="12" hidden>
              <Form.Group>
                  <Form.File ref={this.inputup} label="Imagen"  onChange={event => this.handleSelectedImage(event)}/>
              </Form.Group>
            </Col>
            <Col sm={this.state.columns}>
              <div className="paneImageUp">
                { (this.state.image && this.state.image !== "")? <Image src={this.HOST+"/image/"+this.state.image} rounded fluid className="imageUpload" /> : ""}
              </div>
            </Col>
            <Col sm={12-this.state.columns}>
                <Row>
                    <Col sm="12">
                      <Button variant="info" className="" onClick={() => this.handleUpload()}>Subir imagen</Button><br/><br/>
                    </Col>
                    {(this.state.image && this.state.image !== "")? <Col sm="12"> <Button variant="danger" className="" onClick={() => this.handleDeleted()}>Borrar</Button></Col> : ""}

                </Row>
            </Col>
          </Row>
  };
}

export default ImageUpload;
