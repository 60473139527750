import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import { userService } from '../../services';
import './Usuarios.scss';
import toast from 'toasted-notes';
import { podcastService } from '../../services';
import queryString from 'query-string';


class UsuariosEdit  extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state.usuario = {name:"",lastname:"",email:"",phone:"",street:"",city:"",country:"",podcasterCode:"",suscribeTags:[]};
      this.state.suscribeTags = [];
      this.callTags();
      if (params.id) {
        this.state.iduser = params.id;
        this.callSearch();
      } else{
        this.state.iduser = -1;
      }

      this.reactTags = React.createRef()
      this.HOST = process.env.REACT_APP_HOST;

  }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
        console.log(datarows);
         this.setState({tags:datarows});
      }
    }
    podcastService.tags(callback);
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
           this.setState({usuario:data});
           if(data.suscribeTags &&  data.suscribeTags.length > 0){
             this.setState({suscribeTags:data.suscribeTags});
           }
        } else {
          const msg = "No se ha encontrado el usuario"
          toast.notify(msg, {position:'top', duration: 5000})
        }

      }
      userService.one(this.state.iduser, callback);
  }

   handleClickClear(event) {
     window.location.href="/usuarios";
   }


  render () {
    console.log("USUARIOS-->");
      console.log(this.state.usuario);
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.iduser !== -1) return "Información de usuario"; else return "Nueva usuario";})() } newbutton={false} link=""/>
         <Row>
         <Col sm="3">
          {this.state.usuario.picture && this.state.usuario.picture !== "" ?<Image src={this.HOST+"/image/"+this.state.usuario.picture} circle fluid /> :""}
         </Col>
         <Col sm="9">
           <Row>
              <Col sm="6">
                  <Form.Group >
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" placeholder="" name="name" value={this.state.usuario.name} readonly/>
                  </Form.Group>
              </Col>
              <Col sm="6">
                   <Form.Group >
                     <Form.Label>Apellido</Form.Label>
                     <Form.Control type="text" placeholder="" name="lastname" value={this.state.usuario.lastname} readonly/>
                   </Form.Group>
               </Col>
               <Col sm="6">
                    <Form.Group >
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="text" placeholder="" name="email" value={this.state.usuario.email} readonly/>
                    </Form.Group>
                </Col>
                <Col sm="6">
                     <Form.Group >
                       <Form.Label>Teléfono</Form.Label>
                       <Form.Control type="text" placeholder="" name="phone" value={this.state.usuario.phone} readonly/>
                     </Form.Group>
                 </Col>
                 <Col sm="12">
                      <Form.Group >
                        <Form.Label>Código Podcaster</Form.Label>
                        <Form.Control type="text" placeholder="" name="podcasterCode" value={this.state.usuario.podcasterCode} readonly/>
                      </Form.Group>
                </Col>
                 <Col sm="4">
                      <Form.Group >
                        <Form.Label>Calle</Form.Label>
                        <Form.Control type="text" placeholder="" name="street" value={this.state.usuario.street} readonly/>
                      </Form.Group>
                  </Col>
                  <Col sm="4">
                       <Form.Group >
                         <Form.Label>Ciudad</Form.Label>
                         <Form.Control type="text" placeholder="" name="city" value={this.state.usuario.city} readonly/>
                       </Form.Group>
                   </Col>
                   <Col sm="4">
                        <Form.Group >
                          <Form.Label>País</Form.Label>
                          <Form.Control type="text" placeholder="" name="country" value={this.state.usuario.country} readonly/>
                        </Form.Group>
                  </Col>
                </Row>
              </Col>
         </Row>
         <Row>
            <Col sm="4">
              <Form.Group >
                <Form.Label>Tag suscripto</Form.Label>
                <ul class="taglist">
                    {this.state.suscribeTags.map((tag,idx) => (<li>{tag.name}</li> ))}

                </ul>
              </Form.Group>
            </Col>
            <Col sm="4">
                 <Form.Group >
                   <Form.Label>Podcast visitados</Form.Label>
                   <Form.Control type="text" placeholder="" name="country" value={this.state.usuario.countVisited} readonly/>
                 </Form.Group>
           </Col>
           <Col sm="4">
                <Form.Group >
                  <Form.Label>Podcast descargados</Form.Label>
                  <Form.Control type="text" placeholder="" name="country" value={this.state.usuario.countDonwload} readonly/>
                </Form.Group>
          </Col>

         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Atras</Button>
             </Col>
         </Row>
    </div></>
  };
}

export default UsuariosEdit;
