import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import { linkAudioService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form } from 'react-bootstrap';
import './LinkAudio.scss';
import queryString from 'query-string';


class LinkAudios  extends Home {
  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state['headers'] =  ["Id","Título","Tipo"];
      this.state['fields'] =  ["id","title","type"];
      this.state['type'] =  ["text","text","text"];
      this.state['data'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 20;
      this.state['filtertitle'] = params.title?params.title:"";
      this.state['filtertype'] =  params.type?params.type:"";
      this.state['link'] = "/linkaudios";
      this.state['query'] = "";
      if (params.page) {
        this.state['page'] = params.page;
        this.callLinkAudios(params.page,this.state.filtertitle,parseInt(this.state.filtertype));
      } else {
        this.state['page'] = 0;
        this.callLinkAudios(0,this.state.filtertitle,parseInt(this.state.filtertype));
      }
  }

  callLinkAudios (offset,name,type){

      let callback = (datarows, error) => {

        if (datarows) {
           datarows.data.forEach(x =>{
             let type_name = "";
             if(x.type == 1) type_name = "PreRoll 1";
             if(x.type == 2) type_name = "PreRoll 2 - Tag";
             if(x.type == 3) type_name = "PreRoll 3 - Podcast";
             if(x.type == 4) type_name = "PostRoll 1 - Podcast";
             x.type = type_name;
           })
           this.setState({data:datarows.data,total:datarows.total});
        }

      }
      console.log("AQUI")
      console.log(name)
      linkAudioService.search(offset,name,type, callback);
  }

  handleChange(event){
       const value = event.target.value
       if (event.target.name === "title") {
           this.setState({filtertitle:value});
       }  else if (event.target.name === "type") {
        this.setState({filtertype:value});
      }
     }

  getFilter(){
       var query=""
       if(this.state.filtertitle !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "title=" + this.state.filtertitle ;
       }
       //CAMBIAR
       if(this.state.filtertype !== ""){
        if(query !== ""){
           query = query + "&";
        }
        query = query + "type=" + this.state.filtertype ;
      }
       return query;
 }

 handleClickSearch(event) {
   this.setState({query:this.getFilter()});
   window.location.href="/linkaudios?page=0&"+encodeURI(this.getFilter());
 }

 handleClickClear(event) {
   window.location.href="/linkaudios";
 }


 clickCell(id){
   window.location.href="/linkaudio?id="+id;
 }



 clickDel(id){
   let callback = (datarows, error) => {
      window.location.reload();
   }
   linkAudioService.remove(id, callback);
 }

  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de audios enlazados" newbutton={true} link="/linkaudionew"/>
           <Accordion defaultActiveKey="0">
             <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="default" eventKey="0">
                  Filtrar
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="filterpanel">
                  <Row>
                    <Col sm="6">
                      <Form.Group >
                        <Form.Label>Título</Form.Label>
                        <Form.Control type="text" placeholder="" name="title" value={this.state.filtertitle} onChange={event => this.handleChange(event)} />
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group controlId="formType">
                          <Form.Label>Tipo de Audio enlazado</Form.Label>
                          <Form.Control as="select" name="type" onChange={event => this.handleChange(event)} value={this.state.filtertype} >
                            <option value="">---</option>
                            <option value="1">PreRoll 1</option>
                            <option value="2">PreRoll 2 - Tag</option>
                            <option value="3">PreRoll 3 - Podcast</option>
                            <option value="4">PostRoll 1 - Podcast</option>
                          </Form.Control>
                      </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                      <Col className="text-right">
                          <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                          <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                      </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
           </Accordion>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} handlerdel={this.clickDel} link={this.state.link} query={this.state.query} />
      </div></>
  };
}

export default LinkAudios;
