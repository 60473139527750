import AdministradoresEdit from './AdministradoresEdit.js';
import Administradores from './Administradores.js';

export {
  AdministradoresEdit,
  Administradores
}


export { default } from './Administradores.js';
