import React from 'react';
import './Home.scss';
import jwt from 'jwt-decode';

const testLogin = function(){
  let json = localStorage.getItem('user')
  if (!json || json === 'undefined') {
    return false;
  }
  let user = JSON.parse(json);

  if (user && user.token) {
    var decodedToken = jwt(user.token);
    console.log(decodedToken);
    var dateNow = new Date();
    const expToken = decodedToken.exp * 1000;
    console.log(expToken);
    console.log(dateNow.getTime());
    if (expToken < dateNow.getTime()) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};



class Home  extends React.Component {



  changePage (){
    console.log('Entra en changePage');
    if (!testLogin()){
      window.location.href = "/";
    }
  };





  constructor(props) {
      super(props)
        console.log('Entra en HOME');
      let json = localStorage.getItem('user')
      var nameuser = '';
        console.log(json);
      if (!json || json === 'undefined' || json === '') {
        nameuser = '';
      } else {
        let user = JSON.parse(json);
        console.log(user);
        nameuser = user.user.name;
      }
      this.changePage();
      this.state = { isLogin: testLogin(), page:'home', user: nameuser}
      //setInterval(this.changePage, 60000);
  }


}

export default Home;
