import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import './Configuracion.scss';
import { Row, Col } from 'react-bootstrap';
import { faUserTag, faAlignJustify, faBell, faHome} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;


class Configuracion  extends Home {

  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Configuración" newbutton={false} link="" handler=""/>
         <Row>
            <Col sm="2">
              <a className="btnconf" href="/roles"> <FontAwesomeIcon icon={faUserTag} /><br/>Roles</a>
            </Col>
             <Col sm="2">
               <a className="btnconf" href="/confighome"> <FontAwesomeIcon icon={faHome} /><br/>Home</a>
             </Col>
             <Col sm="2">
               <a className="btnconf" href="/parameters"> <FontAwesomeIcon icon={faAlignJustify} /><br/>Parametros</a>
             </Col>
            <Col sm="2">
              <a className="btnconf" href="/notification"> <FontAwesomeIcon icon={faBell} /><br/>Notificacion</a>
            </Col>
         </Row>
      </div></>
  };
}

export default Configuracion;
