import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import './index.css';

import App from './App';
import Login from './screens/Auth/Login'
//import Home from './screens/Home'
import MainBody from './screens/Main';
import {Podcast, PodcastEdit} from './screens/podcast';
import {Popup, PopupEdit} from './screens/popup';
import {Usuarios,UsuariosEdit} from './screens/usuarios';
import {Roles,RolesEdit} from './screens/roles';
import Configuracion from './screens/configuracion';
import {Emisoras, EmisoraEdit}   from './screens/emisoras';
import {Ads, AdsEdit}   from './screens/ads';
import {Administradores, AdministradoresEdit}   from './screens/administradores';
import {Notification}   from './screens/notification';
import {Parameters}   from './screens/parameters';
import {Confighome}   from './screens/confighome';
import {Tag, TagEdit} from './screens/tag';
import {Program, ProgramEdit} from './screens/program';
import {RSS, RSSEdit} from './screens/RSS';
import {Story, StoryEdit} from './screens/story';
import {Ruta, RutaEdit} from './screens/ruta';
import {Poi, PoiEdit} from './screens/poi';
import {LinkAudios, LinkAudioEdit} from './screens/linkaudio';


import Profile  from './screens/profile';

import * as serviceWorker from './serviceWorker';

const routing = (
  <Router>
    <Switch>
      <Route path="/login"       exact={true} component={Login} />
      <Route path="/"            exact={true} component={App} />
      <Route path='/home' exact={true} component={MainBody} />
      <Route path='/podcasts' exact={true} component={Podcast} />
      <Route path='/podcast' exact={true} component={PodcastEdit} />
      <Route path='/podcastnew' exact={true} component={PodcastEdit} />
      <Route path='/tags' exact={true} component={Tag} />
      <Route path='/tag' exact={true} component={TagEdit} />
      <Route path='/tagnew' exact={true} component={TagEdit} />
      <Route path='/programs' exact={true} component={Program} />
      <Route path='/program' exact={true} component={ProgramEdit} />
      <Route path='/programnew' exact={true} component={ProgramEdit} />
      <Route path='/popups' exact={true} component={Popup} />
      <Route path='/popup' exact={true} component={PopupEdit} />
      <Route path='/popupnew' exact={true} component={PopupEdit} />
      <Route path='/usuarios' exact={true} component={Usuarios} />
      <Route path='/usuario' exact={true} component={UsuariosEdit} />
      <Route path='/notification' exact={true} component={Notification} />
      <Route path='/parameters' exact={true} component={Parameters} />
      <Route path='/roles' exact={true} component={Roles} />
      <Route path='/rol' exact={true} component={RolesEdit} />
      <Route path='/rolnew' exact={true} component={RolesEdit} />
      <Route path='/configuracion' exact={true} component={Configuracion} />
      <Route path='/emisoras' exact={true} component={Emisoras} />
      <Route path='/emisoranew' exact={true} component={EmisoraEdit} />
      <Route path='/emisora' exact={true} component={EmisoraEdit} />
      <Route path='/administradores' exact={true} component={Administradores} />
      <Route path='/administradornew' exact={true} component={AdministradoresEdit} />
      <Route path='/administrador' exact={true} component={AdministradoresEdit} />
      <Route path='/confighome' exact={true} component={Confighome} />
      <Route path='/ads' exact={true} component={Ads} />
      <Route path='/adsnew' exact={true} component={AdsEdit} />
      <Route path='/adsedit' exact={true} component={AdsEdit} />
      <Route path='/profile' exact={true} component={Profile} />
      <Route path='/RSSs' exact={true} component={RSS} />
      <Route path='/RSS' exact={true} component={RSSEdit} />
      <Route path='/RSSnew' exact={true} component={RSSEdit} />
      <Route path='/stories' exact={true} component={Story} />
      <Route path='/storynew' exact={true} component={StoryEdit} />
      <Route path='/story' exact={true} component={StoryEdit} />
      <Route path='/rutas' exact={true} component={Ruta} />
      <Route path='/rutanew' exact={true} component={RutaEdit} />
      <Route path='/ruta' exact={true} component={RutaEdit} />
      <Route path='/pois' exact={true} component={Poi} />
      <Route path='/poinew' exact={true} component={PoiEdit} />
      <Route path='/poi' exact={true} component={PoiEdit} />
      <Route path='/linkaudios' exact={true} component={LinkAudios} />
      <Route path='/linkaudio' exact={true} component={LinkAudioEdit} />
      <Route path='/linkaudionew' exact={true} component={LinkAudioEdit} />
    </Switch>
  </Router>

)

ReactDOM.render(
  routing,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
