import BaseService from './_base.service';

export const stationService = {
    all,
    search,
    edit,
    create,
    remove,
    tags,
    one
};

function all(callback) {
    return BaseService.get("/station/all/", callback, true)
}

function tags(callback) {
    return BaseService.get("/tag/all/", callback);
}

function search(offset,name,url, callback) {
    let body = JSON.stringify({ name,url })
    return BaseService.post("/station/search/"+offset, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/station/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/station/", body, callback, true)
}


function one(id,callback) {
    return BaseService.get("/station/one/"+id, callback);
}
function remove(id,callback) {
    return BaseService.delete("/station/"+id, callback);
}
