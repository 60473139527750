import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { linkAudioService } from '../../services';
import './LinkAudio.scss';
import toast from 'toasted-notes'
import queryString from 'query-string';


class LinkAudioEdit  extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state.linkAudio = {title:"",type:0,tag:0,podcast:0};
      this.state.buttonvisibles = true;

      if (params.id) {
        this.state.idlinkaudio = params.id;
        this.callSearch();
      } else{
        this.state.idlinkaudio = -1;
      }

      //this.handlerUpload = this.handlerUpload.bind(this);
      //this.handlerDelete = this.handlerDelete.bind(this);

      this.state.podcasts = [];
      this.state.tags = [];

      this.callPodcasts();
      this.callTags();
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
           this.setState({linkAudio:data});
        } else {
          const msg = "No se ha encontrado el audio enlazado"
          toast.notify(msg, {position:'top', duration: 5000})
          this.setState({buttonvisibles:false});
        }

      }
      linkAudioService.one(this.state.idlinkaudio, callback);
  }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({tags:datarows});
      }
    }
    linkAudioService.tags(callback);
  }
  callPodcasts (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({podcasts:datarows});
      }
    }
    linkAudioService.podcasts(callback);
  }

 handleClickClear(event) {
   window.location.href="/linkaudios";
 }


  handleChange(event){
       const value = event.target.value
       var linkAudio = this.state.linkAudio;
       if (event.target.name === "title") {
        linkAudio.title = value;
       } else if (event.target.name === "url") {
        linkAudio.url = value;
       } else if (event.target.name === "type") {
        linkAudio.type = parseInt(value);
      } else if (event.target.name === "tag") {
        linkAudio.tag = parseInt(value);
      } else if (event.target.name === "podcast") {
        linkAudio.podcast = parseInt(value);
      }


       this.setState({linkAudio:linkAudio});
  }

   handleClickSave(event) {
     if (this.state.linkAudio.title && this.state.linkAudio.title !== "") {
       if(this.state.idlinkaudio !== -1){
         //EDIT
         this.callEdit();
       } else {
         //CREATE
         this.callCreate();
       }
     } else if (!this.state.linkAudio.title || this.state.linkAudio.title === "" ) {
         const msg = "El nombre es obligatorio"
         toast.notify(msg, {position:'top', duration: 5000})
     }


   }

   callCreate (){

       let callback = (datarows, error) => {
         if (datarows && datarows.data) {
            this.setState({linkAudio:datarows.data});
            this.setState({idlinkaudio:datarows.data.id});
            window.location.href="/linkaudio?id="+datarows.data.id;
         }

       }
       linkAudioService.create(this.state.linkAudio, callback);
   }

   callEdit (){

       let callback = (datarows, error) => {
         if (datarows && datarows.data) {
             const msg = "Se ha guardado correctamente";
             toast.notify(msg, {position:'top', duration: 5000});
         }

       }
       linkAudioService.edit(this.state.idlinkaudio,this.state.linkAudio, callback);
   }

   handleClickClear(event) {
     window.location.href="/linkaudios/";
   }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idlinkaudio !== -1) return "Editar audio enlazado"; else return "Nuevo audio enlazado";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="12">
                <Form.Group >
                  <Form.Label>Título</Form.Label>
                  <Form.Control type="text" placeholder="" name="title" value={this.state.linkAudio.title} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="12">
                 <Form.Group >
                   <Form.Label>Url</Form.Label>
                   <Form.Control type="text" placeholder="" name="url" value={this.state.linkAudio.url} onChange={event => this.handleChange(event)} required/>
                 </Form.Group>
             </Col>
             <Col sm="4">
                 <Form.Group controlId="formPro">
                   <Form.Label>Tipo de audio</Form.Label>
                   <Form.Control as="select" name="type" onChange={event => this.handleChange(event)} value={String(this.state.linkAudio.type)} >
                    <option value="1">PreRoll 1</option>
                    <option value="2">PreRoll 2 - Tag</option>
                    <option value="3">PreRoll 3 - Podcast</option>
                    <option value="4">PostRoll 1 - Podcast</option>
                   </Form.Control>
                 </Form.Group>
             </Col>
             <Col sm="4">
                 <Form.Group controlId="formPro">
                   <Form.Label>Tag del audio</Form.Label>
                   <Form.Control as="select" name="tag" onChange={event => this.handleChange(event)} value={this.state.linkAudio.tag} >
                     <option value="">---</option>
                     {this.state.tags.map((tag,idx) => (
                         <option value={tag.id}>{tag.name}</option>
                     ))}
                   </Form.Control>
                 </Form.Group>
             </Col>
             <Col sm="4">
                 <Form.Group controlId="formPro">
                   <Form.Label>Podcast del audio</Form.Label>
                   <Form.Control as="select" name="podcast" onChange={event => this.handleChange(event)} value={this.state.linkAudio.podcast} >
                     <option value="">---</option>
                     {this.state.podcasts.map((tag,idx) => (
                         <option value={tag.id}>{tag.title}</option>
                     ))}
                   </Form.Control>
                 </Form.Group>
             </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default LinkAudioEdit;
