import BaseService from './_base.service';

export const reviewService = {
    all,
    remove
};

function all(podcastId,callback) {
    let body = JSON.stringify({'podcastId': parseInt(podcastId)})
    return BaseService.post("/app/review/find/", body, callback, true)
}

function remove(id, callback) {
    return BaseService.delete("/review/"+id, callback);
}
