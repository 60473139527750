import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import './Parameters.scss';
import { configurationService } from '../../services';
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import toast from 'toasted-notes'


class Parameters extends Home{


  constructor(props) {
      super(props)
      this.state.configuration = {url_final:"",orderpodcast:""};
      this.state.showconfirm = false;
      this.callSearch();
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
           this.setState({configuration:data});
        } else {
          const msg = "No se ha encontrado la configuración por defecto";
          toast.notify(msg, {position:'top', duration: 5000});
          this.setState({buttonvisibles:false});
        }

      }
      configurationService.one(1, callback);
  }


  handleChange(event){
       const value = event.target.value
       var configuration = this.state.configuration;
       if (event.target.name === "url_final") {
         configuration.url_final = value;
       } else if (event.target.name === "orderpodcast") {
         configuration.orderpodcast = value;
       }
       this.setState({configuration:configuration});
   }

    handleClickSave(event) {
      if (this.state.configuration.url_final && this.state.configuration.orderpodcast && this.state.configuration.url_final !== "" && this.state.configuration.orderpodcast !== '') {
          this.callSend();
      } else if (!this.state.configuration.url_final || this.state.configuration.url_final === "" ) {
          const msg = "La url es obligatoria"
          toast.notify(msg, {position:'top', duration: 5000})
      } else {
          const msg = "El orden es obligatorio"
          toast.notify(msg, {position:'top', duration: 5000});
      }

    }



    callSend (){

        let callback = (data, error) => {
          if (data) {
             this.setState({showconfirm:true});
          }

        }
        configurationService.edit(1,this.state.configuration, callback);
    }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Parametros de configuración" newbutton={false} link=""  />
         <Row>
            <Col sm="12">
                <Form.Group >
                  <Form.Label>Url audio secundario</Form.Label>
                  <Form.Control type="text" placeholder="" name="url_final" value={this.state.configuration.url_final} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="12">
                 <Form.Group >
                   <Form.Label>Mensaje</Form.Label>
                   <Form.Control as="select" type="text" placeholder="" name="orderpodcast" value={this.state.configuration.message} onChange={event => this.handleChange(event)} required>
                      <option value="VISITED">Más visitados</option>
                      <option value="DATE">Fecha</option>
                   </Form.Control>
                 </Form.Group>
             </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>
             </Col>
         </Row>
      </div>

      <Modal show={this.state.showconfirm} onHide={event => this.setState({showconfirm:false})}>
        <Modal.Header closeButton>
          <Modal.Title>configurationficacion enviada</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>Se ha enviado la configurationficación correctamente</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={event => this.setState({showconfirm:false})}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </>
  };
}

export default Parameters;
