import React from 'react';
import './TitlePage.scss';
import { Button } from 'react-bootstrap';



class TitlePage extends React.Component {


  constructor(props) {
      super(props)
      this.state = { title: props.title,newbutton:props.newbutton,link:this.props.link}
  }


  render() {
    return <h2 className="titlePage">{this.state.title} {(() => { if (this.state.newbutton) return  <Button variant="primary" className="btnnew" onClick={() => {window.location.href = this.state.link}}>Crear nuevo</Button>;})() }</h2>
  };
}

export default TitlePage;
