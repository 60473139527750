import BaseService from './_base.service';

export const configurationService = {
    edit,
    one,
    home,
    edithome,
    language
};

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/configuration/"+id, body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/configuration/one/"+id, callback);
}

function home(id,callback) {
    return BaseService.get("/confighome/locale/"+id, callback);
}

function edithome(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/confighome/"+id, body, callback, true)
}

function language(callback) {
    return BaseService.get("/app/language", callback, true)
}
