import React from "react";
import './App.scss';
import jwt from 'jwt-decode';
import { Redirect } from 'react-router-dom';

class App extends React.Component {

  testLogin (){
    let json = localStorage.getItem('user')
    if (!json || json === 'undefined') {
      console.log('Entra en es falso');
      return false;
    } else {
      console.log('JSON' + json);
    }
    let user = JSON.parse(json);

    if (user && user.tokenJwt) {
      var decodedToken = jwt(user.tokenJwt);
      console.log(decodedToken);
      var dateNow = new Date();
      const expToken = decodedToken.exp * 1000;
      console.log(expToken);
      console.log(dateNow.getTime());
      if (expToken < dateNow.getTime()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };


  render() {
    console.log('Compruea el estado');
    if (this.testLogin()) {
      return <Redirect to='/home' />
    } else {
      return <Redirect to='/login' />
    }
  }
}

export default App;
