import BaseService from './_base.service';

export const commentService = {
    all,
    remove
};

function all(podcastId,callback) {
    let body = JSON.stringify({'podcastId': parseInt(podcastId)})
    console.log("COMMENTS")
    console.log(body)
    return BaseService.post("/app/comment/find/", body, callback, true)
}

function remove(id, callback) {
    return BaseService.delete("/comment/"+id, callback);
}
