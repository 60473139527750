import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import './Popup.scss';
import { popupService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form, Modal } from 'react-bootstrap';
import queryString from 'query-string';


class Popup extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state['headers'] =  ["id","Nombre"];
      this.state['fields'] =  ["id","title"];
      this.state['type'] =  ["text","text"];
      this.state['data'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 20;
      this.state['filtertitle'] = params.title?params.title:"";
      this.state['query'] = this.getFilter();
      this.state['popups'] = [];
      this.state['popups'] = [];
      this.state['link'] = "/popups";
      this.state['showconfirm'] = false;
      this.state['showconfirmExport'] = false;

      if (params.page) {
        this.state['page'] = params.page;
        this.callPopup(params.page,this.state.filtertitle);
      } else {
        this.state['page'] = 0;
        this.callPopup(0,this.state.filtertitle);
      }

      this.inputup = React.createRef();
  }


  callPopup(offset,title){
      let callback = (datarows, error) => {

        if (datarows) {
           this.setState({data:datarows.data,total:datarows.total});
        }

      }
      popupService.search(offset,title, callback);
  }


   handleChange(event){
       const value = event.target.value

       if (event.target.name === "title") {
           this.setState({filtertitle:value});
       }
     }

     clickCell(id){
       window.location.href="/popup?id="+id;
     }


     clickDel(id){
       let callback = (datarows, error) => {
          window.location.reload();
       }
       popupService.remove(id, callback);
    }

    getFilter(){
       var query=""
       if(this.state.filtertitle !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "title=" + this.state.filtertitle ;
       }
       return query;
     }


  handleClickClear(event) {
    window.location.href="/popups";
  }
   handleClickSearch(event) {
     this.setState({query:this.getFilter()});
     window.location.href="/popups?page=0&"+this.getFilter();
   }

   handleUpload(){
    this.inputup.current.click()
 }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de popups" newbutton={true} link="/popupnew"  />
         <Accordion defaultActiveKey="0">
           <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="default" eventKey="0">
                Filtrar
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="filterpanel">
               <Row>
                  <Col sm="12">
                    <Form.Group >
                      <Form.Label>Título</Form.Label>
                      <Form.Control type="text" placeholder="" name="title" value={this.state.filtertitle} onChange={event => this.handleChange(event)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                        <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                    </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
         </Accordion>
         <Row>
             <Col sm="12" hidden>

               <Form.Group>
                   <Form.File ref={this.inputup} label="Imagen"  onChange={event => this.handleSelectedExcel(event)}/>
               </Form.Group>
             </Col>
         </Row>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} link={this.state.link} query={this.state.query} handlerdel={this.clickDel}  />

      </div>
      </>
  };
}

export default Popup;
