import BaseService from './_base.service';

export const programService = {
    search,
    all,
    edit,
    create,
    one,
    remove,
    tags,
    categories,
    exportProgram,
    importfile
};

function search(offset,name,type, callback) {
    let elementType = type
    let body = JSON.stringify({ name, elementType})
    return BaseService.post("/program/search/"+offset, body, callback, true)
}

function tags(callback) {
    return BaseService.get("/tag/all/", callback);
}

function categories(callback) {
    return BaseService.get("/category/all/", callback);
}

function edit(id, bodyelement, callback) {
    if(bodyelement.category.id == -1){
        bodyelement.category = null;
      }
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/program/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    if(bodyelement.category.id == -1){
        bodyelement.category = null;
      }

    let body = JSON.stringify(bodyelement)
    return BaseService.post("/program/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/program/one/"+id, callback);
}
function remove(id,callback) {
    return BaseService.delete("/program/"+id, callback);
}
function all(callback) {
    return BaseService.get("/program/all/", callback);
}
function exportProgram(callback) {
    return BaseService.get("/program/export", callback)
}
function importfile(excel, callback) {
    return BaseService.postMultipart("/program/import", excel, callback, true)
}
