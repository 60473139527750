import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import './Tag.scss';
import { tagService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form, Modal } from 'react-bootstrap';
import queryString from 'query-string';


class Tag extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state['headers'] =  ["id","Nombre"];
      this.state['fields'] =  ["id","name"];
      this.state['type'] =  ["text","text"];
      this.state['data'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 20;
      this.state['filtertitle'] = params.title?params.title:"";
      this.state['query'] = this.getFilter();
      this.state['tags'] = [];
      this.state['programs'] = [];
      this.state['link'] = "/tags";
      this.state['showconfirm'] = false;
      this.state['showconfirmExport'] = false;

      if (params.page) {
        this.state['page'] = params.page;
        this.callTag(params.page,this.state.filtertitle);
      } else {
        this.state['page'] = 0;
        this.callTag(0,this.state.filtertitle);
      }
      this.inputup = React.createRef();

  }


  callTag(offset,name){
      let callback = (datarows, error) => {

        if (datarows) {
           this.setState({data:datarows.data,total:datarows.total});
        }

      }
      tagService.search(offset,name, callback);
  }


   handleChange(event){
       const value = event.target.value

       if (event.target.name === "title") {
           this.setState({filtertitle:value});
       }
     }

     clickCell(id){
       window.location.href="/tag?id="+id;
     }


     clickDel(id){
       let callback = (datarows, error) => {
          window.location.reload();
       }
       tagService.remove(id, callback);
    }

    getFilter(){

       var query=""
       if(this.state.filtertitle !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "title=" + this.state.filtertitle ;
       }
       return query;
     }


  handleClickClear(event) {
    window.location.href="/tags";
  }
   handleClickSearch(event) {
     this.setState({query:this.getFilter()});
     window.location.href="/tags?page=0&"+this.getFilter();
   }

   handleUpload(){
    this.inputup.current.click()
 }

   handleSelectedExcel(event){
    const value = event.target.files[0];

    if (value) {
      let callback = (data, error) => {
        if (data) {
          this.setState({showconfirm:true});
        }
      }
      tagService.importfile(value, callback);
    }

  }

   handleExport(event){
    let callback = (datarows, error) => {
        this.setState({showconfirmExport:true});
    }
    tagService.exportTag(callback);

  }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de podcast" newbutton={true} link="/tagnew"  />
         <Accordion defaultActiveKey="0">
           <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="default" eventKey="0">
                Filtrar
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="filterpanel">
               <Row>
                  <Col sm="3">
                    <Form.Group >
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control type="text" placeholder="" name="title" value={this.state.filtertitle} onChange={event => this.handleChange(event)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                        <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                    </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
         </Accordion>
         <Row>
             <Col sm="12" hidden>

               <Form.Group>
                   <Form.File ref={this.inputup} label="Imagen"  onChange={event => this.handleSelectedExcel(event)}/>
               </Form.Group>
             </Col>
             <Col className="text-right importpanel">
                  <Button variant="info" className="" onClick={event => this.handleExport()} style={{marginRight:"20px"}}>Exportar Programas</Button>
                 <Button variant="danger" className="" onClick={event => this.handleUpload()}>Importar Progamas</Button>
             </Col>
         </Row>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} link={this.state.link} query={this.state.query} handlerdel={this.clickDel}  />

      </div>
      <Modal show={this.state.showconfirm} onHide={event => this.setState({showconfirm:false})}>
        <Modal.Header closeButton>
          <Modal.Title>Fichero importado</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>El documento se esta importando, se te notificará por Email cuando termine.</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={event => this.setState({showconfirm:false})}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={this.state.showconfirmExport} onHide={event => this.setState({showconfirmExport:false})}>
        <Modal.Header closeButton>
          <Modal.Title>Fichero exportando ...</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p> Se estan exportando los tags. Cuando termine se enviara un fichero Excel a tu correo.</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={event => this.setState({showconfirmExport:false})}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </>
  };
}

export default Tag;
