import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import './Story.scss';
import toast from 'toasted-notes'
import { storyService } from '../../services';
import { tagService } from '../../services';
import { podcastService } from '../../services';

import queryString from 'query-string';
class StoryEdit  extends Home{
    constructor(props) {
        super(props)
        let params = queryString.parse(this.props.location.search);
        this.state.story = {name:"", title:"", type:"tag", typeId:"",image:""};
        this.state.type_id = [];
        this.state.typeId = -1;
        this.state.programs = [];
        this.state.podcasts = [];
        this.state.tags = [];

        this.callTags();
        this.callPrograms();
        this.callPodcasts();

        this.state.buttonvisibles = true;
        if (params.id) {
            this.state.idstory = params.id;
            this.callSearch();
        } else{
            this.state.idstory = -1;
        }
        if (this.state.story.image) {
            this.state.imagePreviewUrl = this.state.story.image;
        }

    this.handlerUpload = this.handlerUpload.bind(this);
      this.handlerDelete = this.handlerDelete.bind(this);

    }

    callTags (){
        let callback = (datarows, error) => {
          if (datarows) {
             this.setState({tags:datarows});
          }
        }
        podcastService.tags(callback);
    }
    callPrograms (){
        let callback = (datarows, error) => {
          if (datarows) {
             this.setState({programs:datarows});
          }
        }
        tagService.programs(callback);
      }
    
    callPodcasts (){

    let callback = (datarows, error) => {
        if (datarows) {
            this.setState({podcasts:datarows});
        }
    }
    tagService.podcasts(callback);
    }
    callSearch (){
        let callback = (data, error) => {
            if (data) {
                this.setState({story:data, imagePreviewUrl: data.image});
            } else {
                const msg = "No se ha encontrado la story";
                toast.notify(msg, {position:'top', duration: 5000});
                this.setState({buttonvisibles:false});
            }
            if(this.state.story.type === 'tag') this.state.type_id = this.state.tags
            else if(this.state.story.type === 'program') this.state.type_id = this.state.programs
            else if(this.state.story.type === 'podcast') this.state.type_id = this.state.podcasts
            console.log("AQUI TYPE: " + this.state.story.typeId)
            console.log(this.state.type_id)
            this.setState({typeId:this.state.story.typeId});
        }
        storyService.one(this.state.idstory, callback);
    }
    
    
    handleClickClear(event) {
        window.location.href="/stories";
    }
    handleChange(event){
        const value = event.target.value
        var story = this.state.story;
        if (event.target.name === "name") {
            story.name = value;
        } else if (event.target.name === "title") {
            story.title = value;
        }
        else if (event.target.name === "type"){
            story.type = value;
            //REVISAR
            if(value === 'tag') this.state.type_id = this.state.tags
            else if(value === 'program') this.state.type_id = this.state.programs
            else this.state.type_id = this.state.podcasts
        } else if (event.target.name === "typeId"){
            console.log(value)
            story.typeId = value;
            this.setState({typeId:value});
        } else if (event.target.name === "url") {
            story.url = value;
        } else if (event.target.name === "image") {
            story.image = value;
            this.setState({ valid_url:this.validURLImage(value) });
            this.setState({ imagePreviewUrl: value });
        }
        this.setState({story:story});
        console.log(this.state.story)
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }

      validURLImage(str) {
        return str.match(/\.(jpeg|jpg|gif|png)$/) != null
      }

      handlerUpload(image){
          console.log("UPLOAD")
          console.log(this.state)
          console.log("IMAGEN: " + image)
        var story = this.state.story;
        story.image = image;
        this.setState({story:story});
      }

      handlerDelete(){
        var story = this.state.story;
        story.image = "";
        this.setState({story:story});
      }

    handleClickSave(event) {
        if (this.state.story.name) {
            if(this.state.idstory !== -1){
                //EDIT
                this.callEdit();
            } else {
                //CREATE
                this.callCreate();
            }
        } else if (!this.state.story.name || this.state.story.name === "" ) {
            const msg = "El nombre es obligatorio"
            toast.notify(msg, {position:'top', duration: 5000})
        }
    }
    callCreate (){
        console.log("XO")
        console.log(this.state.story)
        let callback = (datarows, error) => {
            if (datarows && datarows.data) {
                this.setState({story:datarows.data});
                this.setState({idstory:datarows.data.id});
                window.location.href="/story?id="+datarows.data.id;
            }
        }
        storyService.create(this.state.story, callback);
    }
    callEdit (){
        console.log("XO")
        console.log(this.state.story)
        let callback = (datarows, error) => {
            if (datarows && datarows.data) {
                const msg = "Se ha guardado correctamente";
                toast.notify(msg, {position:'top', duration: 5000});
            }
        }
        storyService.edit(this.state.idstory,this.state.story, callback);
    }
  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idstory !== -1) return "Editar story"; else return "Nueva story";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" placeholder="" name="name" value={this.state.story.name} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="4">
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Tipo</Form.Label>
                    <Form.Control as="select" name="type"
                    value={this.state.story.type}
                    onChange={event => this.handleChange(event)} required>
                        <option value="">Seleccione un tipo</option>
                        <option value="podcast">Podcast</option>
                        <option value="program">Programa</option>
                        <option value="tag">Tag</option>
                        <option value="url">URL externa</option>
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col sm="8">
                <Form.Group >
                  <Form.Label>Título</Form.Label>
                  <Form.Control type="text" placeholder="" name="title" value={this.state.story.title} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>
            { this.state.story.type == 'url' ? 
            <div>
              <Col sm="6">
                  <Form.Group >
                    <Form.Label>URL externa</Form.Label>
                    <Form.Control type="text" placeholder="" name="url" value={this.state.story.url} onChange={event => this.handleChange(event)}/>
                  </Form.Group>
              </Col>
            </div>
            : 
            <Col sm="6">
                 <Form.Group controlId="formPro">
                   <Form.Label>Identificador tipo: {this.state.story.type}</Form.Label>
                   <Form.Control as="select" name="typeId" onChange={event => this.handleChange(event)} value={this.state.typeId} >
                     <option value="">---</option>
                     {this.state.type_id.map((pro,idx) => (
                         <option value={pro.id}>{pro.name}</option>
                     ))}
                   </Form.Control>
                 </Form.Group>
             </Col>
            }
            
            <Col sm="8">
                <Form.Label>Imagen</Form.Label>
                <Form.Control name="image" value={this.state.story.image} onChange={event => this.handleChange(event)} />
                <div className='paneImage'>
                  <Image src={this.state.imagePreviewUrl} alt="Preview" fluid />
                </div>
            </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}
export default StoryEdit;
