import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import './Podcast.scss';
import ReactTags from 'react-tag-autocomplete';
import toast from 'toasted-notes'
import { podcastService, commentService, reviewService } from '../../services';
import queryString from 'query-string';
import moment from 'moment';
import TableTic from './../../components/page/table';


class PodcastEdit  extends Home{


  constructor(props) {
      super(props)

      let params = queryString.parse(this.props.location.search);
      this.state.podcast = {title:"",description:"",url:"",image:"",icon_map:"", latitude:"",longitude:"",visibleApp:false,featured:false,donwload:false,tags:[],location:"",tagsFeatured:[],featureGlobal:false,program:{id:-1},code:"",relatedContent:"",relatedVideo:"",subtitle:"", banner:"", descriptionPoi:"", route:[],creatorId:null,isPrivate:false,elementType:1, urlDimension:"", bannerPlay:"", bannerPlayUrl:""};
      this.state.buttonvisibles = true;
      this.state.programs = [];

      this.state['headers'] =  ["id","Comentario","Usuario","Fecha"];
      this.state['fields'] =  ["id","comment","userId","dateCreate"];
      this.state['type'] =  ["text","text","text","date"];
      this.state['comments'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 999999999;
      this.state['page'] = 0;
      this.state['link'] = "/podcasts";
      this.state['query'] = "";

      this.state['headersR'] =  ["id","Usuario","Review","Fecha"];
      this.state['fieldsR'] =  ["id","userId","review","dateCreate"];
      this.state['typeR'] =  ["text","text","text","date"];
      this.state['reviews'] = [];
      this.state['totalR'] = 0;
      this.state['pageR'] = 0;
      this.state['linkR'] = "/podcasts";
      this.state['queryR'] = "";

      if (params.id) {
        this.state.idpodcast = params.id;
        this.callSearch();
        this.callComments();
        this.callReviews();
      } else{
        this.state.idpodcast = -1;
      }

      if (params.page) {
        this.state['page'] = params.page;
      } else {
        this.state['page'] = 0;
      }

      this.handlerUpload = this.handlerUpload.bind(this);
      this.handlerDelete = this.handlerDelete.bind(this);
      this.state.valid_url = false;
      this.state.valid_url_icon = false;

      this.reactTags = React.createRef()
      this.reactTagsFeature = React.createRef()

      this.callTags();
      this.callPrograms();

  }


    callSearch (){

        let callback = (data, error) => {
          if (data) {

            if(data.isPoi){
                data.elementType = 3
            }
             if (data.program == null || data.program.id == null){
               data.program = {id:-1}
             }
             this.setState({podcast:data});
             if (data.image) {
                this.setState({valid_url:this.validURLImage(data.image)});
             }
             if(data.icon_map) {
                 this.setState({valid_url_icon:this.validURLImage(data.icon_map)});
             }
          } else {
            const msg = "No se ha encontrado el podcast";
            toast.notify(msg, {position:'top', duration: 5000});
            this.setState({buttonvisibles:false});
          }

        }
        podcastService.one(this.state.idpodcast, callback);
    }

    callComments (){

      let callback = (datarows, error) => {

        if (datarows) {
            this.setState({comments:datarows.data,total:datarows.total});
            console.log("COMMENTS")
          console.log(this.state.comments)
        }
    }
      commentService.all(this.state.idpodcast, callback);
  }
  callReviews (){

    let callback = (datarows, error) => {

      if (datarows) {
          this.setState({reviews:datarows.data,totalR:datarows.total});
          console.log("REVIWS")
          console.log(this.state.reviews)
      }
  }
    reviewService.all(this.state.idpodcast, callback);
}
  clickDel(id){
    let callback = (datarows, error) => {
       window.location.reload();
    }
    commentService.remove(id, callback);
  }
  clickDelR(id){
    let callback = (datarows, error) => {
       window.location.reload();
    }
    reviewService.remove(id, callback);
  }



  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  validURLImage(str) {
    return str.match(/\.(jpeg|jpg|gif|png)$/) != null
  }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({tags:datarows});
      }
    }
    podcastService.tags(callback);
  }

  clickCell(id){
    console.log(id);
  }
  clickCellR(id){
    console.log(id);
  }

  callPrograms (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({programs:datarows});
      }
    }
    podcastService.programs(callback);
  }


   handleClickClear(event) {
     window.location.href="/podcasts";
   }

   handlerUpload(image){
     var podcast = this.state.podcast;
     podcast.image = image;
     this.setState({podcast:podcast});
   }

   handlerDelete(){
     var podcast = this.state.podcast;
     podcast.image = "";
     this.setState({podcast:podcast});
   }

   setChecked(field,event,checked) {
    var podcast = this.state.podcast;
     if (field === "visibleApp") {
        podcast.visibleApp = checked;
     } else if (event.target.name === "donwload") {
        podcast.donwload = checked;
     } else if (field === "featureGlobal") {
        podcast.featureGlobal = checked;
     } else if (field === "featured") {
        podcast.featured = checked;
     } else if (field === "externalUrl") {
        podcast.externalUrl = checked;
     } else if (field === "isHome") {
        podcast.isHome = checked;
     } else if (field === "push") {
        podcast.push = checked;
     } else if (field === "isPrivate") {
      podcast.isPrivate = checked;
    }


     this.setState({podcast:podcast});

   }

  onDelete (i) {
    const tags = this.state.podcast.tags.slice(0)
    tags.splice(i, 1)
    var podcast = this.state.podcast;
    podcast.tags = tags;
    this.setState({podcast:podcast});
  }

  onAddition (tag) {
    const tags = [].concat(this.state.podcast.tags, tag)
    var podcast = this.state.podcast;
    podcast.tags = tags;
    this.setState({podcast:podcast});
  }

  onDeleteFeatured (i) {
    const tags = this.state.podcast.tagsFeatured.slice(0)
    tags.splice(i, 1)
    var podcast = this.state.podcast;
    podcast.tagsFeatured = tags;
    this.setState({podcast:podcast});
  }

  onAdditionFeatured (tag) {
    const tags = [].concat(this.state.podcast.tagsFeatured, tag)
    var podcast = this.state.podcast;
    podcast.tagsFeatured = tags;
    this.setState({podcast:podcast});
  }


  handleChange(event){
       const value = event.target.value
       var podcast = this.state.podcast;
       if (event.target.name === "title") {
         podcast.title = value;
       } else if (event.target.name === "url") {
         podcast.url = value;
       } else if (event.target.name === "idRadioViajera") {
         podcast.idRadioViajera = value;
       } else if (event.target.name === "description") {
         podcast.description = value;
       } else if (event.target.name === "latitude") {
         podcast.latitude = value;
       } else if (event.target.name === "longitude") {
         podcast.longitude = value;
       } else if (event.target.name === "location") {
         podcast.location = value;
       } else if (event.target.name === "url_shared") {
         podcast.url_shared = value;
       } else if (event.target.name === "country") {
         podcast.country = value;
       } else if (event.target.name === "street") {
         podcast.street = value;
       } else if (event.target.name === "state") {
         podcast.state = value;
       } else if (event.target.name === "locale") {
         podcast.locale = value;
       } else if (event.target.name === "image") {
         podcast.image = value;
         this.setState({valid_url:this.validURLImage(value)});
       } else if (event.target.name === "icon_map") {
         podcast.icon_map = value;
         this.setState({valid_url_icon:this.validURLImage(value)});
       } else if (event.target.name === "dateCreate") {
          podcast.dateCreate = moment(value).format('YYYY-MM-DD')+"T00:00:00Z";
       } else if (event.target.name === "program") {
          podcast.program = {id:value};
       } else if (event.target.name === "code") {
          podcast.code = value;
       } else if (event.target.name === "urlDimension") {
        podcast.urlDimension = value;
      } else if (event.target.name === "relatedContent") {
          podcast.relatedContent = value;
       } else if (event.target.name === "relatedVideo") {
          podcast.relatedVideo = value;
       } else if (event.target.name === "subtitle") {
          podcast.subtitle = value;
       } else if (event.target.name === "banner") {
          podcast.banner = value;
        } else if (event.target.name === "bannerPlay") {
          podcast.bannerPlay = value;
        } else if (event.target.name === "bannerPlayUrl") {
          podcast.bannerPlayUrl = value;
        } else if (event.target.name === "creatorId") {
            podcast.creatorId = {id:value};
        } else if (event.target.name === "elementType") {
            podcast.elementType = parseInt(value);
        }

       this.setState({podcast:podcast});
  }


     handleClickSave(event) {
       if (this.state.podcast.title && this.state.podcast.url && this.state.podcast.title !== "" && this.state.podcast.url !== "" && this.validURL(this.state.podcast.url )) {

         if(this.state.idpodcast !== -1){
           //EDIT
           this.callEdit();
         } else {
           //CREATE
           this.callCreate();
         }
       } else if (!this.state.podcast.title || this.state.podcast.title === "" ) {
           const msg = "El nombre es obligatorio"
           toast.notify(msg, {position:'top', duration: 5000})
       } else {
             const msg = "La url no tiene un formato correcto";
             toast.notify(msg, {position:'top', duration: 5000});
       }


     }

     callCreate (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
              window.location.href="/podcast?id="+datarows.data.id;
           }

         }
         podcastService.create(this.state.podcast, callback);
     }

     callEdit (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
               const msg = "Se ha guardado correctamente";
               toast.notify(msg, {position:'top', duration: 5000});
           }

         }
         podcastService.edit(this.state.idpodcast,this.state.podcast, callback);
     }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idpodcast !== -1) return "Editar podcast"; else return "Nuevo podcast";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>ID</Form.Label>
                  <Form.Control type="number" placeholder="" name="idRadioViajera" value={this.state.podcast.idRadioViajera} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>Titulo</Form.Label>
                  <Form.Control type="text" placeholder="" name="title" value={this.state.podcast.title} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
             <Col sm="6">
                 <Form.Group >
                   <Form.Label>Fecha</Form.Label>
                   <Form.Control type="date" placeholder="" name="dateCreate" value={moment(this.state.podcast.dateCreate).format('YYYY-MM-DD')} onChange={event => this.handleChange(event) } pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"  required/>
                 </Form.Group>
             </Col>
            <Col sm="6">
                 <Form.Group >
                   <Form.Label>Url</Form.Label>
                   <Form.Control type="text" placeholder="" name="url" value={this.state.podcast.url} onChange={event => this.handleChange(event)} required/>
                 </Form.Group>
             </Col>
             <Col sm="12">
                  <Form.Group >
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control as="textarea" rows="3" placeholder="" name="description" value={this.state.podcast.description} onChange={event => this.handleChange(event)} />
                  </Form.Group>
            </Col>
            <Col sm="6">
                 <Form.Group >
                   <Form.Label>Latitud</Form.Label>
                   <Form.Control placeholder="" name="latitude" value={this.state.podcast.latitude} onChange={event => this.handleChange(event)} />
                 </Form.Group>
             </Col>
             <Col sm="6">
                  <Form.Group >
                    <Form.Label>Longitud</Form.Label>
                    <Form.Control placeholder="" name="longitude" value={this.state.podcast.longitude} onChange={event => this.handleChange(event)} />
                  </Form.Group>
              </Col>
              <Col sm="12">
                   <Form.Group >
                     <Form.Label>País</Form.Label>
                     <Form.Control placeholder="" name="country" value={this.state.podcast.country} onChange={event => this.handleChange(event)} />
                   </Form.Group>
             </Col>
             <Col sm="12">
                  <Form.Group >
                    <Form.Label>Provincia/Estado</Form.Label>
                    <Form.Control placeholder="" name="state" value={this.state.podcast.state} onChange={event => this.handleChange(event)} />
                  </Form.Group>
            </Col>
              <Col sm="12">
                   <Form.Group >
                     <Form.Label>Localidad</Form.Label>
                     <Form.Control placeholder="" name="location" value={this.state.podcast.location} onChange={event => this.handleChange(event)} />
                   </Form.Group>
             </Col>
             <Col sm="12">
                  <Form.Group >
                    <Form.Label>Calle</Form.Label>
                    <Form.Control placeholder="" name="street" value={this.state.podcast.street} onChange={event => this.handleChange(event)} />
                  </Form.Group>
            </Col>
             <Col sm="3">
               <Form.Group controlId="visibleApp">
                 <Form.Check type="checkbox" label="Visible en APP" name="visibleApp" checked={this.state.podcast.visibleApp} onChange={(event) => this.setChecked("visibleApp",event,!this.state.podcast.visibleApp)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
               <Form.Group controlId="donwload">
                 <Form.Check type="checkbox" label="Descargable" name="donwload" checked={this.state.podcast.donwload} onChange={(event) => this.setChecked("donwload",event,!this.state.podcast.donwload)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
               <Form.Group controlId="featured">
                 <Form.Check type="checkbox" label="Destacada en tags propios" name="featured" checked={this.state.podcast.featured} onChange={(event) => this.setChecked("featured",event,!this.state.podcast.featured)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
               <Form.Group controlId="featureGlobal">
                 <Form.Check type="checkbox" label="Destacada GENERAL" name="featureGlobal" checked={this.state.podcast.featureGlobal} onChange={(event) => this.setChecked("featureGlobal",event,!this.state.podcast.featureGlobal)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
               <Form.Group controlId="push">
                 <Form.Check type="checkbox" label="Push" name="push" checked={this.state.podcast.push} onChange={(event) => this.setChecked("push",event,!this.state.podcast.push)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
               <Form.Group controlId="externalUrl">
                 <Form.Check type="checkbox" label="URL Externa" name="externalUrl" checked={this.state.podcast.externalUrl} onChange={(event) => this.setChecked("externalUrl",event,!this.state.podcast.externalUrl)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
               <Form.Group controlId="isHome">
                 <Form.Check type="checkbox" label="En la home" name="isHome" checked={this.state.podcast.isHome} onChange={(event) => this.setChecked("isHome",event,!this.state.podcast.isHome)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
                  <Form.Group >
                    <Form.Label>Idioma</Form.Label>
                    <Form.Control placeholder="" name="locale" value={this.state.podcast.locale} onChange={event => this.handleChange(event)} />
                  </Form.Group>
             </Col>
             <Col sm="6">
                 <Form.Group controlId="formPro">
                   <Form.Label>Programa</Form.Label>
                   <Form.Control as="select" name="program" onChange={event => this.handleChange(event)} value={this.state.podcast.program.id} >
                     <option value="">---</option>
                     {this.state.programs.map((pro,idx) => (
                         <option value={pro.id}>{pro.name}</option>
                     ))}
                   </Form.Control>
                 </Form.Group>
             </Col>
             <Col sm="6">
                  <Form.Group >
                    <Form.Label>Tags</Form.Label>
                    <ReactTags allowNew={true} className="tagsinput" ref={this.reactTags} tags={this.state.podcast.tags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Nuevas etiquetas"/>
                  </Form.Group>
            </Col>
            <Col sm="12">
                 <Form.Group >
                   <Form.Label>Tags donde destacar</Form.Label>
                   <ReactTags allowNew={true} className="tagsinput" ref={this.reactTagsFeature} tags={this.state.podcast.tagsFeatured} suggestions={this.state.tags} onDelete={this.onDeleteFeatured.bind(this)} onAddition={this.onAdditionFeatured.bind(this)} placeholderText="Nuevas etiquetas donde destacar"/>
                 </Form.Group>
           </Col>
           <Col sm="3">
                 <Form.Group controlId="formPro">
                   <Form.Label>Tipo de elemento</Form.Label>
                   <Form.Control as="select" name="elementType" onChange={event => this.handleChange(event)} value={String(this.state.podcast.elementType)} >
                     <option value="1">Podcast</option>
                     <option value="2">Elemento patrimonial</option>
                     <option value="3">Punto de interés (POI)</option>
                   </Form.Control>
                 </Form.Group>
             </Col>

             <Col sm="3">
               <Form.Group controlId="isPrivate">
                 <Form.Check type="checkbox" label="Elemento privado" name="isPrivate" checked={this.state.podcast.isPrivate} onChange={(event) => this.setChecked("isPrivate",event,!this.state.podcast.isPrivate)}/>
               </Form.Group>
             </Col>
             <Col sm="12">
                   <Form.Group >
                     <Form.Label>URL 3D</Form.Label>
                     <Form.Control placeholder="" name="urlDimension" value={this.state.podcast.urlDimension} onChange={event => this.handleChange(event)} />
                   </Form.Group>
             </Col>
           <Col sm="12">Creador del podcast</Col>

           <Col sm="9">
                <Form.Group >
                  <Form.Label>Imagen</Form.Label>
                  <Form.Control placeholder="" name="image" value={this.state.podcast.image} onChange={event => this.handleChange(event)} />
                </Form.Group>
          </Col>
           <Col sm="3">
                <Image src={this.state.podcast.image } rounded fluid className="imagetable" style={{ display: this.state.valid_url  ? "block" : "none" }} />
          </Col>
          <Col sm="9">
               <Form.Group >
                 <Form.Label>Icono mapa</Form.Label>
                 <Form.Control placeholder="" name="icon_map" value={this.state.podcast.icon_map} onChange={event => this.handleChange(event)} />
               </Form.Group>
         </Col>
          <Col sm="3">
               <Image src={this.state.podcast.icon_map } rounded fluid className="imagetable"  style={{ display: this.state.valid_url_icon  ? "block" : "none" }}/>
         </Col>
         <Col sm="12">
              <Form.Group >
                <Form.Label>URL a compartir</Form.Label>
                <Form.Control placeholder="" name="url_shared" value={this.state.podcast.url_shared} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>

        <Col sm="12">
              <Form.Group >
                <Form.Label>Código podcaster</Form.Label>
                <Form.Control placeholder="" name="code" value={this.state.podcast.code} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>
        <Col sm="12">
              <Form.Group >
                <Form.Label>Contenido relacionado</Form.Label>
                <Form.Control placeholder="" name="relatedContent" value={this.state.podcast.relatedContent} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>
        <Col sm="12">
              <Form.Group >
                <Form.Label>Video relacionado</Form.Label>
                <Form.Control placeholder="" name="relatedVideo" value={this.state.podcast.relatedVideo} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>
        <Col sm="12">
              <Form.Group >
                <Form.Label>Subtítulo</Form.Label>
                <Form.Control placeholder="" name="subtitle" value={this.state.podcast.subtitle} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>
        <Col sm="12">
              <Form.Group >
                <Form.Label>Banner</Form.Label>
                <Form.Control placeholder="" name="banner" value={this.state.podcast.banner} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>
        <Col sm="6">
              <Form.Group >
                <Form.Label>Banner en reproductor</Form.Label>
                <Form.Control placeholder="" name="bannerPlay" value={this.state.podcast.bannerPlay} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>
        <Col sm="6">
              <Form.Group >
                <Form.Label>URL para banner en reproductor</Form.Label>
                <Form.Control placeholder="" name="bannerPlayUrl" value={this.state.podcast.bannerPlayUrl} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>
         </Row>

         <Row>
         <Col sm="12">
          <h3>Reviews</h3>
          </Col>
         </Row>

         <TableTic  headers={this.state.headersR} fields={this.state.fieldsR} data={this.state.reviews} row={this.state.row} total={this.state.totalR} page={this.state.page} sizepage={this.state.sizepage} type={this.state.typeR} handler={this.clickCellR} link={this.state.linkR} query={this.state.queryR} handlerdel={this.clickDelR}  />

         <Row>
         <Col sm="12">
          <h3>Comentarios</h3>
          </Col>
         </Row>

         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.comments} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} link={this.state.link} query={this.state.query} handlerdel={this.clickDel}  />


         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default PodcastEdit;
