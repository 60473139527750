import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import './Popup.scss';
import toast from 'toasted-notes'
import { popupService } from '../../services';
import queryString from 'query-string';
import ReactTags from 'react-tag-autocomplete';


class PopupEdit  extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state.popup = {title:"",question:"", image:"",imageUrl:"",stopPlay:1, nextPopupId:null,podcastId:null,timestamp:0, elementType:1, answer1Text:"", answer2Text:"", answer3Text:"", answer4Text:"", answer1Correct:0, answer2Correct:0, answer3Correct:0, answer4Correct:0, answer1Error:"", answer2Error:"", answer3Error:"", answer4Error:""};
      this.state.buttonvisibles = true;
      this.state.podcasts = [];
      this.state.nextPopups = [];

      if (params.id) {
        this.state.idpopup = params.id;
        this.callSearch();
      } else{
        this.state.idpopup = -1;
      }

      this.handlerUpload = this.handlerUpload.bind(this);
      this.handlerDelete = this.handlerDelete.bind(this);

      this.reactTags = React.createRef()
      this.reactTagsFeature = React.createRef()

      this.callPodcasts();
      this.callPopups();
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
          if (data.podcastId == null){
            data.podcastId = -1
          }
          if (data.nextPopupId == null){
            data.nextPopupId = -1
          }
          this.setState({popup:data});
          if (data.image) {
             this.setState({valid_url:this.validURLImage(data.image)});
          }
        } else {
          const msg = "No se ha encontrado el popup";
          toast.notify(msg, {position:'top', duration: 5000});
          this.setState({buttonvisibles:false});
        }

      }
      popupService.one(this.state.idpopup, callback);
  }

  validURLImage(str) {
    return str.match(/\.(jpeg|jpg|gif|png)$/) != null
  }

  callPopups (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({nextPopups:datarows});
      }
    }
    popupService.all(callback);
  }

  callPodcasts (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({podcasts:datarows});
      }
    }
    popupService.podcasts(callback);
  }

 handleClickClear(event) {
   window.location.href="/popups";
 }

 handlerUpload(image){
  var popup = this.state.popup;
  popup.image = image;
  this.setState({popup:popup});
}

handlerDelete(){
  var popup = this.state.popup;
  popup.image = "";
  this.setState({popup:popup});
}

setChecked(field,event,checked) {
  var popup = this.state.popup;
   if (field === "stopPlay") {
    popup.stopPlay = checked;
   } else if (field === "answer1Correct") {
    popup.answer1Correct = checked;
   } else if (field === "answer2Correct") {
    popup.answer2Correct = checked;
   } else if (field === "answer3Correct") {
    popup.answer3Correct = checked;
   } else if (field === "answer4Correct") {
    popup.answer4Correct = checked;
   }

   this.setState({popup:popup});

 }

  handleChange(event){
       const value = event.target.value
       var popup = this.state.popup;
       if (event.target.name === "title") {
        popup.title = value;
       } else if (event.target.name === "question") {
        popup.question = value;
       } else if (event.target.name === "image") {
        popup.image = value;
         this.setState({valid_url:this.validURLImage(value)});
       } else if (event.target.name === "imageUrl") {
        popup.imageUrl = value;
       } else if (event.target.name === "podcastId") {
        popup.podcastId = value;
       } else if (event.target.name === "timestamp") {
        popup.timestamp = value;
       } else if (event.target.name === "nextPopupId") {
        popup.nextPopupId = value;
       } else if (event.target.name === "answer1Text") {
        popup.answer1Text = value;
       } else if (event.target.name === "answer2Text") {
        popup.answer2Text = value;
       } else if (event.target.name === "answer3Text") {
        popup.answer3Text = value;
       }  else if (event.target.name === "answer4Text") {
        popup.answer4Text = value;
       } else if (event.target.name === "answer1Error") {
        popup.answer1Error = value;
      } else if (event.target.name === "answer2Error") {
        popup.answer2Error = value;
      } else if (event.target.name === "answer3Error") {
        popup.answer3Error = value;
      } else if (event.target.name === "answer4Error") {
        popup.answer4Error = value;
      }

       this.setState({popup:popup});
  }


   handleClickSave(event) {
     if (this.state.popup.title) {
       if(this.state.idpopup !== -1){
         //EDIT
         this.callEdit();
       } else {
         //CREATE
         this.callCreate();
       }
     } else if (!this.state.popup.title || this.state.popup.title === "" ) {
         const msg = "El nombre es obligatorio"
         toast.notify(msg, {position:'top', duration: 5000})
     }


   }

     callCreate (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
              this.setState({station:datarows.data});
              this.setState({idstation:datarows.data.id});
              window.location.href="/popup?id="+datarows.data.id;
           }

         }
         popupService.create(this.state.popup, callback);
     }

     callEdit (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
               const msg = "Se ha guardado correctamente";
               toast.notify(msg, {position:'top', duration: 5000});
           }

         }
         popupService.edit(this.state.idpopup,this.state.popup, callback);
     }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idpopup !== -1) return "Editar popup"; else return "Nuevo popup";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>Título</Form.Label>
                  <Form.Control type="text" placeholder="" name="title" value={this.state.popup.title} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="3">
               <Form.Group controlId="stopPlay">
                 <Form.Check type="checkbox" label="Para la reproducción" name="stopPlay" checked={this.state.popup.stopPlay} onChange={(event) => this.setChecked("stopPlay",event,!this.state.popup.stopPlay)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
                 <Form.Group >
                   <Form.Label>Timestamp(ms) cuando para la reproducción</Form.Label>
                   <Form.Control type="number" placeholder="" name="timestamp" value={this.state.popup.timestamp} onChange={event => this.handleChange(event)}/>
                 </Form.Group>
             </Col>
            <Col sm="12">
                 <Form.Group >
                   <Form.Label>Pregunta</Form.Label>
                   <Form.Control as="textarea" rows="3" placeholder="" name="question" value={this.state.popup.question} onChange={event => this.handleChange(event)} />
                 </Form.Group>
           </Col>
           <Col sm="6">
                 <Form.Group controlId="formPro">
                   <Form.Label>Podcast sobre el que salta</Form.Label>
                   <Form.Control as="select" name="podcastId" onChange={event => this.handleChange(event)} value={this.state.popup.podcastId} >
                     <option value="">---</option>
                     {this.state.podcasts.map((pro,idx) => (
                         <option value={pro.id}>{pro.title}</option>
                     ))}
                   </Form.Control>
                 </Form.Group>
             </Col>
             <Col sm="6">
                 <Form.Group controlId="formPop">
                   <Form.Label>Siguiente Popup</Form.Label>
                   <Form.Control as="select" name="nextPopupId" onChange={event => this.handleChange(event)} value={this.state.popup.nextPopupId} >
                     <option value="">---</option>
                     {this.state.nextPopups.map((pro,idx) => (
                         <option value={pro.id}>{pro.title}</option>
                     ))}
                   </Form.Control>
                 </Form.Group>
             </Col>
             <Col sm="12">
                 <Form.Group >
                   <Form.Label>URL de la imagen</Form.Label>
                   <Form.Control as="textarea" rows="3" placeholder="" name="imageUrl" value={this.state.popup.imageUrl} onChange={event => this.handleChange(event)} />
                 </Form.Group>
           </Col>
            <Col sm="9">
                <Form.Group >
                  <Form.Label>Imagen</Form.Label>
                  <Form.Control placeholder="" name="image" value={this.state.popup.image} onChange={event => this.handleChange(event)} />
                </Form.Group>
          </Col>
           <Col sm="3">
                <Image src={this.state.popup.image } rounded fluid className="imagetable" style={{ display: this.state.valid_url  ? "block" : "none" }} />
          </Col>

          <Col sm="5">
                <Form.Group >
                  <Form.Label>Texto respuesta 1</Form.Label>
                  <Form.Control type="text" placeholder="" name="answer1Text" value={this.state.popup.answer1Text} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>
            <Col sm="3">
               <Form.Group controlId="answer1Correct">
                 <Form.Check type="checkbox" label="Es correcta" name="answer1Correct" checked={this.state.popup.answer1Correct} onChange={(event) => this.setChecked("answer1Correct",event,!this.state.popup.answer1Correct)}/>
               </Form.Group>
             </Col>
            <Col sm="4">
                <Form.Group >
                  <Form.Label>Texto error respuesta 1</Form.Label>
                  <Form.Control type="text" placeholder="" name="answer1Error" value={this.state.popup.answer1Error} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>

            <Col sm="5">
                <Form.Group >
                  <Form.Label>Texto respuesta 2</Form.Label>
                  <Form.Control type="text" placeholder="" name="answer2Text" value={this.state.popup.answer2Text} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>
            <Col sm="3">
               <Form.Group controlId="answer2Correct">
                 <Form.Check type="checkbox" label="Es correcta" name="answer2Correct" checked={this.state.popup.answer2Correct} onChange={(event) => this.setChecked("answer2Correct",event,!this.state.popup.answer2Correct)}/>
               </Form.Group>
             </Col>
            <Col sm="4">
                <Form.Group >
                  <Form.Label>Texto error respuesta 2</Form.Label>
                  <Form.Control type="text" placeholder="" name="answer2Error" value={this.state.popup.answer2Error} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>

            <Col sm="5">
                <Form.Group >
                  <Form.Label>Texto respuesta 3</Form.Label>
                  <Form.Control type="text" placeholder="" name="answer3Text" value={this.state.popup.answer3Text} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>
            <Col sm="3">
               <Form.Group controlId="answer3Correct">
                 <Form.Check type="checkbox" label="Es correcta" name="answer3Correct" checked={this.state.popup.answer3Correct} onChange={(event) => this.setChecked("answer3Correct",event,!this.state.popup.answer3Correct)}/>
               </Form.Group>
             </Col>
            <Col sm="4">
                <Form.Group >
                  <Form.Label>Texto error respuesta 3</Form.Label>
                  <Form.Control type="text" placeholder="" name="answer3Error" value={this.state.popup.answer3Error} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>

            <Col sm="5">
                <Form.Group >
                  <Form.Label>Texto respuesta 4</Form.Label>
                  <Form.Control type="text" placeholder="" name="answer4Text" value={this.state.popup.answer4Text} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>
            <Col sm="3">
               <Form.Group controlId="answer4Correct">
                 <Form.Check type="checkbox" label="Es correcta" name="answer4Correct" checked={this.state.popup.answer4Correct} onChange={(event) => this.setChecked("answer4Correct",event,!this.state.popup.answer4Correct)}/>
               </Form.Group>
             </Col>
            <Col sm="4">
                <Form.Group >
                  <Form.Label>Texto error respuesta 4</Form.Label>
                  <Form.Control type="text" placeholder="" name="answer4Error" value={this.state.popup.answer4Error} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default PopupEdit;
