import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import './Administradores.scss';
import { Button,  Row, Col, Form } from 'react-bootstrap';
import { userService } from '../../services';
import { rolesService } from '../../services';
import toast from 'toasted-notes'
import queryString from 'query-string';


class AdministradoresEdit  extends Home{
    constructor(props) {
        super(props)
        let params = queryString.parse(this.props.location.search);
        this.state.usuario = {name:"",lastname:"",email:"",phone:"",street:"",city:"",country:"",pass:"",repassword:"", rol:{code:"ADMIN"},isClient:false};
        this.state.buttonvisibles = true;
        this.state.roles = [];
        if (params.id) {
          this.state.iduser = params.id;
          this.callSearch();
        } else{
          this.state.iduser = -1;
        }
        this.callRoles();
        this.reactTags = React.createRef()
        this.HOST = process.env.REACT_APP_HOST;

    }
    callRoles (){

        let callback = (data, error) => {
          if (data) {
             this.setState({roles:data});
          }

        }
        rolesService.search(0,callback);
    }

    callSearch (){

        let callback = (data, error) => {
          if (data) {
             data.pass="";
             this.setState({usuario:data});
             if(data.suscribeTags &&  data.suscribeTags.length > 0){
               this.setState({suscribeTags:data.suscribeTags});
             }
          } else {
            this.setState({buttonvisibles:false});
            const msg = "No se ha encontrado el administrador"
            toast.notify(msg, {position:'top', duration: 5000})
          }

        }
        userService.one(this.state.iduser, callback);
    }

     handleClickClear(event) {
       window.location.href="/administradores";
     }

     handleChange(event){
          const value = event.target.value
          var usuario = this.state.usuario;
          if (event.target.name === "name") {
            usuario.name = value;
          } else if (event.target.name === "lastname") {
            usuario.lastname = value;
          } else if (event.target.name === "email") {
            usuario.email = value;
          } else if (event.target.name === "phone") {
            usuario.phone = value;
          } else if (event.target.name === "street") {
            usuario.street = value;
          } else if (event.target.name === "city") {
            usuario.city = value;
          }else if (event.target.name === "country") {
            usuario.country = value;
          } else if (event.target.name === "password") {
            usuario.pass = value;
          } else if (event.target.name === "repassword") {
            usuario.repassword = value;
          } else if (event.target.name === "rol") {
            usuario.rol = {code:value};
          }

          this.setState({usuario:usuario});
     }

     handleClickSave(event) {
       if (this.state.usuario.email &&  this.state.usuario.email !== "" && (this.state.iduser  !== -1 || (this.state.usuario.pass  && this.state.usuario.pass !== "" && this.state.usuario.repassword === this.state.usuario.pass ))) {
         if(this.state.iduser !== -1){
           //EDIT
           this.callEdit();
         } else {
           //CREATE
           this.callCreate();
         }
       } else if (!this.state.usuario.email || this.state.usuario.email === "" ) {
           const msg = "El nombre es obligatorio"
           toast.notify(msg, {position:'top', duration: 5000})
       } else {
             const msg = "Debe introducir la contraseña o estas no coinciden";
             toast.notify(msg, {position:'top', duration: 5000});
       }


     }

     callCreate (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
              this.setState({station:datarows.data});
              this.setState({idstation:datarows.data.id});
              window.location.href="/administrador?id="+datarows.data.id;
           }

         }
         userService.create(this.state.usuario, callback);
     }

     callEdit (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
               const msg = "Se ha guardado correctamente";
               toast.notify(msg, {position:'top', duration: 5000});
           }

         }
         userService.edit(this.state.iduser,this.state.usuario, callback);
     }


    render () {
      return <>
        <NavBar user={this.state.user} callback={this.changeMenu}/>
        <div className="main">
           <TitlePage title={(() => { if (this.state.iduser !== -1) return "Editar de administrador"; else return "Nuevo administrador";})() } newbutton={false} link=""/>
           <Row>
           <Col sm="12">
             <Row>
                <Col sm="6">
                    <Form.Group>
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control type="text" placeholder="" name="name" value={this.state.usuario.name}  onChange={event => this.handleChange(event)} />
                    </Form.Group>
                </Col>
                <Col sm="6">
                     <Form.Group>
                       <Form.Label>Apellido</Form.Label>
                       <Form.Control type="text" placeholder="" name="lastname" value={this.state.usuario.lastname} onChange={event => this.handleChange(event)} />
                     </Form.Group>
                 </Col>
                 <Col sm="6">
                      <Form.Group >
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" placeholder="" name="email" value={this.state.usuario.email} onChange={event => this.handleChange(event)} />
                      </Form.Group>
                  </Col>
                  <Col sm="6">
                       <Form.Group >
                         <Form.Label>Teléfono</Form.Label>
                         <Form.Control type="text" placeholder="" name="phone" value={this.state.usuario.phone} onChange={event => this.handleChange(event)} />
                       </Form.Group>
                   </Col>
                   <Col sm="4">
                        <Form.Group>
                          <Form.Label>Calle</Form.Label>
                          <Form.Control type="text" placeholder="" name="street" value={this.state.usuario.street} onChange={event => this.handleChange(event)} />
                        </Form.Group>
                    </Col>
                    <Col sm="4">
                         <Form.Group>
                           <Form.Label>Ciudad</Form.Label>
                           <Form.Control type="text" placeholder="" name="city" value={this.state.usuario.city} onChange={event => this.handleChange(event)} />
                         </Form.Group>
                     </Col>
                     <Col sm="4">
                          <Form.Group>
                            <Form.Label>País</Form.Label>
                            <Form.Control type="text" placeholder="" name="country" value={this.state.usuario.country} onChange={event => this.handleChange(event)} />
                          </Form.Group>
                    </Col>
                    <Col sm="6">
                         <Form.Group >
                           <Form.Label>Contraseña</Form.Label>
                           <Form.Control type="password" placeholder="" name="password" value={this.state.usuario.pass} onChange={event => this.handleChange(event)} />
                         </Form.Group>
                   </Col>
                   <Col sm="6">
                        <Form.Group >
                          <Form.Label>Repetir Contraseña</Form.Label>
                          <Form.Control type="password" placeholder="" name="repassword" value={this.state.usuario.repassword} onChange={event => this.handleChange(event)} />
                        </Form.Group>
                  </Col>
                  <Col sm="12">
                      <Form.Group controlId="formTags">
                        <Form.Label>Rol</Form.Label>
                        <Form.Control as="select" name="rol" onChange={event => this.handleChange(event)}  ref="colTags" value={this.state.usuario.rol.code} >
                          {this.state.roles.map((tag,idx) => (
                              <option value={tag.code}>{tag.name}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                  </Col>

                  </Row>
                </Col>
           </Row>

           <Row>
               <Col className="text-right">
                   <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Atras</Button>
                   {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
               </Col>
           </Row>
      </div></>
    };
}

export default AdministradoresEdit;
