import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import './Confighome.scss';
import { configurationService,podcastService, stationService } from '../../services';
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';
import ImageUpload from './../../components/image';
import toast from 'toasted-notes'


class Confighome extends Home{


  constructor(props) {
      super(props)
      this.state.configuration = {station:{id:""},podcast1:{id:""}};
      this.state.showconfirm = false;
      this.state.stations = [];
      this.state.podcast = [];
      this.state.tags = [];
      this.state.locales = [];
      this.state.locale = 'es';
      this.callSearch('es');

      this.reactTags = React.createRef()
      this.reactPrograms = React.createRef()
      this.reactTagsStation = React.createRef()
      this.reactTagsExclusive = React.createRef()
      this.reactTagsTrend = React.createRef()

      this.callLanguage();
      this.callStations();
      this.callPodcast();
      this.callTags();
      this.callPrograms();

  }

  callLanguage(){
    let callback = (data, error) => {
      if (data) {
         this.setState({locales:data});
      }

    }
    configurationService.language(callback);
  }

  callPodcast(){
    let callback = (data, error) => {
      if (data) {
        let podcasts = []
        if(data.length > 0)
        {
            podcasts = data.filter(x => x.isPoi === false);
        }
        this.setState({podcast:podcasts});
        
      }

    }
    podcastService.all(callback);
  }

  callStations (){

      let callback = (data, error) => {
        if (data) {
           this.setState({stations:data});
        }

      }
      stationService.all(callback);
  }



  callTags (){

      let callback = (datarows, error) => {
        if (datarows) {
          console.log(datarows);
           this.setState({tags:datarows});
        }
      }
      podcastService.tags(callback);
  }


  callPrograms (){

    let callback = (datarows, error) => {
      if (datarows) {
        console.log(datarows);
         this.setState({programs:datarows});
      }
    }
    podcastService.programs(callback);
  }

  callSearch (locale){

      let callback = (data, error) => {
        if (data) {
           this.setState({configuration:data});
        } else {
          const msg = "No se ha encontrado la configuración por defecto";
          toast.notify(msg, {position:'top', duration: 5000});
          this.setState({buttonvisibles:false});
        }

      }
      configurationService.home(locale, callback);
  }

   handleChangeLocale(event) {
     const locale = event.target.value
     this.setState({locale:locale});
     this.callSearch(locale);

   }

   handleChange(event){
      var configuration = this.state.configuration;
      if (event.target.name === "visibleApp") {
         const value = event.target.value
         configuration.station = {id:value};
         this.setState({configuration:configuration});
       } else if (event.target.name === "podcast1") {
        const value = event.target.value
        configuration.podcast1 = {id:value};
        configuration.urlSLide11 = null;
        configuration.tag11 = null;
        this.setState({configuration:configuration});
      } else if (event.target.name === "podcast2") {
         const value = event.target.value
         configuration.podcast2 = {id:value};
         configuration.urlSLide12 = null;
         configuration.tag12 = null;
         this.setState({configuration:configuration});
       } else if (event.target.name === "podcast3") {
          const value = event.target.value
          configuration.podcast3 = {id:value};
          configuration.urlSLide13 = null;
          configuration.tag13 = null;
          this.setState({configuration:configuration});
       } else if (event.target.name === "podcast4") {
         const value = event.target.value
         configuration.podcast4 = {id:value};
         configuration.urlSLide14 = null;
         configuration.tag14 = null;
         this.setState({configuration:configuration});
       } else if (event.target.name === "podcast5") {
          const value = event.target.value
          configuration.podcast5 = {id:value};
          configuration.urlSLide15 = null;
          configuration.tag15 = null;
          this.setState({configuration:configuration});
      } else if (event.target.name === "podcast6") {
         const value = event.target.value
         configuration.podcast6 = {id:value};
         configuration.urlSLide16 = null;
         configuration.tag16 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "podcast7") {
         const value = event.target.value
         configuration.podcast7 = {id:value};
         configuration.urlSLide17 = null;
         configuration.tag17 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "podcast8") {
         const value = event.target.value
         configuration.podcast8 = {id:value};
         configuration.urlSLide18 = null;
         configuration.tag18 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "urlSLide11") {
         const value = event.target.value
         configuration.urlSLide11 = value;
         configuration.podcast1 = null;
         configuration.tag11 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "urlSLide12") {
         const value = event.target.value
         configuration.urlSLide12 = value;
         configuration.podcast2 = null;
         configuration.tag12 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "urlSLide13") {
         const value = event.target.value
         configuration.urlSLide13 = value;
         configuration.podcast3 = null;
         configuration.tag13 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "urlSLide14") {
         const value = event.target.value
         configuration.urlSLide14 = value;
         configuration.podcast4 = null;
         configuration.tag14 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "urlSLide15") {
         const value = event.target.value
         configuration.urlSLide15 = value;
         configuration.podcast5 = null;
         configuration.tag15 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "urlSLide16") {
         const value = event.target.value
         configuration.urlSLide16 = value;
         configuration.podcast6 = null;
         configuration.tag16 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "urlSLide17") {
         const value = event.target.value
         configuration.urlSLide17 = value;
         configuration.podcast7 = null;
         configuration.tag17 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "urlSLide18") {
         const value = event.target.value
         configuration.urlSLide18 = value;
         configuration.podcast8 = null;
         configuration.tag18 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "podcast21") {
       const value = event.target.value
       configuration.podcast21 = {id:value};
       configuration.urlSLide21 = null;
       configuration.tag21 = null;
       this.setState({configuration:configuration});
     } else if (event.target.name === "podcast22") {
        const value = event.target.value
        configuration.podcast22 = {id:value};
        configuration.urlSLide22 = null;
        configuration.tag22 = null;
        this.setState({configuration:configuration});
      } else if (event.target.name === "podcast23") {
         const value = event.target.value
         configuration.podcast23 = {id:value};
         configuration.urlSLide23 = null;
         configuration.tag23 = null;
         this.setState({configuration:configuration});
      } else if (event.target.name === "podcast24") {
        const value = event.target.value
        configuration.podcast24 = {id:value};
        configuration.urlSLide24 = null;
        configuration.tag24 = null;
        this.setState({configuration:configuration});
      } else if (event.target.name === "podcast25") {
         const value = event.target.value
         configuration.podcast25 = {id:value};
         configuration.urlSLide25 = null;
         configuration.tag25 = null;
         this.setState({configuration:configuration});
     } else if (event.target.name === "podcast26") {
        const value = event.target.value
        configuration.podcast26 = {id:value};
        configuration.urlSLide26 = null;
        configuration.tag26 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "podcast27") {
        const value = event.target.value
        configuration.podcast27 = {id:value};
        configuration.urlSLide27 = null;
        configuration.tag27 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "podcast28") {
        const value = event.target.value
        configuration.podcast28 = {id:value};
        configuration.urlSLide28 = null;
        configuration.tag28 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "urlSLide21") {
        const value = event.target.value
        configuration.urlSLide21 = value;
        configuration.tag21 = null;
        configuration.podcast21 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "urlSLide22") {
        const value = event.target.value
        configuration.urlSLide22 = value;
        configuration.tag22 = null;
        configuration.podcast22 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "urlSLide23") {
        const value = event.target.value
        configuration.urlSLide23 = value;
        configuration.tag23 = null;
        configuration.podcast23 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "urlSLide24") {
        const value = event.target.value
        configuration.urlSLide24 = value;
        configuration.tag24 = null;
        configuration.podcast24 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "urlSLide25") {
        const value = event.target.value
        configuration.urlSLide25 = value;
        configuration.tag25 = null;
        configuration.podcast25 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "urlSLide26") {
        const value = event.target.value
        configuration.urlSLide26 = value;
        configuration.tag26 = null;
        configuration.podcast26 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "urlSLide27") {
        const value = event.target.value
        configuration.urlSLide27 = value;
        configuration.tag27 = null;
        configuration.podcast27 = null;
        this.setState({configuration:configuration});
     } else if (event.target.name === "urlSLide28") {
        const value = event.target.value
        configuration.urlSLide28 = value;
        configuration.tag28 = null;
        configuration.podcast28 = null;
        this.setState({configuration:configuration});
     }else if (event.target.name === "tag11") {
       const value = event.target.value
       configuration.tag11 = {id:value};
       configuration.urlSLide11 = "";
       configuration.podcast1 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag12") {
       const value = event.target.value
       configuration.tag12 = {id:value};
       configuration.urlSLide12 = "";
       configuration.podcast2 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag13") {
       const value = event.target.value
       configuration.tag13 = {id:value};
       configuration.urlSLide13 = "";
       configuration.podcast3 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag14") {
       const value = event.target.value
       configuration.tag14 = {id:value};
       configuration.urlSLide14 = "";
       configuration.podcast4 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag15") {
       const value = event.target.value
       configuration.tag15 = {id:value};
       configuration.urlSLide15 = "";
       configuration.podcast5 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag16") {
       const value = event.target.value
       configuration.tag16 = {id:value};
       configuration.urlSLide16 = "";
       configuration.podcast6 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag17") {
       const value = event.target.value
       configuration.tag17 = {id:value};
       configuration.urlSLide17 = "";
       configuration.podcast7 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag18") {
       const value = event.target.value
       configuration.tag18 = {id:value};
       configuration.urlSLide18 = "";
       configuration.podcast8 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag21") {
       const value = event.target.value
       configuration.tag21 = {id:value};
       configuration.urlSLide21 = "";
       configuration.podcast21 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag22") {
       const value = event.target.value
       configuration.tag22 = {id:value};
       configuration.urlSLide22 = "";
       configuration.podcast22 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag23") {
       const value = event.target.value
       configuration.tag23 = {id:value};
       configuration.urlSLide23 = "";
       configuration.podcast23 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag24") {
       const value = event.target.value
       configuration.tag24 = {id:value};
       configuration.urlSLide24 = "";
       configuration.podcast24 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag25") {
       const value = event.target.value
       configuration.tag25 = {id:value};
       configuration.urlSLide25 = "";
       configuration.podcast25 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag26") {
       const value = event.target.value
       configuration.tag26 = {id:value};
       configuration.urlSLide26 = "";
       configuration.podcast26 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag27") {
       const value = event.target.value
       configuration.tag27 = {id:value};
       configuration.urlSLide27 = "";
       configuration.podcast27 = null;
       this.setState({configuration:configuration});
     }else if (event.target.name === "tag28") {
       const value = event.target.value
       configuration.tag28 = {id:value};
       configuration.urlSLide28 = "";
       configuration.podcast28 = null;
       this.setState({configuration:configuration});
     }
   }

    handleClickSave(event) {
      this.callSend();
    }

    onDelete (i) {
      const tags = this.state.configuration.tags.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.tags = tags;
      this.setState({configuration:configuration});
    }

    onAddition (tag) {
      const tags = [].concat(this.state.configuration.tags, tag)
      var configuration = this.state.configuration;
      configuration.tags = tags;
      this.setState({configuration:configuration});
    }

    onDeleteProgram(i) {
      const tags = this.state.configuration.programs.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.programs = tags;
      this.setState({configuration:configuration});
    }

    onAdditionProgram (tag) {
      const tags = [].concat(this.state.configuration.programs, tag)
      var configuration = this.state.configuration;
      configuration.programs = tags;
      this.setState({configuration:configuration});
    }

    onDeleteExclusive(i) {
      const tags = this.state.configuration.exclusives.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.exclusives = tags;
      this.setState({configuration:configuration});
    }

    onAdditionExclusive (tag) {
      const tags = [].concat(this.state.configuration.exclusives, tag)
      var configuration = this.state.configuration;
      configuration.exclusives = tags;
      this.setState({configuration:configuration});
    }

    onDeleteTrend(i) {
      const tags = this.state.configuration.trends.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.trends = tags;
      this.setState({configuration:configuration});
    }

    onAdditionTrend (tag) {
      const tags = [].concat(this.state.configuration.trends, tag)
      var configuration = this.state.configuration;
      configuration.trends = tags;
      this.setState({configuration:configuration});
    }

    onDeleteStation(i) {
      const tags = this.state.configuration.stations.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.stations = tags;
      this.setState({configuration:configuration});
    }

    onAdditionStation (tag) {
      const tags = [].concat(this.state.configuration.stations, tag)
      var configuration = this.state.configuration;
      configuration.stations = tags;
      this.setState({configuration:configuration});
    }

  callSend (){

      let callback = (data, error) => {
        if (data) {
           this.setState({showconfirm:true});
        }
      }
      configurationService.edithome(this.state.configuration.id,this.state.configuration, callback);
  }

  handlerUpload(image){
    console.log('Upload 1 ' + image);
    var configuration = this.state.configuration;
    configuration.image1 = image;
    this.setState({configuration:configuration});
  }

  handlerDelete(){
    console.log('DEL Upload 1 ');
    var configuration = this.state.configuration;
    configuration.image1 = "";
    this.setState({configuration:configuration});
  }

  handlerUpload2(image){
    console.log('Upload 2 ' + image);
    var configuration = this.state.configuration;
    configuration.image2 = image;
    this.setState({configuration:configuration});
  }

  handlerDelete2(){
    console.log('DEL Upload 2 ');
    var configuration = this.state.configuration;
    configuration.image2 = "";
    this.setState({configuration:configuration});
  }

    handlerUpload3(image){
      console.log('Upload 3 ' + image);
      var configuration = this.state.configuration;
      configuration.image3 = image;
      this.setState({configuration:configuration});
    }

    handlerDelete3(){
      console.log('DEL Upload 3' );
      var configuration = this.state.configuration;
      configuration.image3 = "";
      this.setState({configuration:configuration});
    }

      handlerUpload4(image){
        console.log('Upload 4 ' + image);
        var configuration = this.state.configuration;
        configuration.image4 = image;
        this.setState({configuration:configuration});
      }

      handlerDelete4(){
        console.log('DEL Upload 4' );
        var configuration = this.state.configuration;
        configuration.image4 = "";
        this.setState({configuration:configuration});
      }


      handlerUpload5(image){
        console.log('Upload 5 ' + image);
        var configuration = this.state.configuration;
        configuration.image5 = image;
        this.setState({configuration:configuration});
      }

      handlerDelete5(){
        console.log('DEL Upload 5' );
        var configuration = this.state.configuration;
        configuration.image5 = "";
        this.setState({configuration:configuration});
      }


      handlerUpload6(image){
        console.log('Upload 6 ' + image);
        var configuration = this.state.configuration;
        configuration.image6 = image;
        this.setState({configuration:configuration});
      }

      handlerDelete6(){
        console.log('DEL Upload 6' );
        var configuration = this.state.configuration;
        configuration.image6 = "";
        this.setState({configuration:configuration});
      }


      handlerUpload7(image){
        console.log('Upload 7 ' + image);
        var configuration = this.state.configuration;
        configuration.image7 = image;
        this.setState({configuration:configuration});
      }

      handlerDelete7(){
        console.log('DEL Upload 7' );
        var configuration = this.state.configuration;
        configuration.image7 = "";
        this.setState({configuration:configuration});
      }


      handlerUpload8(image){
        console.log('Upload 8 ' + image);
        var configuration = this.state.configuration;
        configuration.image8 = image;
        this.setState({configuration:configuration});
      }

      handlerDelete8(){
        console.log('DEL Upload 8' );
        var configuration = this.state.configuration;
        configuration.image8 = "";
        this.setState({configuration:configuration});
      }



        handlerUpload21(image){
          console.log('Upload 21 ' + image);
          var configuration = this.state.configuration;
          configuration.image21 = image;
          this.setState({configuration:configuration});
        }

        handlerDelete21(){
          console.log('DEL Upload 1 ');
          var configuration = this.state.configuration;
          configuration.image21 = "";
          this.setState({configuration:configuration});
        }

        handlerUpload22(image){
          console.log('Upload 22 ' + image);
          var configuration = this.state.configuration;
          configuration.image22 = image;
          this.setState({configuration:configuration});
        }

        handlerDelete22(){
          console.log('DEL Upload 22 ');
          var configuration = this.state.configuration;
          configuration.image22 = "";
          this.setState({configuration:configuration});
        }

          handlerUpload23(image){
            console.log('Upload 23 ' + image);
            var configuration = this.state.configuration;
            configuration.image23 = image;
            this.setState({configuration:configuration});
          }

          handlerDelete23(){
            console.log('DEL Upload 23' );
            var configuration = this.state.configuration;
            configuration.image23 = "";
            this.setState({configuration:configuration});
          }

            handlerUpload24(image){
              console.log('Upload 24 ' + image);
              var configuration = this.state.configuration;
              configuration.image24 = image;
              this.setState({configuration:configuration});
            }

            handlerDelete24(){
              console.log('DEL Upload 24' );
              var configuration = this.state.configuration;
              configuration.image24 = "";
              this.setState({configuration:configuration});
            }


            handlerUpload25(image){
              console.log('Upload 25 ' + image);
              var configuration = this.state.configuration;
              configuration.image25 = image;
              this.setState({configuration:configuration});
            }

            handlerDelete25(){
              console.log('DEL Upload 25' );
              var configuration = this.state.configuration;
              configuration.image25 = "";
              this.setState({configuration:configuration});
            }


            handlerUpload26(image){
              console.log('Upload 26 ' + image);
              var configuration = this.state.configuration;
              configuration.image26 = image;
              this.setState({configuration:configuration});
            }

            handlerDelete26(){
              console.log('DEL Upload 26' );
              var configuration = this.state.configuration;
              configuration.image26 = "";
              this.setState({configuration:configuration});
            }


            handlerUpload27(image){
              console.log('Upload 27 ' + image);
              var configuration = this.state.configuration;
              configuration.image27 = image;
              this.setState({configuration:configuration});
            }

            handlerDelete27(){
              console.log('DEL Upload 27' );
              var configuration = this.state.configuration;
              configuration.image27 = "";
              this.setState({configuration:configuration});
            }


            handlerUpload28(image){
              console.log('Upload 28 ' + image);
              var configuration = this.state.configuration;
              configuration.image28 = image;
              this.setState({configuration:configuration});
            }

            handlerDelete28(){
              console.log('DEL Upload 28' );
              var configuration = this.state.configuration;
              configuration.image28 = "";
              this.setState({configuration:configuration});
            }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Definición de la home" newbutton={false} link=""  />
          <Row>
            <Col sm="12">
                <h5>Selecciona el idioma a modificar</h5>
            </Col>
            <Col sm="12">
                <Form.Group controlId="formTags">
                  <Form.Label></Form.Label>
                  <Form.Control as="select" name="podcast1" onChange={event => this.handleChangeLocale(event)} value={this.state.locale} >
                    {this.state.locales.map((locale,idx) => (
                        <option value={locale.locale}>{locale.locale}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
            </Col>
          </Row>
         <Row>

              <Col sm="12">
                  <h4>Primer Slider</h4>
              </Col>
              <div sm="12" className="panelslider">
                <Row>
                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                           <ImageUpload image={this.state.configuration.image1}  upload={this.handlerUpload.bind(this)} deleted={this.handlerDelete.bind(this)} columns={4} />
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast1" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast1 != null && this.state.configuration.podcast1.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control type="text" placeholder="" name="urlSLide11" value={this.state.configuration.urlSLide11} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag11" onChange={event => this.handleChange(event)} value={this.state.configuration.tag11 != null && this.state.configuration.tag11.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                           <ImageUpload image={this.state.configuration.image2}  upload={this.handlerUpload2.bind(this)} deleted={this.handlerDelete2.bind(this)} columns={4} />
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast2" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast2 != null && this.state.configuration.podcast2.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide12" value={this.state.configuration.urlSLide12} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag12" onChange={event => this.handleChange(event)} value={this.state.configuration.tag12 != null && this.state.configuration.tag12.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                           <ImageUpload image={this.state.configuration.image3}  upload={this.handlerUpload3.bind(this)} deleted={this.handlerDelete3.bind(this)} columns={4} />
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast3" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast3 != null && this.state.configuration.podcast3.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide13" value={this.state.configuration.urlSLide13} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag13" onChange={event => this.handleChange(event)} value={this.state.configuration.tag13 != null && this.state.configuration.tag13.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                           <ImageUpload image={this.state.configuration.image4}  upload={this.handlerUpload4.bind(this)} deleted={this.handlerDelete4.bind(this)} columns={4} />
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast4" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast4 != null && this.state.configuration.podcast4.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide14" value={this.state.configuration.urlSLide14} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag14" onChange={event => this.handleChange(event)} value={this.state.configuration.tag14 != null && this.state.configuration.tag14.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                            <ImageUpload image={this.state.configuration.image5}  upload={this.handlerUpload5.bind(this)} deleted={this.handlerDelete5.bind(this)} columns={4} />
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast5" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast5 != null && this.state.configuration.podcast5.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide15" value={this.state.configuration.urlSLide15} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag15" onChange={event => this.handleChange(event)} value={this.state.configuration.tag15 != null && this.state.configuration.tag15.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>
                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                            <ImageUpload image={this.state.configuration.image6}  upload={this.handlerUpload6.bind(this)} deleted={this.handlerDelete6.bind(this)} columns={4} />
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast6" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast6 != null && this.state.configuration.podcast6.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide16" value={this.state.configuration.urlSLide16} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag16" onChange={event => this.handleChange(event)} value={this.state.configuration.tag16 != null && this.state.configuration.tag16.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                            <ImageUpload image={this.state.configuration.image7}  upload={this.handlerUpload7.bind(this)} deleted={this.handlerDelete7.bind(this)} columns={4} />
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast7" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast7 != null && this.state.configuration.podcast7.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide17" value={this.state.configuration.urlSLide17} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag17" onChange={event => this.handleChange(event)} value={this.state.configuration.tag17 != null && this.state.configuration.tag17.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                    <Col sm="3">
                      <Row>
                         <Col sm="12">
                            <ImageUpload image={this.state.configuration.image8}  upload={this.handlerUpload8.bind(this)} deleted={this.handlerDelete8.bind(this)} columns={4} />
                         </Col>
                         <Col sm="12">
                             <Form.Group controlId="formTags">
                               <Form.Label>Podcast</Form.Label>
                               <Form.Control as="select" name="podcast8" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast8 != null && this.state.configuration.podcast8.id} >
                                 <option value="">---</option>
                                 {this.state.podcast.map((podcast,idx) => (
                                     <option value={podcast.id}>{podcast.title}</option>
                                 ))}
                               </Form.Control>
                             </Form.Group>
                         </Col>
                         <Col sm="12">
                            - o -
                         </Col>
                         <Col sm="12">
                              <Form.Group >
                                <Form.Label>URL</Form.Label>
                                <Form.Control placeholder="" name="urlSLide18" value={this.state.configuration.urlSLide18} onChange={event => this.handleChange(event)} />
                              </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Tag</Form.Label>
                              <Form.Control as="select" name="tag18" onChange={event => this.handleChange(event)} value={this.state.configuration.tag18 != null && this.state.configuration.tag18.id} >
                                <option value="">---</option>
                                {this.state.tags.map((tag,idx) => (
                                    <option value={tag.id}>{tag.name}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>

                    </Col>
                </Row>
              </div>

              <Col sm="12">
                  <h4>Segundo Slider</h4>
              </Col>
              <div sm="12" className="panelslider">
                <Row>
                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                           <ImageUpload image={this.state.configuration.image21}  upload={this.handlerUpload21.bind(this)} deleted={this.handlerDelete21.bind(this)} columns={4} />
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast21" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast21 != null && this.state.configuration.podcast21.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide21" value={this.state.configuration.urlSLide21} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag21" onChange={event => this.handleChange(event)} value={this.state.configuration.tag21 != null && this.state.configuration.tag21.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                           <ImageUpload image={this.state.configuration.image22}  upload={this.handlerUpload22.bind(this)} deleted={this.handlerDelete22.bind(this)} columns={4} />
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast22" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast22 != null && this.state.configuration.podcast22.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide22" value={this.state.configuration.urlSLide22} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag22" onChange={event => this.handleChange(event)} value={this.state.configuration.tag22 != null && this.state.configuration.tag22.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                           <ImageUpload image={this.state.configuration.image23}  upload={this.handlerUpload23.bind(this)} deleted={this.handlerDelete23.bind(this)} columns={4} />
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast23" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast23 != null && this.state.configuration.podcast23.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide23" value={this.state.configuration.urlSLide23} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag23" onChange={event => this.handleChange(event)} value={this.state.configuration.tag23 != null && this.state.configuration.tag23.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                           <ImageUpload image={this.state.configuration.image24}  upload={this.handlerUpload24.bind(this)} deleted={this.handlerDelete24.bind(this)} columns={4} />
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast24" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast24 != null && this.state.configuration.podcast24.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide24" value={this.state.configuration.urlSLide14} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag24" onChange={event => this.handleChange(event)} value={this.state.configuration.tag24 != null && this.state.configuration.tag24.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                            <ImageUpload image={this.state.configuration.image25}  upload={this.handlerUpload25.bind(this)} deleted={this.handlerDelete25.bind(this)} columns={4} />
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast25" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast25 != null && this.state.configuration.podcast25.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide25" value={this.state.configuration.urlSLide25} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag25" onChange={event => this.handleChange(event)} value={this.state.configuration.tag25 != null && this.state.configuration.tag25.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>
                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                            <ImageUpload image={this.state.configuration.image26}  upload={this.handlerUpload26.bind(this)} deleted={this.handlerDelete26.bind(this)} columns={4} />
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast26" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast26 != null && this.state.configuration.podcast26.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide26" value={this.state.configuration.urlSLide26} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag26" onChange={event => this.handleChange(event)} value={this.state.configuration.tag26 != null && this.state.configuration.tag26.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                            <ImageUpload image={this.state.configuration.image27}  upload={this.handlerUpload27.bind(this)} deleted={this.handlerDelete27.bind(this)} columns={4} />
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast27" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast27 != null && this.state.configuration.podcast27.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide27" value={this.state.configuration.urlSLide27} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag27" onChange={event => this.handleChange(event)} value={this.state.configuration.tag27 != null && this.state.configuration.tag27.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                    <Col sm="3">
                      <Row>
                         <Col sm="12">
                            <ImageUpload image={this.state.configuration.image28}  upload={this.handlerUpload28.bind(this)} deleted={this.handlerDelete28.bind(this)} columns={4} />
                         </Col>
                         <Col sm="12">
                             <Form.Group controlId="formTags">
                               <Form.Label>Podcast</Form.Label>
                               <Form.Control as="select" name="podcast28" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast28 != null && this.state.configuration.podcast28.id} >
                                 <option value="">---</option>
                                 {this.state.podcast.map((podcast,idx) => (
                                     <option value={podcast.id}>{podcast.title}</option>
                                 ))}
                               </Form.Control>
                             </Form.Group>
                         </Col>
                         <Col sm="12">
                            - o -
                         </Col>
                         <Col sm="12">
                              <Form.Group >
                                <Form.Label>URL</Form.Label>
                                <Form.Control placeholder="" name="urlSLide28" value={this.state.configuration.urlSLide28} onChange={event => this.handleChange(event)} />
                              </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Tag</Form.Label>
                              <Form.Control as="select" name="tag28" onChange={event => this.handleChange(event)} value={this.state.configuration.tag28 != null && this.state.configuration.tag28.id} >
                                <option value="">---</option>
                                {this.state.tags.map((tag,idx) => (
                                    <option value={tag.id}>{tag.name}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>

                    </Col>
                </Row>
              </div>


             <Col sm="12" className="tagsrow">
                  <Form.Group >
                    <Form.Label>Tags</Form.Label>
                    <ReactTags allowNew={true} className="tagsinput" ref={this.reactTags} tags={this.state.configuration.tags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Etiquetas"/>
                  </Form.Group>
            </Col>
            <Col sm="12">
                 <Form.Group>
                   <Form.Label>Programas</Form.Label>
                   <ReactTags allowNew={false} className="tagsinput" ref={this.reactTagsFeature} tags={this.state.configuration.programs} suggestions={this.state.programs} onDelete={this.onDeleteProgram.bind(this)} onAddition={this.onAdditionProgram.bind(this)} placeholderText="Programas"/>
                 </Form.Group>
           </Col>
           <Col sm="12">
                 <Form.Group>
                   <Form.Label>Exclusivos Radio Viajera</Form.Label>
                   <ReactTags allowNew={false} className="tagsinput" ref={this.reactTagsExclusive} tags={this.state.configuration.exclusives} suggestions={this.state.programs} onDelete={this.onDeleteExclusive.bind(this)} onAddition={this.onAdditionExclusive.bind(this)} placeholderText="Exclusivos Radio Viajera"/>
                 </Form.Group>
           </Col>
           <Col sm="12">
                 <Form.Group>
                   <Form.Label>Tendencias</Form.Label>
                   <ReactTags allowNew={false} className="tagsinput" ref={this.reactTagsTrend} tags={this.state.configuration.trends} suggestions={this.state.tags} onDelete={this.onDeleteTrend.bind(this)} onAddition={this.onAdditionTrend.bind(this)} placeholderText="Tendencias"/>
                 </Form.Group>
           </Col>
           <Col sm="12">
                 <Form.Group>
                   <Form.Label>Emisoras</Form.Label>
                   <ReactTags allowNew={false} className="tagsinput" ref={this.reactTagsStation} tags={this.state.configuration.stations} suggestions={this.state.stations} onDelete={this.onDeleteStation.bind(this)} onAddition={this.onAdditionStation.bind(this)} placeholderText="Emisoras"/>
                 </Form.Group>
           </Col>
           <Col sm="12">
               <Form.Group controlId="formTags">
                 <Form.Label>Radio de inicio</Form.Label>
                 <Form.Control as="select" name="station" onChange={event => this.handleChange(event)} value={this.state.configuration.station != null  && this.state.configuration.station.id} >
                   <option value="">---</option>
                   {this.state.stations.map((station,idx) => (
                       <option value={station.id}>{station.name}</option>
                   ))}
                 </Form.Control>
               </Form.Group>
           </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>
             </Col>
         </Row>
      </div>

      <Modal show={this.state.showconfirm} onHide={event => this.setState({showconfirm:false})}>
        <Modal.Header closeButton>
          <Modal.Title>configurationficacion enviada</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>Se ha enviado la configurationficación correctamente</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={event => this.setState({showconfirm:false})}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </>
  };
}

export default Confighome;
