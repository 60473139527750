import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import './Program.scss';
import toast from 'toasted-notes'
import { programService } from '../../services';
import queryString from 'query-string';
import ReactTags from 'react-tag-autocomplete';


class ProgramEdit  extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state.program = {name:"",description:"", owner:"",programWeb:"",image:"", ranking:0,category:{id:-1},tags:[], elementType:1, creatorId:null, startCentury:"", startYear:"", endCentury:"", endYear:"", relatedContent:"", banner:""};
      this.state.buttonvisibles = true;
      this.state.categories = [];

      if (params.id) {
        this.state.idprogram = params.id;
        this.callSearch();
      } else{
        this.state.idprogram = -1;
      }

      this.handlerUpload = this.handlerUpload.bind(this);
      this.handlerDelete = this.handlerDelete.bind(this);

      this.reactTags = React.createRef()
      this.reactTagsFeature = React.createRef()

      this.callTags();
      this.callCategories();
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
          if (data.category == null || data.category.id == null){
            data.category = {id:-1}
          }
          this.setState({program:data});
          if (data.image) {
             this.setState({valid_url:this.validURLImage(data.image)});
          }
        } else {
          const msg = "No se ha encontrado el programa";
          toast.notify(msg, {position:'top', duration: 5000});
          this.setState({buttonvisibles:false});
        }

      }
      programService.one(this.state.idprogram, callback);
  }

  validURLImage(str) {
    return str.match(/\.(jpeg|jpg|gif|png)$/) != null
  }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({tags:datarows});
      }
    }
    programService.tags(callback);
  }

  callCategories (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({categories:datarows});
      }
    }
    programService.categories(callback);
  }

 handleClickClear(event) {
   window.location.href="/programs";
 }

 handlerUpload(image){
  var program = this.state.program;
  program.image = image;
  this.setState({program:program});
}

handlerDelete(){
  var program = this.state.program;
  program.image = "";
  this.setState({program:program});
}

onDelete (i) {
  const tags = this.state.program.tags.slice(0)
  tags.splice(i, 1)
  var program = this.state.program;
  program.tags = tags;
  this.setState({program:program});
}

onAddition (tag) {
  const tags = [].concat(this.state.program.tags, tag)
  var program = this.state.program;
  program.tags = tags;
  this.setState({program:program});
}

  handleChange(event){
       const value = event.target.value
       var program = this.state.program;
       if (event.target.name === "name") {
         program.name = value;
       } else if (event.target.name === "description") {
         program.description = value;
       } else if (event.target.name === "image") {
         program.image = value;
         this.setState({valid_url:this.validURLImage(value)});
       } else if (event.target.name === "owner") {
         program.owner = value;
       } else if (event.target.name === "programWeb") {
         program.programWeb = value;
       } else if (event.target.name === "category") {
          let index = this.state.categories.findIndex(x => x.id == parseInt(value))
          console.log("INDEX: " + index)
          console.log(this.state.categories)
          if(index != -1){
            program.category = this.state.categories[index];
          }
         
       } else if (event.target.name === "ranking") {
         program.ranking = value;
       } else if (event.target.name === "elementType") {
         program.elementType = parseInt(value);
       } else if (event.target.name === "creatorId") {
         program.creatorId = {id:value};
       } else if (event.target.name === "startCentury") {
         program.startCentury = value;
       } else if (event.target.name === "endCentury") {
         program.endCentury = value;
       } else if (event.target.name === "startYear") {
         program.startYear = value;
       }  else if (event.target.name === "endYear") {
         program.endYear = value;
       }  else if (event.target.name === "relatedContent") {
        program.relatedContent = value;
      }  else if (event.target.name === "banner") {
        program.banner = value;
      }

       this.setState({program:program});
  }


   handleClickSave(event) {
     if (this.state.program.name) {
       if(this.state.idprogram !== -1){
         //EDIT
         this.callEdit();
       } else {
         //CREATE
         this.callCreate();
       }
     } else if (!this.state.program.name || this.state.program.name === "" ) {
         const msg = "El nombre es obligatorio"
         toast.notify(msg, {position:'top', duration: 5000})
     }


   }

     callCreate (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
              this.setState({station:datarows.data});
              this.setState({idstation:datarows.data.id});
              window.location.href="/program?id="+datarows.data.id;
           }

         }
         programService.create(this.state.program, callback);
     }

     callEdit (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
               const msg = "Se ha guardado correctamente";
               toast.notify(msg, {position:'top', duration: 5000});
           }

         }
         programService.edit(this.state.idprogram,this.state.program, callback);
     }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idprogram !== -1) return "Editar programa"; else return "Nuevo programa";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" placeholder="" name="name" value={this.state.program.name} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="3">
                 <Form.Group >
                   <Form.Label>Ranking</Form.Label>
                   <Form.Control type="number" placeholder="" name="ranking" value={this.state.program.ranking} onChange={event => this.handleChange(event)}/>
                 </Form.Group>
             </Col>
            <Col sm="12">
                 <Form.Group >
                   <Form.Label>Descripción</Form.Label>
                   <Form.Control as="textarea" rows="3" placeholder="" name="description" value={this.state.program.description} onChange={event => this.handleChange(event)} />
                 </Form.Group>
           </Col>
           <Col sm="12">
                   <Form.Group >
                     <Form.Label>Podcaster</Form.Label>
                     <Form.Control placeholder="" name="owner" value={this.state.program.owner} onChange={event => this.handleChange(event)} />
                   </Form.Group>
             </Col>
             <Col sm="12">
                   <Form.Group >
                     <Form.Label>Web del podcaster</Form.Label>
                     <Form.Control placeholder="" name="programWeb" value={this.state.program.programWeb} onChange={event => this.handleChange(event)} />
                   </Form.Group>
             </Col>
           <Col sm="6">
                 <Form.Group controlId="formPro">
                   <Form.Label>Temática</Form.Label>
                   <Form.Control as="select" name="category" onChange={event => this.handleChange(event)} value={this.state.program.category.id} >
                     <option value="">---</option>
                     {this.state.categories.map((pro,idx) => (
                         <option value={pro.id}>{pro.name}</option>
                     ))}
                   </Form.Control>
                 </Form.Group>
             </Col>
             <Col sm="6">
                  <Form.Group >
                    <Form.Label>Tags</Form.Label>
                    <ReactTags allowNew={true} className="tagsinput" ref={this.reactTags} tags={this.state.program.tags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Nuevas etiquetas"/>
                  </Form.Group>
            </Col>
            <Col sm="6">
                 <Form.Group controlId="formPro">
                   <Form.Label>Tipo de elemento</Form.Label>
                   <Form.Control as="select" name="elementType" onChange={event => this.handleChange(event)} value={String(this.state.program.elementType)} >
                     <option value="1">Programa</option>
                      <option value="2">Proyecto</option>
                   </Form.Control>
                 </Form.Group>
             </Col>
             <Col sm="6">
                <Form.Group >
                  <Form.Label>URL de contenido relacionado</Form.Label>
                  <Form.Control type="text" placeholder="" name="relatedContent" value={this.state.program.relatedContent} onChange={event => this.handleChange(event)}/>
                </Form.Group>
            </Col>
            <Col sm="12">
              <Form.Group >
                <Form.Label>Banner</Form.Label>
                <Form.Control placeholder="" name="banner" value={this.state.program.banner} onChange={event => this.handleChange(event)} />
              </Form.Group>
        </Col>

            { this.state.program.elementType == 2 ? 
            <div>
              <Col sm="6">
                  <Form.Group >
                    <Form.Label>Siglo de comienzo</Form.Label>
                    <Form.Control type="text" placeholder="" name="startCentury" value={this.state.program.startCentury} onChange={event => this.handleChange(event)}/>
                  </Form.Group>
              </Col>
              <Col sm="6">
                  <Form.Group >
                    <Form.Label>Siglo de fin</Form.Label>
                    <Form.Control type="text" placeholder="" name="endCentury" value={this.state.program.endCentury} onChange={event => this.handleChange(event)}/>
                  </Form.Group>
              </Col>
              <Col sm="6">
                  <Form.Group >
                    <Form.Label>Ano de comienzo</Form.Label>
                    <Form.Control type="text" placeholder="" name="startYear" value={this.state.program.startYear} onChange={event => this.handleChange(event)}/>
                  </Form.Group>
              </Col>
              <Col sm="6">
                  <Form.Group >
                    <Form.Label>Ano de fin</Form.Label>
                    <Form.Control type="text" placeholder="" name="endYear" value={this.state.program.endYear} onChange={event => this.handleChange(event)}/>
                  </Form.Group>
              </Col> 
            </div>
            : null
            }
             

             <Col sm="6">Nombre del creador</Col>
            <Col sm="9">
                <Form.Group >
                  <Form.Label>Imagen</Form.Label>
                  <Form.Control placeholder="" name="image" value={this.state.program.image} onChange={event => this.handleChange(event)} />
                </Form.Group>
          </Col>
           <Col sm="3">
                <Image src={this.state.program.image } rounded fluid className="imagetable" style={{ display: this.state.valid_url  ? "block" : "none" }} />
          </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default ProgramEdit;
