import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import './Profile.scss';


class Profile  extends Home {


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Editar perfil" newbutton={false} link="" handler=""/>
      </div></>
  };
}

export default Profile;
