import BaseService from './_base.service';

export const popupService = {
    search,
    all,
    edit,
    create,
    one,
    remove,
    podcasts
};

function search(offset, title, callback) {
    let body = JSON.stringify({ title })
    return BaseService.post("/popup/search/"+offset, body, callback, true)
}

function edit(id, bodyelement, callback) {
    if(bodyelement.podcastId == -1){
        bodyelement.podcastId = null;
      }
      if(bodyelement.nextPopupId == -1){
        bodyelement.nextPopupId = null;
      }
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/popup/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    if(bodyelement.podcastId == -1){
        bodyelement.podcastId = null;
      }
      if(bodyelement.nextPopupId == -1){
        bodyelement.nextPopupId = null;
      }
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/popup/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/popup/one/"+id, callback);
}
function remove(id,callback) {
    return BaseService.delete("/popup/"+id, callback);
}
function all(callback) {
    return BaseService.get("/popup/all/", callback);
}

function podcasts(callback) {
    return BaseService.get("/podcast/all/", callback);
}