import React from 'react';
import ReactDOM from "react-dom"
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form } from 'react-bootstrap';
import './Roles.scss';
import toast from 'toasted-notes'
import { rolesService } from '../../services';


class RolesEdit  extends Home{


  constructor(props) {
      super(props)
      this.state.rol = {code:"",name:"",descripction:"",permissions:[]};
      this.state.buttonvisibles = true;
      this.state.permissions = [];
      this.state.permissionsrol = [];
      this.callPermissions();
      if (this.props.match.params.id && this.props.match.params.id !== 'new') {
        this.state.idrol = this.props.match.params.id;
        this.callSearch();
      } else{
        this.state.idrol = -1;
      }

      this.reactTags = React.createRef()
      this.HOST = process.env.REACT_APP_HOST;

  }

  callPermissions (){

    let callback = (datarows, error) => {
      if (datarows) {
        console.log(datarows);
         this.setState({permissions:datarows});
      }
    }
    rolesService.permissions(callback);
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
           this.setState({rol:data});
           if(data.permissions) {
             var per = [];
             for (const [value] of data.permissions.entries()) {
              per.push(value.code);
             }
             this.setState({permissionsrol:per});
           }
        } else {
          const msg = "No se ha encontrado el rol"
          toast.notify(msg, {position:'top', duration: 5000})
        }

      }
      rolesService.one(this.state.idrol, callback);
  }

   handleClickClear(event) {
     window.location.href="/roles/";
   }



     handleChange(event){
          const value = event.target.value
          var rol = this.state.rol;
          if (event.target.name === "name") {
            rol.name = value;
          } else if (event.target.name === "code") {
            rol.code = value;
          } else if (event.target.name === "descripction") {
            rol.descripction = value;
          } else if (event.target.name === "permissions") {
              var node = ReactDOM.findDOMNode(this.refs.colTags);
              var options = [].slice.call(node.querySelectorAll('option'));
              var selected = options.filter(function (option) {
               return option.selected;
              });
              var selectedValues = [];
              var selectedCodes = [];
              selected.map(function (option) {
                 selectedValues.push({code:option.value});
                 selectedCodes.push(option.value);
                 return option.selected;
              });
              rol.permissions = selectedValues;
              this.setState({permissionsrol:selectedCodes});
          }

          this.setState({rol:rol});
     }


        handleClickSave(event) {
          if (this.state.rol.code && this.state.rol.code && this.state.rol.name !== "" && this.state.rol.name !== "") {
            if(this.state.idrol !== -1){
              //EDIT
              this.callEdit();
            } else {
              //CREATE
              this.callCreate();
            }
          } else if (!this.state.rol.code || this.state.rol.code === "" ) {
              const msg = "El nombre es obligatorio"
              toast.notify(msg, {position:'top', duration: 5000})
          } else {
              const msg = "El código es obligatorio"
              toast.notify(msg, {position:'top', duration: 5000});
          }


        }

        callCreate (){

            let callback = (datarows, error) => {
              if (datarows && datarows.data) {
                 this.setState({rol:datarows.data});
                 this.setState({idrol:datarows.data.code});
                 window.location.href="/rol/"+datarows.data.code;
              }

            }
            rolesService.create(this.state.rol, callback);
        }

        callEdit (){

            let callback = (datarows, error) => {
              if (datarows && datarows.data) {
                  const msg = "Se ha guardado correctamente";
                  toast.notify(msg, {position:'top', duration: 5000});
              }

            }
            rolesService.edit(this.state.idrol,this.state.rol, callback);
        }

  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idrol !== -1) return "Editar rol"; else return "Nueva rol";})() } newbutton={false} link=""/>
         <Row>
         <Col sm="12">
           <Row>
              <Col sm="6">
                  <Form.Group >
                    <Form.Label>Código</Form.Label>
                    <Form.Control type="text" placeholder="" name="code" readOnly={this.state.idrol !== -1} value={this.state.rol.code} onChange={event => this.handleChange(event)}  />
                  </Form.Group>
              </Col>
              <Col sm="6">
                   <Form.Group >
                     <Form.Label>Nombre</Form.Label>
                     <Form.Control type="text" placeholder="" name="name" value={this.state.rol.name} onChange={event => this.handleChange(event)}/>
                   </Form.Group>
               </Col>
               <Col sm="12">
                    <Form.Group >
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control as="textarea" rows="3" type="text" placeholder="" name="descripction" value={this.state.rol.descripction} onChange={event => this.handleChange(event)}/>
                    </Form.Group>
                </Col>
                <Col sm="12">
                    <Form.Group controlId="formTags">
                      <Form.Label>Permisos</Form.Label>
                      <Form.Control as="select" multiple name="permissions" onChange={event => this.handleChange(event)}  ref="colTags" value={this.state.permissionsrol} >
                        {this.state.permissions.map((tag,idx) => (
                            <option value={tag.code}>{tag.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                </Col>
                </Row>
              </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Atras</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default RolesEdit;
