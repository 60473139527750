import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form } from 'react-bootstrap';
import './RSS.scss';
import toast from 'toasted-notes'
import { RSSService } from '../../services';
import { podcastService } from '../../services';
import queryString from 'query-string';
class RSSEdit  extends Home{
    constructor(props) {
        super(props)
        let params = queryString.parse(this.props.location.search);
        this.state.RSS = {name:"", type:"", title:"",visibleApp:false,isHome:false,donwload:false,tag:{id:-1},locale:"",iconMap:""};
        this.state.buttonvisibles = true;
        this.state.tags = [];

        if (params.id) {
            this.state.idRSS = params.id;
            this.callSearch();
        } else{
            this.state.idRSS = -1;
        }

        this.callTags();
    }
    callSearch (){
        let callback = (data, error) => {
            if (data) {
                if (data.tag == null || data.tag.id == null){
                  data.tag = {id:-1}
                }
                console.log("*********")
                console.log(data)
                this.setState({RSS:data});
            } else {
                const msg = "No se ha encontrado la RSS";
                toast.notify(msg, {position:'top', duration: 5000});
                this.setState({buttonvisibles:false});
            }
        }
        RSSService.one(this.state.idRSS, callback);
    }
    callTags (){

        let callback = (datarows, error) => {
          if (datarows) {
             this.setState({tags:datarows});
          }
        }
        podcastService.tags(callback);
      }
    handleClickClear(event) {
        window.location.href="/RSSs";
    }
    setChecked(field,event,checked) {
        var RSS = this.state.RSS;
         if (field === "visibleApp") {
          RSS.visibleApp = checked;
         } else if (event.target.name === "donwload") {
          RSS.donwload = checked;
         } else if (field === "isHome") {
          RSS.isHome = checked;
         }

         this.setState({RSS:RSS});

       }
    handleChange(event){
        const value = event.target.value
        var RSS = this.state.RSS;
        if (event.target.name === "name") {
            RSS.name = value;
        }
        else if (event.target.name === "type"){
            RSS.type = value;
        } else if (event.target.name === "title"){
            RSS.title = value;
        } else if (event.target.name === "locale") {
          RSS.locale = value;
        } else if (event.target.name === "icon_map") {
          RSS.location = value;
          this.setState({valid_url_icon:this.valid_url_icon(value)});
        }  else if (event.target.name === "tag") {
          let index = this.state.tags.findIndex(x => x.id == parseInt(value))
          console.log("INDEX: " + index)
          console.log(this.state.tags)
          if(index != -1){
            RSS.tag = this.state.tags[index];
          }
         }
        this.setState({RSS:RSS});
    }
    handleClickSave(event) {
        if (this.state.RSS.name) {
            if(this.state.idRSS !== -1){
                //EDIT
                this.callEdit();
            } else {
                //CREATE
                this.callCreate();
            }
        } else if (!this.state.RSS.name || this.state.RSS.name === "" ) {
            const msg = "El nombre es obligatorio"
            toast.notify(msg, {position:'top', duration: 5000})
        }
    }
    callCreate (){
        let callback = (datarows, error) => {
            if (datarows && datarows.data) {
                this.setState({station:datarows.data});
                this.setState({idstation:datarows.data.id});
                window.location.href="/RSS?id="+datarows.data.id;
            }
        }
        RSSService.create(this.state.RSS, callback);
    }
    callEdit (){
        let callback = (datarows, error) => {
            if (datarows && datarows.data) {
                const msg = "Se ha guardado correctamente";
                toast.notify(msg, {position:'top', duration: 5000});
            }
        }
        RSSService.edit(this.state.idRSS,this.state.RSS, callback);
    }
  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idRSS !== -1) return "Editar RSS"; else return "Nueva RSS";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" placeholder="" name="name" value={this.state.RSS.name} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="3">
                <Form.Group >
                  <Form.Label>Título</Form.Label>
                  <Form.Control type="text" placeholder="" name="title" value={this.state.RSS.title} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="3">
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Tipo</Form.Label>
                    <Form.Control as="select" name="type"
                    value={this.state.RSS.type}
                    onChange={event => this.handleChange(event)} required>
                    <option value="">Seleccione un tipo</option>
                    <option value="iVoox">iVoox</option>
                    <option value="Spreaker">Spreaker</option>
                    <option value="Anchor">Anchor</option>
                    <option value="RTVE">RTVE</option>
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col sm="3">
               <Form.Group controlId="visibleApp">
                 <Form.Check type="checkbox" label="Visible en APP" name="visibleApp" checked={this.state.RSS.visibleApp} onChange={(event) => this.setChecked("visibleApp",event,!this.state.RSS.visibleApp)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
               <Form.Group controlId="donwload">
                 <Form.Check type="checkbox" label="Descargable" name="donwload" checked={this.state.RSS.donwload} onChange={(event) => this.setChecked("donwload",event,!this.state.RSS.donwload)}/>
               </Form.Group>
             </Col>
             <Col sm="3">
               <Form.Group controlId="isHome">
                 <Form.Check type="checkbox" label="En la home" name="isHome" checked={this.state.RSS.isHome} onChange={(event) => this.setChecked("isHome",event,!this.state.RSS.isHome)}/>
               </Form.Group>
             </Col>
            <Col sm="3">
                  <Form.Group >
                    <Form.Label>Idioma</Form.Label>
                    <Form.Control placeholder="" name="locale" value={this.state.RSS.locale} onChange={event => this.handleChange(event)} />
                  </Form.Group>
             </Col>
             <Col sm="6">
                 <Form.Group controlId="formPro">
                   <Form.Label>Tag</Form.Label>
                   <Form.Control as="select" name="tag" onChange={event => this.handleChange(event)} value={this.state.RSS.tag.id} >
                     <option value="">---</option>
                     {this.state.tags.map((pro,idx) => (
                         <option value={pro.id}>{pro.name}</option>
                     ))}
                   </Form.Control>
                 </Form.Group>
             </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}
export default RSSEdit;
