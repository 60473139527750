import BaseService from './_base.service';
export const storyService = {
    search,
    all,
    edit,
    create,
    one,
    remove
};
function search(offset,name, type, callback) {
    if(type) type = type.toLowerCase()
    let body = JSON.stringify({ name, type})
    return BaseService.post("/story/search/" + offset, body, callback, true)
}
function edit(id, bodyelement, callback) {
    console.log("EDIT")
    console.log(bodyelement)
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/story/"+id, body, callback, true)
}
function create(bodyelement, callback) {
    console.log("CREATE")
    console.log(bodyelement)
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/story/", body, callback, true)
}
function one(id,callback) {
    return BaseService.get("/story/one/"+id, callback);
}
function remove(id,callback) {
    return BaseService.delete("/story/"+id, callback);
}
function all(callback) {
    return BaseService.get("/story/all/", callback);
}