import React from 'react';
import './TableTic.scss';
import { Button, Table,Row,Col, Pagination,Image,Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle,faTimesCircle,faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';


class PaginationTic extends React.Component {
  constructor(props) {
      super(props)
      this.state = { total: this.props.total, page:this.props.page, size:this.props.size, link:this.props.link, query:props.query}
  }

  static getDerivedStateFromProps(props, state) {
      if (props.total !== state.total) {
        return  {total:props.total, link:props.link, query:props.query};
      }

      return null;
  }

 createPanel() {
   console.log(this.state);
   if(this.state.total > 0){
     if(this.state.total === this.props.size) {
        //Hay que paginar
        if (this.state.page == 0) {
          // Estamos en la primera página
          return (
            <Pagination>
              <Pagination.Next href={this.state.link +"?page="+ (parseInt(this.props.page)+1) + "&" + this.state.query}/>
            </Pagination>
          );
        } else {
          return (
            <Pagination>
              <Pagination.Prev href={this.state.link +"?page="+ (parseInt(this.props.page)-1) + "&" + this.state.query}/>
              <Pagination.Next href={this.state.link +"?page="+ (parseInt(this.props.page)+1) + "&"  + this.state.query}/>
            </Pagination>
          );
        }

     } else {
       if (this.state.page == 0) {
         //Hay menos que el tamaño de la página
         return <div></div>
       } else {
         //Estamos en la ultima pagina
         return (
           <Pagination>
             <Pagination.Prev href={this.state.link +"?page="+ (parseInt(this.props.page)-1) + "&" +  this.state.query}/>
           </Pagination>
         );
       }

     }
   } else {
     //No hay elementos
     return <div></div>
   }
 }

  render(){
    return this.createPanel();
  }


}

class TableCell extends React.Component {
  constructor(props) {
      super(props)
      this.state = { type: this.props.type, field:this.props.field, data:this.props.data, id: this.props.id, onClick: this.props.onClick}
      this.HOST = process.env.REACT_APP_HOST;
      this.HTTPS = process.env.HTTPS;
  }


  getArrayData(data,type,key){
    if (data) {
      var value = "";
      for(var i = 0; i < data.length; i++){
        let fields = key.split(".");
        if(fields.length > 1){
            value = value +  '<div>' + this.getData(data[i][fields[1]],type)  + "</div>";
        } else {
            value = value + this.getData(data[i],type) + ",";
        }

      }

      return value;
    }

    return '';

  }

  getUrlImage(data){
      if (data.indexOf("http") !== -1){
        return data;
      } else {
        return this.HOST+"/image/"+data;
      }
  }

  getData(data,type) {
    if (data || data === 0) {
      switch (type) {
        case 'image':
            return <Image src={this.getUrlImage(data)} rounded fluid className="imagetable" />;
        case 'number':
            return data;
        case 'decimal':
            return data;
        case 'audio':
            return <audio controls><source src={data} type="audio/mpeg" /></audio>;
        case 'date':
            return data? moment(data).format("DD/MM/YYYY"):"";
        case 'boolean':
            if(data) {
              return <FontAwesomeIcon icon={faCheckCircle} />;
            } else {
              return <FontAwesomeIcon icon={faTimesCircle} />;
            }
        default:
          return data;
      }
    } else {
      return '';
    }

  }

  render() {
    if (this.state.type === 'array') {
      let fields = this.state.field.split(".");
      if(fields.length > 1){
         return  <td key={this.state.field} className={this.state.type} onClick = {this.state.onClick} dangerouslySetInnerHTML={{__html:this.getArrayData(this.state.data[fields[0]],this.state.type,this.state.field)}}></td>
        } else {
          return  <td key={this.state.field} className={this.state.type} onClick = {this.state.onClick}>{this.getData(this.state.data[this.state.field],this.state.type)}</td>
        }
    } else {
      let fields = this.state.field.split(".");
      if(fields.length > 1){
          var valueAux = this.state.data[fields[0]];
          if (valueAux){
            for(var i = 1;i < fields.length; i++){
              if(valueAux[fields[i]]) {
                valueAux = valueAux[fields[i]];
              }
            }
          }
          return  <td key={this.state.field} className={this.state.type} onClick = {this.state.onClick}>{this.getData(valueAux,this.state.type)}</td>
      } else {
        return  <td key={this.state.field} className={this.state.type} onClick = {this.state.onClick}>{this.getData(this.state.data[this.state.field],this.state.type)}</td>
      }
    }

  };
}

class TableTic extends React.Component {


  constructor(props) {
      super(props)
      this.state = {show:false, headers: props.headers,fields:props.fields,data:props.data,row:props.row, total:props.total, page:props.page, sizepage:props.sizepage, type:props.type, handler:props.handler, link:this.props.link, query:props.query,handlerdel:props.handlerdel,idselected:-1}
      if(props.identy){
        this.state.identy = props.identy;
      } else {
        this.state.identy = 'id';
      }

  }

  static getDerivedStateFromProps(props, state) {
      if (props.total !== state.total) {
        return  {data: props.data, total:props.total, link:props.link, query:props.query};
      }

      return null;
  }

  render() {
    return <>
      <Row>
          <Col sm="12">
              <Table striped hover responsive>
                  <thead>
                    <tr>
                    {this.state.headers.map((headCell,idx) => (
                        <th key={this.state.fields[idx]}>{headCell}</th>
                    ))}

                    {this.state.handlerdel  && <th key="delete">.</th> }
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((row,idx) => (
                      <tr key={row[this.state.identy]} data-id={row[this.state.identy]}>
                        {this.state.fields.map((field,cellidx) => (
                           <TableCell key={row[this.state.identy]+"-"+field} type={this.state.type[cellidx]} field={field} data={row} id={row[this.state.identy]} onClick={() => {this.state.handler(row[this.state.identy])}} />
                        ))}

                        {this.state.handlerdel  && <td key="delete"><Button variant="danger" className="" onClick={() => this.setState({show:true,idselected:row[this.state.identy]})}><FontAwesomeIcon icon={faTrash} /></Button></td> }
                      </tr>
                    ))}
                  </tbody>
             </Table>
          </Col>
          <Col sm="12" className="text-center">
              <PaginationTic total={this.state.total} size={this.state.sizepage} page={this.state.page} link={this.state.link} query={this.state.query} />
          </Col>
      </Row>
      <Modal show={this.state.show} onHide={() => this.setState({show:false,idselected:-1})}>
        <Modal.Header closeButton>
          <Modal.Title>Borrar</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <Row>
            <Col>
              <p>¿Estas seguro que desea borrarlo?</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({show:false,idselected:-1})}>No</Button>
          <Button variant="primary" onClick={() => { this.setState({show:false}); this.state.handlerdel(this.state.idselected);}}>Sí</Button>
        </Modal.Footer>
      </Modal>
    </>
  };
}

export default TableTic;
