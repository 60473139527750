import BaseService from './_base.service';
import axios from "axios";

export const podcastService = {
    search,
    tags,
    all,
    programs,
    podcasts,
    edit,
    create,
    one,
    remove,
    importfile,
    exportPodcast
};

function search(offset,latitude,longitude,location,tags,title,id,program,general,locale,type, callback) {
    if(locale == ""){
        locale = null;
      }
    let elementType = type
    let isPoi = null

    if(elementType){
        if(elementType == 3){
            isPoi = true
            elementType = 1
        } else {
            isPoi = false
        }
    }
    let body = JSON.stringify({ general,latitude,longitude,location,tags,title,id,program,locale,elementType, isPoi})
    return BaseService.post("/podcast/search/"+offset, body, callback, true)
}

function tags(callback) {
    return BaseService.get("/tag/all/", callback);
}

function programs(callback) {
    return BaseService.get("/program/all/", callback);
}

function podcasts(callback) {
    return BaseService.get("/podcast/all/", callback);
}

function edit(id, bodyelement, callback) {
    if(bodyelement.program.id == -1){
      bodyelement.program = null;
    }
    if(bodyelement.elementType === 3){
        bodyelement.elementType = 1;
        bodyelement.isPoi = true
    } else {
        bodyelement.isPoi = false
    }

    let creator = bodyelement.creatorId
    if(Number.isInteger(creator)) bodyelement.creatorId = {id:creator}
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/podcast/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    bodyelement.isPoi = false
    if(bodyelement.program.id == -1){
      bodyelement.program = null;
    }
    if(bodyelement.elementType === 3){
        bodyelement.elementType = 1;
        bodyelement.isPoi = true
    } else {
        bodyelement.isPoi = false
    }
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/podcast/", body, callback, true)
}

function one(id,callback) {
    let podcast = BaseService.get("/podcast/one/"+id, callback);

    return podcast;
}

function importfile(excel, callback) {
    return BaseService.postMultipart("/podcast/import", excel, callback, true)
    //return BaseService.postMultipart("/import/podcast", excel, callback, true)
}
function remove(id,callback) {
    return BaseService.delete("/podcast/"+id, callback);
}
function all(callback) {
    return BaseService.get("/podcast/all/", callback);
}

function exportPodcast(callback) {
    return BaseService.get("/podcast/export", callback)
    /*axios.get('http://export-api:5002/export/podcast').then(resp => {
      console.log(resp.data);
      return 200
    });*/
}
