import BaseService from './_base.service';

export const routeService = {
    search,
    all,
    edit,
    create,
    one,
    remove
};

function search(offset,name, callback) {
    let body = JSON.stringify({ name})
    return BaseService.post("/route/search/"+offset, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/route/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/route/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/route/one/"+id, callback);
}
function remove(id,callback) {
    return BaseService.delete("/route/"+id, callback);
}
function all(callback) {
    return BaseService.get("/route/all/", callback);
}