import React from 'react';
import ReactDOM from "react-dom"
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import './Poi.scss';
import { poiService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form, Modal } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';
import queryString from 'query-string';


class Poi extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state['headers'] =  ["id","#","Titulo","Latitud","Longitud","Tags","Ruta","Fecha"];
      this.state['fields'] =  ["idRadioViajera","image","title","latitude","longitude","tags.name","route.name","dateCreate"];
      this.state['type'] =  ["text","image","text","text","text","array","array","date"];
      this.state['data'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 20;
      this.state['filtertitle'] = params.title?params.title:"";
      this.state['filtergeneral'] = params.general?params.general:"";
      this.state['filterlatitude'] =  params.latitude?params.latitude:"";
      this.state['filterlongitude'] =  params.longitude?params.longitude:"";
      this.state['filterroute'] =  params.route?this.readRoute(params.route):[];
      this.state['filterid'] =  params.idrad?params.idrad:"";
      this.state['filtertags'] =  params.tags?this.readTag(params.tags):[];
      this.state['query'] = this.getFilter();
      this.state['tags'] = [];
      this.state['routes'] = [];
      this.state['link'] = "/pois";
      //this.state['showconfirm'] = false;
      //this.state['showconfirmExport'] = false;

      if (params.page) {
        this.state['page'] = params.page;
        this.callPodcast(params.page,this.state.filterlatitude,this.state.filterlongitude,this.state.filtertags,this.state.filtertitle,this.state.filterid,this.state.filterroute,this.state.filtergeneral);
      } else {
        this.state['page'] = 0;
        this.callPodcast(0,this.state.filterlatitude,this.state.filterlongitude,this.state.filtertags,this.state.filtertitle,this.state.filterid,this.state.filterroute,this.state.filtergeneral);
      }
      this.inputup = React.createRef();
      this.reactTags = React.createRef()
      this.reactRoutes = React.createRef()
      this.callTags();
      this.callRoutes();
  }

  readTag(tags){
      var array = []
      if (Array.isArray(tags)){
          tags.forEach((item, i) => {
              var split = item.split("|");
              array.push({id:split[0],name:split[1]});
          });
      } else {
            var split = tags.split("|");
            array.push({id:split[0],name:split[1]});
      }

      return array;

  }

  readRoute(route){
    var array = []
    if (Array.isArray(route)){
      route.forEach((item, i) => {
            var split = item.split("|");
            array.push({id:split[0],name:split[1]});
        });
    } else {
          var split = route.split("|");
          array.push({id:split[0],name:split[1]});
    }

    return array;

}

  callPodcast (offset,latitude,longitude,tags,title,id,route,general){
      var tagsAux = [];
      if(tags && tags.length > 0){
         tags.forEach((item, i) => {
            tagsAux.push(item.id);
         });

      }

      let callback = (datarows, error) => {

        if (datarows) {
           this.setState({data:datarows.data,total:datarows.total});
        }

      }
      poiService.search(offset,latitude,longitude,tagsAux,title,id,route,general, callback);
  }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({tags:datarows});
      }
    }
    poiService.tags(callback);
  }

  callRoutes (){
    console.log("ENTRA EN POI ROUTES")
    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({routes:datarows});
      }
    }
    poiService.routes(callback);
  }

  handleChange(event){
       const value = event.target.value

       if (event.target.name === "title") {
           this.setState({filtertitle:value});
           this.setState({filtergeneral:""});
       } else if (event.target.name === "general") {
          this.setState({filtergeneral:value});
          const valueaux = ""
          this.setState({filtertitle:valueaux});
          this.setState({filterlatitude:valueaux});
          this.setState({filterlongitude:valueaux});
          this.setState({filterroute:[]});
          this.setState({filterid:valueaux});
          this.setState({filtertags:[]});
       } else if (event.target.name === "latitude") {
           this.setState({filtergeneral:""});
           this.setState({filterlatitude:value});
       } else if (event.target.name === "longitude") {
           this.setState({filtergeneral:""});
           this.setState({filterlongitude:value});
       } else if (event.target.name === "route") {
          this.setState({filtergeneral:""});
          var node = ReactDOM.findDOMNode(this.refs.colRoutes);
          var options = [].slice.call(node.querySelectorAll('option'));
          var selected = options.filter(function (option) {
          return option.selected;
          });
          var selectedValues = [];
          selected.map(function (option) {
          selectedValues.push(option.value);
          return option.selected;
          });
          this.setState({filterroute:selectedValues});
       } else if (event.target.name === "idrad") {
           this.setState({filtergeneral:""});
           this.setState({filterid:value});
       } else if (event.target.name === "tags") {
           this.setState({filtergeneral:""});
           var node = ReactDOM.findDOMNode(this.refs.colTags);
           var options = [].slice.call(node.querySelectorAll('option'));
           var selected = options.filter(function (option) {
            return option.selected;
           });
           var selectedValues = [];
            selected.map(function (option) {
            selectedValues.push(option.value);
            return option.selected;
           });
           this.setState({filtertags:selectedValues});
       }
     }

     clickCell(id){
       window.location.href="/poi?id="+id;
     }


     clickDel(id){
       let callback = (datarows, error) => {
          window.location.reload();
       }
       poiService.remove(id, callback);
     }

    getFilter(){

       var query=""
       if(this.state.filtertitle !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "title=" + this.state.filtertitle ;
       }
       if(this.state.filtergeneral !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "general=" + this.state.filtergeneral ;
       }
       if(this.state.filterlatitude !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "latitude=" + this.state.filterlatitude ;
       }
       if(this.state.filterid !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "idrad=" + this.state.filterid ;
       }
       if(this.state.filterlongitude !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "longitude=" + this.state.filterlongitude ;
       }
       if(this.state.filterroute.length > 0){
        debugger;
        this.state.filterroute.forEach( function(tag) {
            if(query !== ""){
               query = query + "&";
            }
            query = query + "route=" + tag.id +"|"+tag.name;
        });
      }
       if(this.state.filtertags.length > 0){
         debugger;
         this.state.filtertags.forEach( function(tag) {
             if(query !== ""){
                query = query + "&";
             }
             query = query + "tags=" + tag.id +"|"+tag.name;
         });
       }
       return query;
     }

     onDelete (i) {
       const tags = this.state.filtertags.slice(0)
       tags.splice(i, 1)
       this.setState({filtertags:tags});
     }

     onAddition (tag) {
       const tags = [].concat(this.state.filtertags, tag)
       this.setState({filtertags:tags});
     }

     onDeleteRoute (i) {
      const route = this.state.filterroute.slice(0)
      route.splice(i, 1)
      this.setState({filterroute:route});
    }

    onAdditionRoute (route) {
      const routes = [].concat(this.state.filterroute, route)
      this.setState({filterroute:routes});
    }


    handleClickClear(event) {
      window.location.href="/pois";
    }
   handleClickSearch(event) {
     this.setState({query:this.getFilter()});
     window.location.href="/pois?page=0&"+this.getFilter();
   }

   handleUpload(){
      this.inputup.current.click()
   }

  //  handleSelectedExcel(event){
  //    const value = event.target.files[0];

  //    if (value) {
  //      let callback = (data, error) => {
  //        if (data) {
  //          this.setState({showconfirm:true});
  //        }
  //      }
  //      poiService.importfile(value, callback);
  //    }

  //  }

  //  handleExport(event){
  //    let callback = (datarows, error) => {
  //        this.setState({showconfirmExport:true});
  //    }
  //    podcastService.exportPodcast(callback);

  //  }

  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de puntos de interés" newbutton={true} link="/poinew"  />
         <Accordion defaultActiveKey="0">
           <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="default" eventKey="0">
                Filtrar
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="filterpanel">
                <Row>
                  <Col sm="3">
                  <Form.Group >
                    <Form.Label>Buscador como en APP</Form.Label>
                    <Form.Control type="text" placeholder="" name="general" value={this.state.filtergeneral} onChange={event => this.handleChange(event)} />
                  </Form.Group>
                </Col>
                  <Col sm="3">
                  <Form.Group >
                    <Form.Label>ID</Form.Label>
                    <Form.Control type="text" placeholder="" name="idrad" value={this.state.filterid} onChange={event => this.handleChange(event)} />
                  </Form.Group>
                </Col>
                  <Col sm="3">
                    <Form.Group >
                      <Form.Label>Titulo</Form.Label>
                      <Form.Control type="text" placeholder="" name="title" value={this.state.filtertitle} onChange={event => this.handleChange(event)} />
                    </Form.Group>
                  </Col>
                  <Col sm="3">
                    <Form.Group controlId="formLatitude">
                      <Form.Label>Latitud</Form.Label>
                      <Form.Control type="text" placeholder="" name="latitude" value={this.state.filterlatitude} onChange={event => this.handleChange(event)} />
                    </Form.Group>
                  </Col>
                  <Col sm="3">
                      <Form.Group controlId="formLongitude">
                        <Form.Label>Longitud</Form.Label>
                        <Form.Control type="text" placeholder="" name="longitude" value={this.state.filterlongitude} onChange={event => this.handleChange(event)}  />
                      </Form.Group>
                  </Col>
        
                  <Col sm="3">
                       <Form.Group >
                         <Form.Label>Tags</Form.Label>
                         <ReactTags allowNew={true} className="tagsinput" ref={this.reactTags} tags={this.state.filtertags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Etiquetas"/>
                       </Form.Group>
                  </Col>
                  <Col sm="3">
                       <Form.Group >
                         <Form.Label>Rutas</Form.Label>
                         <ReactTags allowNew={true} className="routesinput" ref={this.reactRoutes} tags={this.state.filterroute} suggestions={this.state.routes} onDelete={this.onDeleteRoute.bind(this)} onAddition={this.onAdditionRoute.bind(this)} placeholderText="Rutas"/>
                       </Form.Group>
                  </Col>

                </Row>
                <Row>
                    <Col className="text-right">
                        <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                        <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                    </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
         </Accordion>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} link={this.state.link} query={this.state.query} handlerdel={this.clickDel}  />

      </div>
      </>
  };
}

export default Poi;
