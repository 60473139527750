import React from 'react';
import { Form, Row, Col, Container, Button, Image, Alert, Modal} from 'react-bootstrap';
import { userService } from '../../../services';
import logo from '../../../assets/img/logoradio.png'
import './Login.scss';
import { useHistory } from "react-router-dom";

const Login = () => {

  let history = useHistory()

  const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const [enableButton,setEnableButton ] = React.useState(() => {
    let emailuser = localStorage.getItem('emailuser')
    let passuser = localStorage.getItem('passuser')
    if (passuser && passuser.length >= 4 && regexp.test(emailuser)) {
      return false
    } else {
      return true
    }
  });

    const [enableButtonRecovery, setEnableButtonRecovery] = React.useState(true);


  const [emailuser,setEmailuser ] = React.useState(() => {
      let emailuser = localStorage.getItem('emailuser')
      if(emailuser === 'undefined'){
          return '';
      }
     return emailuser;
  });


  const [passuser,setPassuser ] = React.useState(() => {
      let passuser = localStorage.getItem('passuser')
      if(passuser === 'undefined'){
          return '';
      }
     return passuser;
  });


  const [emailRecovery,setEmailRecovery ] = React.useState(() => {
    return '';
  });


  const [checked, setChecked] = React.useState(true);

  const [haveError, setHaveError] = React.useState(false);

  const handleClick = (event) => {

    setHaveError(false)

    if (checked) {
       localStorage.setItem('emailuser', emailuser);
       localStorage.setItem('passuser', passuser);
    }

    let callback = (data, error) => {

      if (data) {
         localStorage.setItem('token', data.tokenJwt);
         localStorage.setItem('user', JSON.stringify(data));
         history.push("/home");
      } else {
        setHaveError(true)
      }
    }
    userService.login(emailuser, passuser, callback)
  }

  const handleClickForbiden = (event) => {
    setShow(true)
  }

  const handleClickRecovery = (event) => {
    setShow(false)
    let callback = (data, error) => {
        console.log('Contraseña enviada al correo');
        setShowConfirm(true);
        setEmailRecovery('');
    }

    userService.recovery(emailRecovery, callback)

  }

  const handleChange = (event) => {
    const value = event.target.value
    console.log(event.target.name, value)

    if (event.target.name === "email") {
        setEmailuser(value)
        if (passuser && passuser.length >= 4 && regexp.test(value)) {
          setEnableButton(false)
        } else {
          setEnableButton(true)
        }
    } else if (event.target.name === "pass") {
        setPassuser(value)
        if (value.length >= 4 && regexp.test(emailuser)) {
          setEnableButton(false)
        } else {
          setEnableButton(true)
        }
    } else if (event.target.name === "emailrecovery") {
        setEmailRecovery(value)
        if (regexp.test(value)) {
          setEnableButtonRecovery(false)
        } else {
          setEnableButtonRecovery(true)
        }
    }


  }


  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);


  const [showConfirm, setShowConfirm] = React.useState(false);
  const handleCloseConfirm = () => setShowConfirm(false);

  return (
    <>
    <div className="backlogin"> <div className="canvas">
      <Container className="login">

        <Row>
          <Col sm="3"></Col>
          <Col sm="6" className="card">
            <Row>
                <Col sm="4"></Col>
                <Col sm="4">
                    <Image src={logo} rounded fluid/>
                </Col>
                <Col sm="4"></Col>
            </Row>
            <Row>
                <Col sm="12">
                  <div>
                    <Alert id="errorlogin" key='errorlogin' variant='danger' className={haveError?"showError":"hiddenError"}>
                      Usuario o contraseña incorrecta
                    </Alert>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" name="email" onChange={handleChange} value={emailuser} />
                      <Form.Text className="text-muted">
                        Debe inidicar el email
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Contraseña</Form.Label>
                      <Form.Control type="password" placeholder="Contraseña" name="pass" onChange={handleChange} value={passuser}  />
                      <Form.Text className="text-muted">
                        Debe inidicar la contraseña
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label="Recordarme" checked={checked} onChange={() => setChecked(!checked)}/>
                    </Form.Group>
                    <div className="text-center">
                      <Button variant="primary"  disabled={enableButton} onClick={handleClick}>
                        Entrar
                      </Button>
                    </div>
                    <div className="text-center">
                      <Button variant="light"  onClick={handleClickForbiden}>
                        ¿Has olvidado la constraseña?
                      </Button>
                    </div>
                  </div>

                </Col>
            </Row>

          </Col>
          <Col sm="3"></Col>
        </Row>
      </Container>
    </div>



  </div>

  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Recuperar contraseña</Modal.Title>
    </Modal.Header>

    <Modal.Body>

      <Row>
        <Col>
          <p>Introduce tu email y te enviaremos una nueva contraseña</p>
        </Col>
      </Row>
       <Row>
        <Col>
          <Form.Group controlId="formBasicEmail">
            <Form.Control type="email" placeholder="Enter email" name="emailrecovery" onChange={handleChange} value={emailRecovery} />
          </Form.Group>
        </Col>
       </Row>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
      <Button variant="primary" disabled={enableButtonRecovery} onClick={handleClickRecovery}>Enviar</Button>
    </Modal.Footer>
  </Modal>

  <Modal show={showConfirm} onHide={handleCloseConfirm}>
    <Modal.Header closeButton>
      <Modal.Title>Nueva contraseña enviada</Modal.Title>
    </Modal.Header>

    <Modal.Body>

      <Row>
        <Col>
          <p>Se ha enviado una nueva contraseña a tu correo electrónico</p>
        </Col>
      </Row>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseConfirm}>Cerrar</Button>
    </Modal.Footer>
  </Modal>
  </>


  );
}

export default Login;
