import BaseService from './_base.service';

export const linkAudioService = {
    search,
    all,
    edit,
    create,
    one,
    remove,
    podcasts,
    tags
};

function search(offset, title, type, callback) {
    let body = JSON.stringify({ title, type })
    return BaseService.post("/link-audio/search/"+offset, body, callback, true)
}

function edit(id, bodyelement, callback) {
    /*if(bodyelement.podcastId == -1){
        bodyelement.podcastId = null;
      }
      if(bodyelement.nextPopupId == -1){
        bodyelement.nextPopupId = null;
      }*/
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/link-audio/"+id, body, callback, true)
}

function create(bodyelement, callback) {
    /*if(bodyelement.podcastId == -1){
        bodyelement.podcastId = null;
      }
      if(bodyelement.nextPopupId == -1){
        bodyelement.nextPopupId = null;
      }*/
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/link-audio/", body, callback, true)
}

function one(id,callback) {
    return BaseService.get("/link-audio/one/"+id, callback);
}
function remove(id,callback) {
    return BaseService.delete("/link-audio/"+id, callback);
}
function all(callback) {
    return BaseService.get("/link-audio/all/", callback);
}

function podcasts(callback) {
    return BaseService.get("/podcast/all/", callback);
}
function tags(callback) {
    return BaseService.get("/tag/all/", callback);
}
