import React from 'react';
import ReactDOM from "react-dom"
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import './Podcast.scss';
import { podcastService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form, Modal } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';
import queryString from 'query-string';


class Podcast extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state['headers'] =  ["id","#","Titulo","Latitud","Longitud","Ubicación","Tags","Programa","Audio","En App", "En Home","Descargable","Fecha"];
      this.state['fields'] =  ["idRadioViajera","image","title","latitude","longitude","location","tags.name","program.name","url","visibleApp", "isHome", "donwload","dateCreate"];
      this.state['type'] =  ["text","image","text","text","text","text","array","text","audio","boolean","boolean", "boolean","date"];
      this.state['data'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 20;
      this.state['filtertitle'] = params.title?params.title:"";
      this.state['filtergeneral'] = params.general?params.general:"";
      this.state['filterlatitude'] =  params.latitude?params.latitude:"";
      this.state['filterlongitude'] =  params.longitude?params.longitude:"";
      this.state['filterlocality'] =  params.location?params.location:"";
      this.state['filterprogram'] =  params.program?params.program:"";
      this.state['filtertype'] = params.type?params.type:"";
      this.state['filterid'] =  params.idrad?params.idrad:"";
      this.state['filterlocale'] =  params.locale?params.locale:"";
      this.state['filtertags'] =  params.tags?this.readTag(params.tags):[];
      this.state['query'] = this.getFilter();
      this.state['tags'] = [];
      this.state['programs'] = [];
      this.state['link'] = "/podcasts";
      this.state['showconfirm'] = false;
      this.state['showconfirmExport'] = false;

      if (params.page) {
        this.state['page'] = params.page;
        this.callPodcast(params.page,this.state.filterlatitude,this.state.filterlongitude,this.state.filterlocality,this.state.filtertags,this.state.filtertitle,this.state.filterid,this.state.filterprogram,this.state.filtergeneral,this.state.filterlocale, parseInt(this.state.filtertype));
      } else {
        this.state['page'] = 0;
        this.callPodcast(0,this.state.filterlatitude,this.state.filterlongitude,this.state.filterlocality,this.state.filtertags,this.state.filtertitle,this.state.filterid,this.state.filterprogram,this.state.filtergeneral,this.state.filterlocale, parseInt(this.state.filtertype));
      }
      this.inputup = React.createRef();
      this.reactTags = React.createRef()
      this.callTags();
      this.callPrograms();
  }

  readTag(tags){
      var array = []
      if (Array.isArray(tags)){
          tags.forEach((item, i) => {
              var split = item.split("|");
              array.push({id:split[0],name:split[1]});
          });
      } else {
            var split = tags.split("|");
            array.push({id:split[0],name:split[1]});
      }

      return array;

  }

  callPodcast (offset,latitude,longitude,location,tags,title,id,program,general,locale, type){
      var tagsAux = [];
      if(tags && tags.length > 0){
         tags.forEach((item, i) => {
            tagsAux.push(item.id);
         });

      }

      let callback = (datarows, error) => {

        if (datarows) {
           this.setState({data:datarows.data,total:datarows.total});
        }

      }
      podcastService.search(offset,latitude,longitude,location,tagsAux,title,id,program,general,locale,type, callback);
  }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({tags:datarows});
      }
    }
    podcastService.tags(callback);
  }

  callPrograms (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({programs:datarows});
      }
    }
    podcastService.programs(callback);
  }

  handleChange(event){
       const value = event.target.value

       if (event.target.name === "title") {
           this.setState({filtertitle:value});
           this.setState({filtergeneral:""});
       } else if (event.target.name === "general") {
          this.setState({filtergeneral:value});
          const valueaux = ""
          this.setState({filtertitle:valueaux});
          this.setState({filterlatitude:valueaux});
          this.setState({filterlongitude:valueaux});
          this.setState({filterlocality:valueaux});
          this.setState({filterprogram:valueaux});
          this.setState({filtertype:valueaux});
          this.setState({filterlocale:valueaux});
          this.setState({filterid:valueaux});
          this.setState({filtertags:[]});
       } else if (event.target.name === "latitude") {
           this.setState({filtergeneral:""});
           this.setState({filterlatitude:value});
       } else if (event.target.name === "longitude") {
           this.setState({filtergeneral:""});
           this.setState({filterlongitude:value});
       } else if (event.target.name === "locality") {
           this.setState({filtergeneral:""});
           this.setState({filterlocality:value});
       } else if (event.target.name === "locality") {
           this.setState({filtergeneral:""});
           this.setState({filterlocality:value});
       } else if (event.target.name === "program") {
           this.setState({filtergeneral:""});
           this.setState({filterprogram:value});
       } else if (event.target.name === "idrad") {
           this.setState({filtergeneral:""});
           this.setState({filterid:value});
       }  else if (event.target.name === "locale") {
        this.setState({filtergeneral:""});
        this.setState({filterlocale:value});
    }  else if (event.target.name === "tags") {
           this.setState({filtergeneral:""});
           var node = ReactDOM.findDOMNode(this.refs.colTags);
           var options = [].slice.call(node.querySelectorAll('option'));
           var selected = options.filter(function (option) {
            return option.selected;
           });
           var selectedValues = [];
            selected.map(function (option) {
            selectedValues.push(option.value);
            return option.selected;
           });
           this.setState({filtertags:selectedValues});
       } else if (event.target.name === "type") {
            this.setState({filtergeneral:""});
            this.setState({filtertype:value});
        } 
     } 

     clickCell(id){
       window.location.href="/podcast?id="+id;
     }


     clickDel(id){
       let callback = (datarows, error) => {
          window.location.reload();
       }
       podcastService.remove(id, callback);
     }

    getFilter(){

       var query=""
       if(this.state.filtertitle !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "title=" + this.state.filtertitle ;
       }
       if(this.state.filtergeneral !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "general=" + this.state.filtergeneral ;
       }
       if(this.state.filterlatitude !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "latitude=" + this.state.filterlatitude ;
       }
       if(this.state.filterid !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "idrad=" + this.state.filterid ;
       }
       if(this.state.filterlongitude !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "longitude=" + this.state.filterlongitude ;
       }
       if(this.state.filterlocality !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "locality=" + this.state.filterlocality ;
       }
       if(this.state.filterprogram !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "program=" + this.state.filterprogram ;
       }
       if(this.state.filtertags.length > 0){
         debugger;
         this.state.filtertags.forEach( function(tag) {
             if(query !== ""){
                query = query + "&";
             }
             query = query + "tags=" + tag.id +"|"+tag.name;
         });
       }
       if(this.state.filterlocale !== ""){
        if(query !== ""){
           query = query + "&";
        }
        query = query + "locale=" + this.state.filterlocale ;
      } 
      if(this.state.filtertype !== ""){
        if(query !== ""){
           query = query + "&";
        }
        query = query + "type=" + this.state.filtertype ;
      }
      
       return query;
     }

     onDelete (i) {
       const tags = this.state.filtertags.slice(0)
       tags.splice(i, 1)
       this.setState({filtertags:tags});
     }

     onAddition (tag) {
       const tags = [].concat(this.state.filtertags, tag)
       this.setState({filtertags:tags});
     }


    handleClickClear(event) {
      window.location.href="/podcasts";
    }
   handleClickSearch(event) {
     this.setState({query:this.getFilter()});
     window.location.href="/podcasts?page=0&"+this.getFilter();
   }

   handleUpload(){
      this.inputup.current.click()
   }

   handleSelectedExcel(event){
     const value = event.target.files[0];

     if (value) {
       let callback = (data, error) => {
         if (data) {
           this.setState({showconfirm:true});
         }
       }
       podcastService.importfile(value, callback);
     }

   }

   handleExport(event){
     let callback = (datarows, error) => {
         this.setState({showconfirmExport:true});
     }
     podcastService.exportPodcast(callback);

   }

  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de podcast" newbutton={true} link="/podcastnew"  />
         <Accordion defaultActiveKey="0">
           <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="default" eventKey="0">
                Filtrar
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="filterpanel">
                <Row>
                  <Col sm="3">
                  <Form.Group >
                    <Form.Label>Buscador como en APP</Form.Label>
                    <Form.Control type="text" placeholder="" name="general" value={this.state.filtergeneral} onChange={event => this.handleChange(event)} />
                  </Form.Group>
                </Col>
                  <Col sm="3">
                  <Form.Group >
                    <Form.Label>ID</Form.Label>
                    <Form.Control type="text" placeholder="" name="idrad" value={this.state.filterid} onChange={event => this.handleChange(event)} />
                  </Form.Group>
                </Col>
                  <Col sm="3">
                    <Form.Group >
                      <Form.Label>Titulo</Form.Label>
                      <Form.Control type="text" placeholder="" name="title" value={this.state.filtertitle} onChange={event => this.handleChange(event)} />
                    </Form.Group>
                  </Col>
                  <Col sm="3">
                    <Form.Group controlId="formLatitude">
                      <Form.Label>Latitud</Form.Label>
                      <Form.Control type="text" placeholder="" name="latitude" value={this.state.filterlatitude} onChange={event => this.handleChange(event)} />
                    </Form.Group>
                  </Col>
                  <Col sm="3">
                      <Form.Group controlId="formLongitude">
                        <Form.Label>Longitud</Form.Label>
                        <Form.Control type="text" placeholder="" name="longitude" value={this.state.filterlongitude} onChange={event => this.handleChange(event)}  />
                      </Form.Group>
                  </Col>
                  <Col sm="3">
                      <Form.Group controlId="formLocality">
                        <Form.Label>Localidad</Form.Label>
                        <Form.Control type="text" placeholder="" name="locality" value={this.state.filterlocality} onChange={event => this.handleChange(event)}  />
                      </Form.Group>
                  </Col>
                  <Col sm="3">
                      <Form.Group controlId="formLocale">
                        <Form.Label>Idioma</Form.Label>
                        <Form.Control as="select" name="locale" onChange={event => this.handleChange(event)} value={this.state.filterlocale} >
                          <option value="">---</option>
                          <option value="es">ES</option>
                          <option value="en">EN</option>
                        </Form.Control>
                      </Form.Group>
                  </Col>
                  <Col sm="3">
                       <Form.Group >
                         <Form.Label>Tags</Form.Label>
                         <ReactTags allowNew={true} className="tagsinput" ref={this.reactTags} tags={this.state.filtertags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Etiquetas"/>
                       </Form.Group>
                  </Col>
                  <Col sm="3">
                      <Form.Group controlId="formTags">
                        <Form.Label>Programa</Form.Label>
                        <Form.Control as="select" name="program" onChange={event => this.handleChange(event)} value={this.state.filterprogram} >
                          <option value="">---</option>
                          {this.state.programs.map((pro,idx) => (
                              <option value={pro.id}>{pro.name}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                  </Col>
                  <Col sm="3">
                      <Form.Group controlId="formType">
                        <Form.Label>Tipo de elemento</Form.Label>
                        <Form.Control as="select" name="type" onChange={event => this.handleChange(event)} value={this.state.filtertype} >
                          <option value="">---</option>
                          <option value="1">Podcast</option>
                          <option value="2">Elemento patrimonial</option>
                          <option value="3">Punto de interés (POI)</option>
                        </Form.Control>
                      </Form.Group>
                  </Col>
                  

                </Row>
                <Row>
                    <Col className="text-right">
                        <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                        <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                    </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
         </Accordion>
         <Row>
             <Col sm="12" hidden>

               <Form.Group>
                   <Form.File ref={this.inputup} label="Imagen"  onChange={event => this.handleSelectedExcel(event)}/>
               </Form.Group>
             </Col>
             <Col className="text-right importpanel">
                  <Button variant="info" className="" onClick={event => this.handleExport()} style={{marginRight:"20px"}}>Exportar Podcast</Button>
                 <Button variant="danger" className="" onClick={event => this.handleUpload()}>Importar Podcast</Button>
             </Col>
         </Row>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} link={this.state.link} query={this.state.query} handlerdel={this.clickDel}  />

      </div>
      <Modal show={this.state.showconfirm} onHide={event => this.setState({showconfirm:false})}>
        <Modal.Header closeButton>
          <Modal.Title>Fichero importado</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>El documento se esta importando, se te notificará por Email cuando termine.</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={event => this.setState({showconfirm:false})}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={this.state.showconfirmExport} onHide={event => this.setState({showconfirmExport:false})}>
        <Modal.Header closeButton>
          <Modal.Title>Fichero exportando ...</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p> Se estan exportando los podcast. Cuando termine se enviara un fichero Excel a tu correo.</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={event => this.setState({showconfirmExport:false})}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </>
  };
}

export default Podcast;
