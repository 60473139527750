import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import {Row, Col} from 'react-bootstrap';
import { stadisticsService } from '../../services';
import './Main.scss';
import toast from 'toasted-notes'
import TableTic from './../../components/page/table';


class Main  extends Home{


  constructor(props) {
      super(props)
      this.state = {sta:{users:{numNewToday:0,numNewWeekend:0,numNewMonth:0,numTotal:0,topPlaying:[],topPlayingWeek:[]}, podcast:{numPlayingToday:0,numPlayingWeekend:0,numPlayingMonth:0,numTotal:0,topPlaying:[],topPlayingWeek:[]},tag:{topPlaying:[],topPlayingWeek:[]},program:{topPlaying:[],topPlayingWeek:[]}}}
      this.state['headersuser'] =  ["Nombre","Email"];
      this.state['fielduser'] =  ["name","email"];
      this.state['typeuser'] =  ["text","text"];
      this.state['headerspod'] =  ["#","Titulo"];
      this.state['fieldpod'] =  ["image","title"];
      this.state['typepod'] =  ["image","text"];
      this.state['headerstag'] =  ["Nombre"];
      this.state['fieldtag'] =  ["name"];
      this.state['typetag'] =  ["text"];
      this.callSearch();
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
           this.setState({sta:data});
        } else {
          const msg = "No se ha encontrado las estadisticas";
          toast.notify(msg, {position:'top', duration: 5000});
          this.setState({buttonvisibles:false});
        }

      }
      stadisticsService.search(callback);
  }


  render () {
    console.log(this.state.sta);
    return<>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Bienvenido a Radioviajera" newbutton={false} link="" handler=""/>
         <Row>
           <Col sm={12}><h3 className="sesSta">Usuarios</h3></Col>
           <Col sm={4}>
             <h4 className="sesCol"><label><b>Número de usuarios</b></label></h4>
             <div className="panelsta">
                 <Row>
                     <Col xs={9}><label>Nuevos usuarios hoy</label></Col><Col xs={3}><span>{this.state.sta.users.numNewToday}</span></Col>
                 </Row>
                 <Row>
                     <Col xs={9}><label>Nuevos usuarios esta semana</label></Col><Col xs={3}><span>{this.state.sta.users.numNewWeekend}</span></Col>
                 </Row>
                 <Row>
                     <Col xs={9}><label>Nuevos usuarios este més</label></Col><Col xs={3}><span>{this.state.sta.users.numNewMonth}</span></Col>
                 </Row>
                 <Row>
                     <Col xs={9}><label>Nuevos usuarios totales</label></Col><Col xs={3}><span>{this.state.sta.users.numTotal}</span></Col>
                 </Row>
             </div>
           </Col>

           <Col sm={4}>
             <h4 className="sesCol"><label><b>Usuarios con más reproducciones de la semana</b></label></h4>
             <div className="panelsta">
                 <Row>
                   <Col xs={12}>
                      <TableTic  headers={this.state.headersuser} fields={this.state.fielduser} data={this.state.sta.users.topPlayingWeek} row={0} total={this.state.sta.users.topPlayingWeek.length} page={0} sizepage={11} type={this.state.typeuser} />
                    </Col>
                </Row>
              </div>
           </Col>

           <Col sm={4}>
             <h4 className="sesCol"><label><b>Usuarios con más reproducciones</b></label></h4>
             <div className="panelsta">
                 <Row>
                   <Col xs={12}>
                      <TableTic  headers={this.state.headersuser} fields={this.state.fielduser} data={this.state.sta.users.topPlaying} row={0} total={this.state.sta.users.topPlaying.length} page={0} sizepage={11} type={this.state.typeuser} />
                    </Col>
                </Row>
              </div>
           </Col>
         </Row>

         <Row>
           <Col sm={12}><h3 className="sesSta">Podcast</h3></Col>
           <Col sm={4}>
             <h4 className="sesCol"><label><b>Número de reproducciones</b></label></h4>
             <div className="panelsta">
                 <Row>
                     <Col xs={9}><label>Hoy</label></Col><Col xs={3}><span>{this.state.sta.podcast.numPlayingToday}</span></Col>
                 </Row>
                 <Row>
                     <Col xs={9}><label>Esta semana</label></Col><Col xs={3}><span>{this.state.sta.podcast.numPlayingWeekend}</span></Col>
                 </Row>
                 <Row>
                     <Col xs={9}><label>Este mes</label></Col><Col xs={3}><span>{this.state.sta.podcast.numPlayingMonth}</span></Col>
                 </Row>
                 <Row>
                     <Col xs={9}><label>Totales</label></Col><Col xs={3}><span>{this.state.sta.podcast.numTotal}</span></Col>
                 </Row>
             </div>
           </Col>

           <Col sm={4}>
             <h4 className="sesCol"><label><b>Podcast con más reproducciones de la semana</b></label></h4>
             <div className="panelsta">
                 <Row>
                   <Col xs={12}>
                      <TableTic  headers={this.state.headerspod} fields={this.state.fieldpod} data={this.state.sta.podcast.topPlayingWeek} row={0} total={this.state.sta.podcast.topPlayingWeek.length} page={0} sizepage={11} type={this.state.typepod} />
                    </Col>
                </Row>
              </div>
           </Col>

           <Col sm={4}>
             <h4 className="sesCol"><label><b>Podcast con más reproducciones</b></label></h4>
             <div className="panelsta">
                 <Row>
                   <Col xs={12}>
                      <TableTic  headers={this.state.headerspod} fields={this.state.fieldpod} data={this.state.sta.podcast.topPlaying} row={0} total={this.state.sta.podcast.topPlaying.length} page={0} sizepage={11} type={this.state.typepod} />
                    </Col>
                </Row>
              </div>
           </Col>
         </Row>

         <Row>
           <Col sm={12}><h3 className="sesSta">Etiquetas</h3></Col>

           <Col sm={6}>
             <h4 className="sesCol"><label><b>Más escuchadas esta semana</b></label></h4>
             <div className="panelsta">
                 <Row>
                   <Col xs={12}>
                      <TableTic  headers={this.state.headerstag} fields={this.state.fieldtag} data={this.state.sta.tag.topPlayingWeek} row={0} total={this.state.sta.tag.topPlayingWeek.length} page={0} sizepage={11} type={this.state.typetag} />
                    </Col>
                </Row>
              </div>
           </Col>

           <Col sm={6}>
             <h4 className="sesCol"><label><b>Mas escuchadas</b></label></h4>
             <div className="panelsta">
                 <Row>
                   <Col xs={12}>
                      <TableTic  headers={this.state.headerstag} fields={this.state.fieldtag} data={this.state.sta.tag.topPlaying} row={0} total={this.state.sta.tag.topPlaying.length} page={0} sizepage={11} type={this.state.typetag} />
                    </Col>
                </Row>
              </div>
           </Col>
         </Row>

         <Row>
           <Col sm={12}><h3 className="sesSta">Programas</h3></Col>

           <Col sm={6}>
             <h4 className="sesCol"><label><b>Más escuchados esta semana</b></label></h4>
             <div className="panelsta">
                 <Row>
                   <Col xs={12}>
                      <TableTic  headers={this.state.headerstag} fields={this.state.fieldtag} data={this.state.sta.program.topPlayingWeek} row={0} total={this.state.sta.program.topPlayingWeek.length} page={0} sizepage={11} type={this.state.typetag} />
                    </Col>
                </Row>
              </div>
           </Col>

           <Col sm={6}>
             <h4 className="sesCol"><label><b>Mas escuchados</b></label></h4>
             <div className="panelsta">
                 <Row>
                   <Col xs={12}>
                      <TableTic  headers={this.state.headerstag} fields={this.state.fieldtag} data={this.state.sta.program.topPlaying} row={0} total={this.state.sta.program.topPlaying.length} page={0} sizepage={11} type={this.state.typetag} />
                    </Col>
                </Row>
              </div>
           </Col>
         </Row>

      </div>

      </>
  };
}

export default Main;
