import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import './Story.scss';
import { storyService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form } from 'react-bootstrap';
import queryString from 'query-string';
class Story extends Home{
    constructor(props) {
        super(props)
        let params = queryString.parse(this.props.location.search);
        this.state['headers'] =  ["#","Nombre","Tipo"];
        this.state['fields'] =  ["image","name","type"];
        this.state['type'] =  ["image","text","text"];
        this.state['data'] = [];
        this.state['row'] = 0;
        this.state['total'] = 0;
        this.state['sizepage'] = 20;
        this.state['filtertitle'] = params.name?params.name:"";
        this.state['filtertype'] = params.type?params.type:"";
        this.state['query'] = this.getFilter();
        this.state['story'] = [];
        this.state['link'] = "/stories";
        if (params.page) {
          this.state['page'] = params.page;
          this.callStory(params.page,this.state.filtertitle, this.state.filtertype);
        } else {
          this.state['page'] = 0;
          this.callStory(0,this.state.filtertitle, this.state.filtertype);
        }
    }
    callStory(offset,name,type){
        let callback = (datarows, error) => {
            console.log(datarows)
            if (datarows) {
                this.setState({data:datarows.data,total:datarows.total});
            }
        }
        storyService.search(offset,name,type, callback);
    }
    handleChange(event){
        const value = event.target.value

        if (event.target.name === "name") {
            this.setState({filtertitle:value});
        }  else if (event.target.name === "type") {
            this.setState({filtertype:value});
        }
    }
    clickCell(id){
        window.location.href="/story?id="+id;
    }
    clickDel(id){
    let callback = (datarows, error) => {
        window.location.reload();
    }
    storyService.remove(id, callback);
    }
     getFilter(){
        var query=""
        if(this.state.filtertitle !== ""){
            if(query !== ""){
              query = query + "&";
            }
            query = query + "name=" + this.state.filtertitle ;
        }
        if(this.state.filtertype !== ""){
            if(query !== ""){
                query = query + "&";
            }
            query = query + "type=" + this.state.filtertype ;
        }
        return query;
    }
    handleClickClear(event) {
        window.location.href="/stories";
    }
    handleClickSearch(event) {
        this.setState({query:this.getFilter()});
        window.location.href="/stories?page=0&"+this.getFilter();
    }
    render () {
        return <>
        <NavBar user={this.state.user} callback={this.changeMenu}/>
        <div className="main">
            <TitlePage title="Listado de stories" newbutton={true} link="/storynew"  />
            <Accordion defaultActiveKey="0">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="default" eventKey="0">
                        Filtrar
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body className="filterpanel">
                    <Row>
                      <Col sm="3">
                        <Form.Group >
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control type="text" placeholder="" name="name" value={this.state.filtertitle} onChange={event => this.handleChange(event)} />
                        </Form.Group>
                      </Col>
                      <Col sm="3">
                        <Form.Group >
                          <Form.Label>Tipo</Form.Label>
                          <Form.Control as="select" name="type" onChange={event => this.handleChange(event)} value={this.state.filtertype} >
                            <option value="">---</option>
                            <option value="podcast">Podcast</option>
                            <option value="program">Programa</option>
                            <option value="tag">Tag</option>
                            
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                        <Col className="text-right">
                            <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                            <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                        </Col>
                    </Row>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            </Accordion>
            <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} link={this.state.link} query={this.state.query} handlerdel={this.clickDel}  />
        </div>
        </>
    };
}
export default Story;
